body {
    &.cloud-control {
        .page-header {
            background-color: transparent;
            @include reset-box-shadow;
        }
    }
}

.cloud-control-wrapper {
    @include page-wrapper-position;
    z-index: 3;
    padding: 0 $page-sides-padding-y;

    &.to-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

.cloud-control-watt {
    margin: rem(20) 0 rem(40) 0;

    > span {
        display: block;
        width: rem(202);
        height: rem(70);
        margin: 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(22, 22, 22, 0.35);
        border-radius: rem(8);

        font-size: rem(50);
        line-height: rem(30);
        text-align: center;
        font-weight: 800;
        padding: rem(12) rem(10);
    }
}

.cloud-control-intensity {
    @include transition-common-link;
    @include absolute-center-horizontal;
    background: linear-gradient(180deg, $primary-green 0%, #005F7D 100%);
    filter: blur(50px);
    width: 100%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    //position: fixed;
    z-index: 2;
    bottom: 0;
    &.low {
        background: $primary-gray-border;
    }
}

.cloud-control-cta {
    background-color: transparent;
    @include transition-common-link;
    border: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: rem(35);
    font-size: rem(12);
    font-weight: 700;
    color: $white;
    letter-spacing: rem(0.67);
    text-transform: uppercase;
    &:last-child {
        margin-bottom: 0;
    }
    .circles {
        @include transition-common-link;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(60);
        height: rem(60);
        border-radius: rem(30);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
        margin-right: rem(20);
        span {
            display: inline-block;
            width: rem(42);
            height: rem(42);
            border-radius: rem(21);
            border: 2px solid $white;
        }
        &:after {
            @include absolute-center;
            content: "";
            display: inline-block;
            width: rem(18);
            height: rem(18);
            border-radius: rem(9);
        }
    }
    &.cloud-control-cta-balanced {
        .circles {
            &:after {
            display: none;
            }
        }
    }
    &.cloud-control-cta-smooth {
        .circles {
            span {
                display: none;
            }
        }
    }
}
.cloud-control-vertical-slider {
    background: linear-gradient(to bottom,  #60605e 0%,#60605e 22.22%,#585856 22.22%,#585856 66.66%,#525250 66.66%,#525250 100%);
    border-radius: rem(10);

    width: rem(90);
    height: 220px;

    .example-track {
        width: rem(90);
        &.example-track-0 {
            background-color: $white;
            border-bottom-left-radius: rem(10);
            border-bottom-right-radius: rem(10);
        }
        &.example-track-1 {
        }
        &.example-track-2 {
        }
        &.example-track-3 {
        }
    }

    .example-thumb {
        width: rem(104);
        height: rem(4);
        border-radius: rem(2);
        text-indent: -9999em;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        background: $white;
        &:after {
            @include absolute-center-vertical;
            content: "";
            left: 0;
            width: 100%;
            height: rem(40);
        }
    }
}

.cloud-control-settings {
    margin: rem(34) 0 rem(29) 0;
    .cloud-control-settings-value {
        position: relative;
        padding: rem(10) rem(3);
        color: #DEDEDE;
        font-size: rem(14);
        font-weight: 600;
        &:after {
            position: absolute;
            z-index: 1;
            content: "";
            height: rem(2);
            width: 100%;
            left: 0;
            bottom: 0;
            border-radius: rem(1);
            background-color: rgba($white, 0.1);
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        span {
            overflow: hidden;
            max-width: 50%;

            color: $white;
            font-size: rem(16);
            font-weight: 800;
            line-height: rem(20);
            text-overflow: ellipsis;
            text-transform: uppercase;
        }
    }
}
.cloud-control-low-power-mode {
    background-color: rgba($primary-gray-darker, 0.75);
    border-radius: rem(8);
    padding: rem(14) rem(15);
    font-size: rem(16);
    font-weight: 600;
    [class*=bat-icon] {
        font-size: rem(24);
        margin-right: rem(10);
        line-height: 0;
    }
}
.cloud-control-low-power-mode-wrapper {
    position: relative;
    z-index: 3;
    padding: rem(30) $page-sides-padding-y;

    &.disabled {
        opacity: 0.5;
    }
}
