.failure-support-wrapper {

    font-family: $font-family-primary;
    font-style: normal;
    font-weight: normal;
    padding: 0 rem(18);
    color: $white;
    z-index: 2;

    .title-row {
        font-size: rem(24);
        line-height: 110%;
        /* or 26px */
        
        margin-top: rem(90);
    }

    .subtitle-row {
        font-size: rem(14);
        line-height: rem(18);

        margin-top: rem(27);
        margin-bottom: rem(27);
    }


    .support-row {
        border-top: rem(1) solid $primary-gray-light;
        border-bottom: rem(1) solid $primary-gray-light;

        font-size: rem(14);
        line-height: rem(18);

        padding-top: rem(15);
        padding-bottom: rem(15);

        &:last-child {
            border-top: none !important;
        }

        img {
            width: rem(20);
            margin-right: rem(10);
        }
        
        [class*=bat-icon] {
            font-size: rem(18);
            margin-right: rem(10);
        }

    }

    .link-contact{
        all: unset;
    }
}