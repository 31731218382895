.network-connection-lost {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1051;
  height: auto;

  &.translucent {
    background-color: rgba(29, 29, 27, 0.9);
  }

  .page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 rem(24);

    text-align: center;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(67);
    width: rem(67);
    margin-bottom: rem(26);

    border: rem(3) solid #79162b;
    border-radius: 50%;

    font-size: rem(30);
  }

  &-title {
    margin-bottom: rem(31);

    font-size: rem(40);
    font-weight: 800;
    line-height: rem(38);
    letter-spacing: rem(0.5);
    text-transform: uppercase;
  }

  &-content {
    margin-bottom: rem(36);

    font-size: rem(14);
    text-align: center;
  }
}
