.shop-subscriptions-actions {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: rem(10) rem(20) rem(20) rem(20);
    background: linear-gradient(0deg, #112935 0%, #000000 73.37%);

    .btn {
        width: 100%;
        margin-bottom: rem(10);
    }

    .shop-subscriptions-actions-title {
        font-weight: bold;
        font-size: rem(12);
        line-height: rem(14);
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: $white;    
        margin-bottom: rem(13);
    }
}

.shop-subscriptions-content {
    position: relative;
    text-align: center;
    color: white;

        &:before {
            content: "";
            position: absolute;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
            opacity: 0.5;
            width: 100%;
            height: 100%;
            z-index: 2;
        
    }

    .subscriptions-title {
        position: absolute;
        top: rem(45);
        padding-left: rem(35);
        padding-right: rem(35);
        width: 100%;
        text-align: center;

        h1 {
            font-weight: 800;
            font-size: rem(60);
            line-height: rem(50);
        }
    }
}

.shop-subscriptions-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: rem(10) rem(20) rem(20) rem(20);
    background: linear-gradient(0deg, #112935 0%, #000000 73.37%);
}

.subscription-badge-container {

    padding: rem(21) rem(19);
    border-radius: rem(8);
    margin-bottom: rem(10);
    background: linear-gradient(180deg, #006E9B 0%, rgba(77, 188, 234, 0.9) 100%);

    .subscription-badge-title {
        font-weight: bold;
        font-size: rem(14);
        line-height: rem(15);
        color: $white;
    }

    .subscription-badge-total {
        color: $white;    
    }

    .subscription-badge-details {
        display: flex;
        flex-direction: column;

        
        font-size: rem(10);
        line-height: rem(12);
        color: #2A2A2A;
        text-transform: uppercase;

        h2 {
            margin-bottom: 0;
        }
    }

    .subscription-badge-btn {
        border: 2px solid $white;
        box-sizing: border-box;
        border-radius: rem(58);
        padding: rem(6) rem(12);

        font-weight: bold;
        font-size: rem(10);
        line-height: rem(12);

        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        
        color: $white;
        background-color: transparent;
    }
}

.payment-details,
.delivery-details {

    display: flex;
    flex-direction: column;

    padding: rem(15) rem(25) rem(17) rem(25);
    font-size: rem(14);
    line-height: rem(18);
    color: $primary-gray-dark-extra;
    background-color: $white;
}
