.gps-pp {
  $gps-pp-header-height: rem(100);
  $gps-pp-card-border-radius: rem(15);
  $gps-pp-padding-x: rem(24);

  &-page {
    background-color: $primary-gray-dark-extra;
  }

  &-body {
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    word-wrap: break-word;

    @media screen and (min-width: 1510px) {
      position: initial;
      left: initial;
      bottom: initial;
      height: auto;
      margin-top: $gps-pp-header-height;
    }
  }

  &-wrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gps-pp-padding-x;
    padding-right: $gps-pp-padding-x;
  }

  &-header {
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;

    background-color: $primary-gray-dark-extra;

    .gps-pp-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $gps-pp-header-height;

      @media screen and (min-width: 1510px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &-change-location {
    display: flex;
    align-items: center;
    font-size: 0;
    font-weight: 500;
    cursor: pointer;

    @media screen and (min-width: 1510px) {
      gap: rem(10);
      font-size: rem(14);
    }

    > img {
      height: rem(24);
      width: rem(24);
    }
  }

  &-brand > img {
    height: rem(24);
  }

  &-card {
    background-color: $primary-gray-soft;
    border-top-left-radius: $gps-pp-card-border-radius;
    border-top-right-radius: $gps-pp-card-border-radius;

    @media screen and (min-width: 1510px) {
      border-radius: $gps-pp-card-border-radius;
    }
  }

  &-country-selector {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1510px) {
      max-width: rem(668);
    }

    &-content {
      height: 100%;
      overflow-y: auto;
      padding-left: $gps-pp-padding-x;
      padding-right: $gps-pp-padding-x;

      @media screen and (min-width: 1510px) {
        overflow-y: initial;
      }

      h1 {
        margin-top: rem(100);

        font-size: rem(24);
        font-weight: 500;
        text-align: center;
      }

      > p {
        margin-bottom: rem(38);

        font-size: rem(13);
        text-align: center;
      }
    }

    &-list {
      margin-bottom: rem(18);

      .provinces {
        @media screen and (min-width: 1510px) {
          max-height: rem(200);
          overflow-y: auto;
        }
      }

      .btn {
        width: 100%;
        margin-bottom: rem(10);

        background-color: $primary-gray-dark-extra;
        border-color: transparent;

        &.active {
          border-color: $primary-green;
        }
      }
    }

    &-label {
      margin-bottom: rem(10);

      font-size: rem(12);
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &-cta {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(125);

      background-color: $primary-gray-dark-extra;
      border-top-left-radius: $gps-pp-card-border-radius;
      border-top-right-radius: $gps-pp-card-border-radius;

      .btn {
        min-width: rem(174);

        border-radius: rem(24);
        font-size: rem(15);
        font-weight: 500;
        letter-spacing: 0;
        line-height: rem(14);
      }

      @media screen and (min-width: 1510px) {
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  &-content-gps-pp {
    overflow-y: auto;
    padding-top: rem(50);
    padding-bottom: rem(50);

    font-size: rem(13);
    line-height: rem(20);

    @media screen and (min-width: 1510px) {
      max-width: rem(1350);
    }

    h1 {
      margin-bottom: rem(50);

      font-size: rem(24);
      font-weight: 500;
    }
  }

  &-content-account-deletion {
    overflow-y: auto;
    padding-top: rem(50);
    padding-bottom: rem(50);

    font-size: rem(13);
    line-height: rem(20);

    h1 {
      margin-bottom: rem(50);

      font-size: rem(24);
      font-weight: 500;
    }
  }
}
