
.feature-tutorial-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .feature-tutorial-header {
        background: rgba($primary-gray-dark-extra, 0.01);
        backdrop-filter: blur(20px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: rem(75);
        width: 100%;
        position: fixed;
        z-index: 2;
        //BUTTON ICON
        .feature-tutorial-icon {
            position: absolute;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            padding: 0;
            width: rem(32);
            height: rem(32);
            left: rem(20);
            line-height: 1;
            [class*=bat-icon] {
                font-size: rem(16);
                color: $white;
            }
            //Positioning on right
            &.on-right {
                left: auto;
                right: rem(20);
                font-size: rem(16);
            }
            
            &.tutorial-icon {
                width: rem(50);
                height: rem(50);
                border-radius: rem(25);
                border: 2px solid rgba($white, 0.25);

                [class*=bat-icon] {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: rem(50);
                    height: rem(60);
                    margin-left:0 ;
                    font-size: rem(20);
                }

                img {
                    max-height: rem(45);
                }
    
            }
    
        }

        .optin-header-label {
            font-size: rem(12);
            font-weight: 700;
            letter-spacing: rem(1.8);
            text-transform: uppercase;
            padding-left: rem(90);
        }
    }

    .feature-tutorial-stories {
        position: absolute;
        z-index: 1;

        .feature-tutorial-stories-controls {
            @include absolute-center;
            width: 100%;
            z-index: 1;
            padding: 0 $page-sides-padding-y;
            font-weight: 700;
            font-size: rem(12);
            pointer-events: none;
            text-transform: uppercase;
            .next {
                margin-left: auto;
            }
        }

        //Steps
        div > div:first-child {
            //background-color: red;
            top: rem(100) !important;
            filter: none !important;
            div {
                height: rem(3) !important;
                background-color: rgba($white, 0.3) !important;
                div {
                    background-color: $white !important;
                }
            }
        }
        //Video
        video {
            object-fit: cover;
            width: 100vw !important;
            height: 100vh !important;
			max-height: none !important;
            position: fixed;
            top: 0;
            left: 0;
        }
    }
}

.features-tutorial-wrapper {

    margin: rem(50) rem(20) rem(30) rem(20);

    .features-tutorial-box {
        padding: rem(18) rem(21) rem(30) rem(21);
        background: linear-gradient(180deg, #323535 0%, #1E2022 100%);
        mix-blend-mode: normal;
        border-radius: rem(8);
        margin-bottom: rem(20);

        .col-6 {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                height: 76px;
            }

            .feature-icon {

                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                width: rem(76);
                height: rem(76);
                border-radius: rem(60);
                font-size: 1.8rem;

                &.feature-find {
                    border: 2px solid #F8AB28;
                }
                &.feature-unlock {
                    border: 2px solid #00C7B1;
                }
                &.feature-battery {
                    border: 2px solid #FB8234;
                }
            }
        }

        .play-tutorial {
            width: rem(50);
            height: rem(50);
            border-radius: rem(25);
            /*border: 2px solid rgba($white, 0.25);*/
            background: transparent;
            .play-tutorial-icon {
                background-image: url(../img/play-tutorial-icon.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: rem(19) rem(17.5);
                display: block;
                width: rem(40);
                height: rem(40);
            }

        }
    }
}