.process-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: rem(20) rem(18) rem(30) rem(18);

    .process-data-title {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $white;
        margin-bottom: rem(34);
        padding: 0 rem(40);
    }

    .process-data-image {
        position: relative;
        text-align: center;
        margin-top: auto;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 3.5rem;
        }

        .process-end-icon {
            background-image: url(../img/icon-check.svg);
            background-repeat: no-repeat;
            position: relative;
            width: rem(70);
            height: rem(70);
            text-align: center;
        }

        .process-removal {
            background-image: url(../img/remove.webp);
            background-repeat: no-repeat;
            position: relative;
            width: rem(70);
            height: rem(70);
            text-align: center;
        }
    }

    .process-data-cta {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        .page-selector-cta-url {
            text-align: center;
            margin-top: rem(15);
            font-size: rem(12);
        }

        &.with-bg {
            padding: rem(27) rem(27) rem(60);
            background-color: #2A2A2A;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        }
    }


    .form-check-input:not(:checked) {
        background: transparent; 
        border: 2px solid #6B6B6B;
    }


    .instructions-container {
        margin-left: 1.125rem;
        margin-right: 1rem;
        font-weight: 500;
        font-size: rem(15);
        //line-height: rem(48);
        color: $white;

        margin-bottom: rem(20);
        ol {
            margin: 0;
            padding: 0;
            li {

                margin-top: rem(15);

                [class*=bat-icon] {
                    background-color: $primary-gray-soft;
                    border-radius: rem(5);
                    width: rem(24);
                    height: rem(24);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 rem(5);
                }

                img {
                    height: rem(24);
                    margin-left: rem(5);
                    margin-right: rem(5);

                }
                
            }
        }

    }
}

.shipping-mode-instruction-img-wrapper {
    background: #272727;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: rem(13);

    width: rem(284);
    height: rem(190);

    img {
        max-height: rem(191);
    }

    .vertical {
        border-left: 1px solid white;
        height: rem(130);
        position: absolute;
        left: 50%;
        z-index: 0;
        opacity: 0.2;
    }

    h5 {
        z-index: 1;
        background: #272727;
        padding-top: rem(10);
        padding-bottom: rem(10);
        margin-bottom: 0;

        font-weight: 800;
        font-size: rem(14);
        line-height: rem(18);
    }
}