:root {
  --rsbs-bg: #{$primary-gray-dark-extra};
  --rsbs-handle-bg: #{$primary-gray-light};
}

.homescreen {
  z-index: auto !important;

  .homescreen-page {
    position: relative;
  }

  .no-devices {
    > div:first-child > div:nth-child(2) > div {
      height: 100%;
      width: 100%;

      > video {
        height: 100%;
        width: 100% !important;
        object-fit: cover;
      }
    }

    .page-selector-cta {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1053;
    }
  }

  .device-carousel {
    &-slide {
      max-width: 100vw;

      background-position: center;
      background-size: cover;

      text-align: center;
    }
  }

  .device-name {
    font-size: rem(30);
    font-weight: 300;
    line-height: rem(38);
    text-align: left;
    margin-top: rem(6);
  
      @media only screen and (max-height: 740px) {
        font-size: rem(24);
        line-height: rem(24);
      }
    }

  .device-img {
    display: inline-block;
    max-height: rem(230);
    height: rem(230);

    @media only screen and (min-height: 741px) and (max-height: 844px) {
      height: rem(175);
    }

    @media only screen and (max-height: 741px) {
      height: rem(150);
    }

  }

  .device-img, .mimosa-img{
    &.disconnected {
      opacity: 0.3;
    }
  }

  .device-img-mimosa, .orb-img-mimosa {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .device-img-mimosa{ z-index: 1; }
  .orb-img-mimosa{ z-index: 0; }

  .reconnect-cta-abs {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .device-selector {
    $height: 3rem;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    padding: 0 rem(24);
    max-height: $height;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    & > div {
      gap: rem(15);
      margin: 0 auto;
    }

    .btn {
      height: $height;
      width: $height;
      box-sizing: border-box;
      padding: 0;

      border-radius: calc($height / 2);

      font-size: 1rem;

      /*background-color: rgba(29, 29, 27, 0.5);*/

      display: flex;
      align-items: center;
      justify-content: center;

      flex-grow: 0;
      flex-shrink: 0;

      i {
        display: block;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: 1053;
  }

  [data-rsbs-header] {
    padding-top: rem(19);
    padding-right: rem(24);
    padding-bottom: rem(13);
    padding-left: rem(24);

    &:before {
      height: rem(3);
      width: rem(60);
      border-radius: rem(1.5);
    }
  }

  [data-rsbs-scroll] {
    overflow: auto;
    padding: rem(28) rem(24) rem(100);
    background-color: $primary-gray-soft;
  }

  .scrollable [data-rsbs-scroll] {
    overflow: auto;
  }

  &-cta {
    height: rem(60);

    border-radius: rem(15);
    box-shadow: 0 rem(3) rem(7) 0 rgba(0, 0, 0, 0.25) inset;
    font-weight: 700;

    .custom-btn-icon {
      color: $primary-gray-light;
      font-size: rem(20);
    }
  }

  .feature-widget {
    &.rr-widget {

      &.disabled {
        pointer-events: all;
      }
    }

    &.fmv-widget {
      padding: 0 0.75rem;

      &.disabled {
        pointer-events: all;
      }
    }

    // &:not(.disabled) {
    //   &.cc-widget {
    //     --cloud-intensity-height: 0;

    //     // Removed because of static assets :c
    //     // but if you want to control the size of intensity
    //     // feel free to restore it!
    //     // &:before {
    //     //   position: absolute;
    //     //   bottom: 0;
    //     //   left: 0;
    //     //   right: 0;
    //     //   transform: translateY(10vw);
    //     //   z-index: 0;
    //     //   height: var(--cloud-intensity-height);

    //     //   background: linear-gradient(180deg, #00c7b1 0%, #005f7d 100%);
    //     //   border-top-left-radius: 50%;
    //     //   border-top-right-radius: 50%;
    //     //   filter: blur(4vw);
    //     //   opacity: 0.5;

    //     //   content: "";
    //     // }
    //   }

    //   // Removed because of static assets :c
    //   // But this solution is so cool!
    //   // &.fmv-widget {
    //   //   // --circle-gradient-radius: 52%;

    //   //   // background-image: repeating-radial-gradient(
    //   //   //   circle at center,
    //   //   //   transparent 0,
    //   //   //   transparent 10%,
    //   //   //   rgba(255, 255, 255, 0.1) var(--circle-gradient-radius)
    //   //   // );

    //   //   // &.full {
    //   //   //   --circle-gradient-radius: 35%;
    //   //   // }
    //   // }
    // }
  }

  .alert-disconnected {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(92.62deg, #79162B 12.37%, #991F39 90.72%);
    z-index: 9000;
    max-height: rem(47);
    transition: .3s all;
    padding: rem(14) rem(26) rem(14) rem(26);
    display: flex;

    &.open {
      max-height: rem(120);
    }

    .container-icon {
      margin-right: rem(14);

      img {
        width: rem(20);
        height: rem(19);
      }
    }

    .main {
      font-size: rem(12);
      font-weight: 700;
      line-height: rem(21);
      letter-spacing: rem(1.2);
      text-align: left;
    }

    .sub {
      font-size: rem(12);
      font-weight: 400;
      line-height: rem(14);
      text-align: left;
      padding-top: rem(14);
    }
  }
}

.ultra-pod-widget{
  width: 100%;
  /*height: rem(59);*/ /*widget closed*/
  /*height: rem(143);*/ /*widget opened*/
  margin: 0 auto;
  padding: 0 rem(10) rem(19);
  z-index: 1052;

  &.absolute {
    position: absolute;
  }

  .bar{
    width: 100%;
    /*height: 100%;*/
    border-radius: rem(8);
    padding: 0 rem(8);
    box-sizing: border-box;
    overflow: hidden;
    background-color: var(--rsbs-bg); /* default color */
  

    .text-centered{
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }

    // pod connection status

    .ultra-pod-status{

      padding: rem(8) 0 rem(8) 0;
      display: flex;
      justify-content: center;
      align-content: center;

      .status-loader{
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: rem(11);
      }

      .status-loader::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: conic-gradient(
            from 90deg at 50% 50%, 
            rgba(0, 199, 177, 0) 0deg, 
            rgba(0, 199, 177, 0) 0.04deg, 
            #00C7B1 360deg
        );
        animation: spin 1.5s linear infinite;
      }

      .status-loader::after {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          right: 2px;
          bottom: 2px;
          border-radius: 50%;
          background-color: var(--rsbs-bg);
      }

      @keyframes spin {
          to {
              transform: rotate(360deg);
          }
      }

      .status-title{
        font-family: Montserrat;
        font-size: rem(14);
        font-weight: 700;
        line-height: rem(12);
        text-transform: uppercase;
        color: #FFFFFF;
        @extend .text-centered;
      }

    }

    // pod collapsed

    .flavour-name{
      font-size: rem(14);
      font-weight: 700;
      line-height: rem(30);
      text-transform: uppercase;
      float: left;
    }

    .info-container{
      height: 30px;
      border-radius: 48px;
    }

    .flavour-icon{
      float: left;
      background-color: #313131;
      text-align: center;

      img{
        height: rem(24);
        margin-top: rem(3);
      }

    }

    .liquid-info{

      padding: 0 rem(8);
      box-sizing: border-box;
      display: flex;

      img{
        height: rem(20);
        margin-top: rem(5);
        margin-right: rem(5);
      }

      .liquid-percentage{
        font-size: rem(14);
        font-weight: 700;
        line-height: rem(30);
        color: #FFFFFF;
        width: min-content;

        span{ font-weight: 400; }

      }

    }

    .flavour-icon, .expand-icon{ width: rem(30); }

    .expand-icon, .liquid-info {
      float: right;
      background-color: var(--rsbs-bg);
      box-shadow: 0 rem(4) rem(4) 0 #0000001A inset;
    }

    .auto-tune-icon {
      float: right;
      border-radius: 50%;
      background: #1D1D1B;
      box-shadow: 0px rem(4) rem(4) 0px rgba(0, 0, 0, 0.10);
      backdrop-filter: blur(15px);
      width: rem(32);
      height: rem(32);

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: rem(20);
        height: rem(20);
      }
    }

    .flavour-icon, .liquid-info{ margin-right: rem(10); }

    .info-container, .flavour-name{
      margin-top: rem(5);
      margin-bottom: rem(5);
    }

    .expand-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      
      img{
        display: block;
        margin: auto;
        height: rem(7.09);
        transition: transform 150ms ease,
      }
    }

    // pod expanded

    .detailed-info{

      width: 100%;
      height: 0;
      transition: all 0.3s linear;

      &.showing {
        height: rem(79);
        margin-bottom: rem(8);
      }
      &.showing-small {
        height: rem(43);
        margin-bottom: rem(8);

        .recommended-label, .recommended-value{
          border-bottom: none !important;
        }

      }

      .detailed-info-row{

        display: flex;

        div{
          width: 50%;
          box-sizing: border-box;
          @extend .text-centered;
        }

        div:nth-of-type(1){ border-right: solid 2px rgba(255,255,255,0.1); }

      }

      .info-title{

        margin-top: rem(1);

        div{
          /*height: rem(32);*/
          background-color: var(--rsbs-bg);
          border-bottom: solid 2px rgba(255,255,255,0.1);
          padding: rem(6) rem(18);
          font-weight: 700;
          font-size: rem(10);
          text-align: center;
          letter-spacing: rem(1.26);
          line-height: rem(11);
          color: #AAAAAA;
        }

        div:nth-of-type(1){ border-top-left-radius: 6px; }
        div:nth-of-type(2){ border-top-right-radius: 6px; }

      }

      .info-value{

        div{
          height: rem(43);
          font-size: rem(14);
          font-weight: 700;
          line-height: rem(12);
          text-align: center;
          color: #FFFFFF;

          &.recommended-label {
            font-size: rem(10);
            line-height: normal;
            text-align: left;
            color: #AAAAAA;
            border-right: none;
          }
          &.recommended-value {
            background-color: #2A2A2A !important;
          }
        }

        div:nth-of-type(1){
          border-bottom-left-radius: 6px;
          background-color: var(--rsbs-bg);
        }
        
        div:nth-of-type(2){
          border-bottom-right-radius: 6px;
          background-color: var(--rsbs-bg);
        }

      }

    }

    .space-cleaner{ clear: both; }

    *{
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

  }

}

// premium homepage
@media screen and (min-height: 844px) {

  .device-img-mimosa{ height: rem(238);}
  .orb-img-mimosa{
    top: rem(21);
    height: rem(330);
  }
  .device-selector{ margin-top: rem(24); }

}

@media screen and (min-height: 741px) and (max-height: 844px) {

  .device-img-mimosa{ height: rem(175);}
  .orb-img-mimosa{
    top: rem(35);
    height: rem(243);
  }
  .device-selector{ margin-top: rem(20); }

}

@media screen and (max-height: 741px){

  .device-img-mimosa{ height: rem(150);}
  .orb-img-mimosa{
    top: rem(40);
    height: rem(208);
  }
  .device-selector{ margin-top: rem(18); }
  
}
