.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(84);
  width: rem(84);
  margin: 0 auto;

  background-color: #3c3c3c;
  border-radius: 50%;

  font-size: rem(30);
  font-weight: 500;
  text-transform: uppercase;
}
