.signup-form-dob-wrapper {
  .wheel-picker-container {
    .wheel-picker-inner {
      &:before {
        background: linear-gradient(
          0deg,
          rgba(239, 239, 244, 0) 0%,
          #ffffff 100%
        );
      }

      &:after {
        background: linear-gradient(
          0deg,
          #ffffff 0%,
          rgba(239, 239, 244, 0) 100%
        );
      }
    }
    .wheel-picker-panel{
      &:before, &:after{ background: transparent !important; }
    }
  }
}

.wheel-picker-container {
  z-index: 10001;

  width: 100%;

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .wheel-picker-inner {
    position: relative;

    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 20px;

    font-size: 1.2em;
    // -webkit-mask-box-image: linear-gradient(to top, transparent, transparent 5%, white 20%, white 80%, transparent 95%, transparent);
    &:before,
    &:after {
      content: "";
      background: linear-gradient(
        0deg,
        rgba(239, 239, 244, 0) 0%,
        #2a2a2a 100%
      );
      position: absolute;
      z-index: 2;
      width: 100%;
      height: rem(30);
      top: 0;
      left: 0;
    }
    &:after {
      background: linear-gradient(
        0deg,
        #2a2a2a 0%,
        rgba(239, 239, 244, 0) 100%
      );
      top: auto;
      bottom: 0;
    }
  }

  .wheel-picker-panel{
    &:before, &:after{ background: transparent !important; }
  }

  .wheel-picker-column {
    flex: 1 1;

    position: relative;

    max-height: 100%;

    overflow: hidden;
    text-align: center;

    .wheel-picker-scroller {
      transition: 300ms;
      transition-timing-function: ease-out;
    }

    .wheel-picker-item {
      position: relative;

      padding: 0 10px;

      white-space: nowrap;
      color: #999999;
      font-size: rem(18);
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;

      &.wheel-picker-item-selected {
        color: #222;
      }
    }
  }

  .wheel-picker-cursor {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;

    pointer-events: none;

    &:before,
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      right: auto;

      display: block;
      width: 100%;
      height: 1px;

      background-color: #d9d9d9;
      transform: scaleY(0.5);
    }

    &:before {
      top: 0;
      bottom: auto;
    }

    &:after {
      bottom: 0;
      top: auto;
    }
  }
}

.picker-header {
  height: rem(74) !important;
  margin-top: 0 !important;
}
