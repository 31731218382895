.manage-device-page {
  .manage-devices-wrapper {
    padding: 2.5rem 1.65625rem;
    padding-bottom: calc(8.125rem + 2.5rem);
  }

  .manage-devices-title{
    font-size: rem(12);
    font-weight: 700;
    line-height: rem(12);
    letter-spacing: rem(1);
    text-align: left;
    color: #fff;
    display: block;
    margin-bottom: rem(14);
  }

  .alert-max-devices {
    margin-bottom: 1.25rem;

    background-color: #991f39;
    background-image: $gradient-error;
    border-radius: 0.5rem;

    font-size: 0.75rem;

    .alert-heading {
      font-family: var(--bs-font-sans-serif);
      font-size: 0.75rem;
      font-weight: 500;
    }

    .alert-icon {
      margin-right: 0.5rem;
      font-size: 1rem;
    }
  }

  .devices-list-group {
    gap: 0.625rem;
  }

  .device-item {
    max-height: rem(72); // need for fix background-size: contain
    padding: rem(15) rem(25) rem(15) rem(85);
    box-sizing: border-box;

    background-color: black;
    background-position: rem(15) center, left center;
    background-repeat: no-repeat;
    background-size: rem(50), cover;
    /*box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);*/
    border: rem(1) solid #AAA;
    border-radius: 0.5rem;

    color: white;

    // &.global {
    //   background-position: rem(10) center, left -#{rem(5)} center;
    // }

    // &.light-market {
    //   background-position: rem(10) center, left -#{rem(5)} center;
    // }

    // &.dark-market {
    //   background-position: rem(10) center, left -#{rem(5)} center;
    // }
  }

  .device-name {
    margin-bottom: 0.5rem;

    font-size: rem(18);
    font-weight: 300;
    line-height: rem(20);
  }

  .device-badge {
    font-size: 0.625rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .device-status {
    display: flex;
    align-items: center;

    &:before {
      height: 0.5rem;
      width: 0.5rem;
      margin-right: 0.3125rem;

      border-radius: 50%;

      content: "";
    }

    &-connected:before {
      background-color: var(--bs-success);
    }

    &-disconnected:before {
      background-color: var(--bs-danger);
    }
  }

  .device-update {
    position: absolute;
    left: 100%;

    min-height: 1.25rem;
    min-width: 4.25rem;
    margin-left: 0.25rem;
    padding-left: rem(8);
    padding-right: rem(8);
    box-sizing: border-box;

    background-color: $primary-violet;
    background-image: $gradient-warning;
    border-radius: 0.625rem;

    line-height: 1.25rem;
    text-align: center;
    white-space: nowrap;
  }

  .bat-icon-chevron-right {
    font-size: 0.75rem;
  }

  .page-selector-cta {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
