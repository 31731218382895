$shop-product-img-width: rem(93);
$shop-product-fav-star: rem(56);


.shop-manage-favourites-wrapper {
    background-color: #EFEFEF;

    .shop-manage-favourites-image {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            background: linear-gradient(180deg, rgba($black, 0) 0%, $black 100%);
            opacity: 0.5;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        img {
            position: relative;
            z-index: 1;
            width: 100%;
            max-height: 12.5rem;
            object-fit: contain;
        }
    }

    .shop-manage-favourites-title {
        position: relative;
        z-index: 1030;
        bottom: 7rem;
        width: 100%;

        h2 {
            text-transform: uppercase;
            text-align: center;
            color: $white;
        }
    }

    .shop-manage-favourites-list {
       padding: rem(17.5);
        position: relative;
        margin-top: - rem(95);
        z-index: 1030;
        width: 100%;

        .shop-manage-favourites-el-wrapper {
            padding-bottom: rem(30);

            .shop-product-title {
                font-weight: 500;
            }

            .shop-product-description {
                width: calc(100% - #{$shop-product-img-width} - #{$shop-product-fav-star});
            }

            .shop-product-fav-star {
                padding: 0.9375rem 1rem 0.9375rem 0.75rem;
                width: $shop-product-fav-star;

                font-size: 1.5625rem;
                color: $primary;
            }
        }

        .shop-manage-favourites-qty {
            display: flex;
            justify-content: flex-end;
            padding-right: rem(15);
        }
    }

    .shop-manage-favourites-empty {
        padding: rem(30) rem(30) rem(65) rem(30);
        display: flex;
        flex-direction: column;

        h1 {
            font-style: normal;
            font-weight: 800;
            font-size: rem(60);
            line-height: rem(50);
            text-align: center;
            color: $primary-gray-medium; 
        }

        span {
            font-size: rem(14);
            line-height: rem(17);
            text-align: center;
            color: $primary-gray-medium; 
            padding: 0 rem(43);
            margin: rem(16) 0;
        }
    }
}
