.country-selector {
}
.country-selector-wrapper {
    @include page-wrapper-position;
    padding-top: rem(50);
    .page-label {
        margin-bottom: rem(14);
    }
}
.country-selector-list {
    padding: 0 $page-sides-padding-y;
    margin-bottom: rem(20);
    .btn {
        margin-bottom: rem(10);
        width: 100%;
        font-weight: 600;
    }
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

@include media-breakpoint-up(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
// Very Extra large devices (Extra large desktops, 1440px and up)
@include media-breakpoint-up(xxl) {
}
// 4K devices (4K, 2560px and up)
@include media-breakpoint-up(4k) {
}

//***************************************
// LESS THAN
//***************************************

// Very Extra small devices (portrait phones, less than 375px)
@include max-xxs {
}
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
}
// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
}
// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
}
// Extra Large devices (desktops, less than 1440px)
@include media-breakpoint-down(xl) {
}
// Extra Large devices (desktops, less than 2560px)
@include media-breakpoint-down(xxl) {
}

//***************************************
// ONLY FOR RANGE
//***************************************

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, From 576px to 767.98)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, From 768px to 991.98px)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, From 992px to 1199.98px)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, From 1200px to 1439.98px)
@include media-breakpoint-only(xl) {
}
// Very Extra large devices (Very Extra large desktops, From 1440px to 2559.98px)
@include media-breakpoint-only(xxl) {
}
// 4K large devices (4K desktops, 2560px and up)
@include media-breakpoint-only(4k) {
}
