.select-device-page {
  &-title {
    padding: 1.25rem 1.65625rem;

    h1 {
      margin-bottom: 0;

      font-size: 0.875rem;
      font-weight: 600;
      text-align: center;
    }
  }

  .device-name {
    font-size: rem(18);
    font-weight: 300;
  }

  .av-devices {
    padding: 1.25rem 1.65625rem;

    ul {
      list-style: none;
    }

    li {
      height: 22vh;

      background-color: black;
      background-position: center;
      background-size: cover;
      border-radius: 0.5rem;
      cursor: pointer;

      font-size: 0.875rem;
    }
  }
}
