.device-icon-group {
  position: relative;
  display: flex;

  &.compact {
    .device-icon:nth-child(n + 2) {
      margin-left: -#{rem(35)};
    }

    .device-count {
      position: absolute;
      right: -#{rem(6)};
      top: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(20);
      width: rem(20);

      background-color: white;
      border-radius: 50%;

      color: $primary-gray-dark-extra;
      font-size: rem(10);
      font-weight: 600;
    }
  }

  &.loose {
    gap: 15px;
  }
}


.device-icon {
  overflow: hidden;
  height: rem(48);
  width: rem(48);

  background-color: $primary-gray-soft;
  border: rem(1) solid #AAAAAA;
  border-radius: 50%;
  z-index: 0;

  > img {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  &.selected {
    border-width: rem(2) !important;
    border-color: $primary-green !important;
  }
  
  &.connected {
    border-color: $primary-green;
  }

  &.disconnected {
    border-color: $red !important;
  }
}

.device-pointer {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  //border-bottom: 17px solid red;
  transform: rotate(180deg) translateX(10px);
  position: absolute;
  left: 50%;
  top: -10px;
  margin-top: -2px;
  z-index: 0;

    &.selected {
      top: -10px;
    }
    &.connected {
      border-bottom: 17px solid $primary-green;
    }

    &.disconnected {
      border-bottom: 17px solid $red !important;
    }
}

.device-connection-indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(8);
  height: rem(20);
  padding-left: rem(10);
  padding-right: rem(10);

  background-color: $primary-gray-darker;
  border-radius: rem(10);

  font-size: rem(10);
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:before {
    height: rem(8);
    width: rem(8);

    background-color: $red;
    border-radius: 50%;

    content: "";
  }

  &.connected:before {
    background-color: $primary-green;
  }
}

.device-battery-icon {
  position: relative;
  overflow: hidden;
  max-height: rem(22);
  display: flex;
  align-items: center;
  justify-content: center;

  .bat-icon-battery-h {
    color: white;
    font-size: rem(44);
    rotate: 90deg;
  }

  .container-battery-level {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -#{rem(0.5)};
    margin-left: -#{rem(2)};

    font-size: rem(14);
    line-height: rem(12);
    font-weight: 700;
  }

  &.disconnected {
    .bat-icon-battery-h {
      color: rgba(255, 255, 255, 0.5);
    }

    .container-battery-level {
      margin-top: -#{rem(1.5)};
    }
  }
}

.device-drawer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1052;

  background-color: $primary-gray-dark-extra;
  border-top-left-radius: rem(25);
  border-top-right-radius: rem(25);

  &-body {
    padding: rem(27);
  }

  .status-bar {
    padding-top: rem(19);
    padding-right: rem(24);
    padding-bottom: rem(13);
    padding-left: rem(24);
  }
}

.status-bar {
  .device-name {
    font-size: rem(30);
    font-weight: 300;
    line-height: rem(38);
    text-align: left;

    @media only screen and (max-width: 360px) {
      font-size: rem(24);
    }
  }
}

.reconnect-cta {
  padding-top: 0;
  padding-bottom: 0;

  border-radius: rem(15);

  font-size: rem(10);
  font-weight: 700;
  letter-spacing: 1px;
  line-height: rem(30);
  text-align: center;

  min-width: rem(80);
  min-height: rem(30);
}

.firmware-cta {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  padding: rem(16);
  border-radius: rem(22);
  font-size: rem(10);
  font-weight: 700;
  letter-spacing: 1px;
  line-height: rem(12);
  text-align: center;
  z-index: 2;
}
