.side-menu-wrapper {
  position: absolute;
  top: rem(20);
  right: rem(20);
  z-index: 1054;
  &.side-menu-wrapper-dark-market {
    top: rem(20);
  }
}

body {
  &.warning-active {
    .side-menu-wrapper {
      top: calc(20vh + #{rem(20)});
    }
  }
}

#bm-menu-content {
  background: $primary-gray-dark-extra;
  // padding-top: $header-height; //if header is fixed
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.bm-menu-wrapper {
  height: 100%;
  overflow: auto;
  padding: rem(20) $page-small-sides-padding-y;

  .list-group {
    border-radius: rem(8);
    margin-bottom: rem(10);
    box-shadow: 0px 4px 4px rgba($black, 0.25);

    .list-group-item {
      background-color: #272727;
      border-bottom-color: rgba($white, 0.2);
      color: $white;
      padding: rem(20);
      text-transform: uppercase;
      font-size: rem(12);
      font-weight: 600;
      letter-spacing: 1px;

      &:last-child {
        border-bottom-color: #272727;
      }

      .loyalty-logo {
        height: rem(20);
      }
    }
  }

  .manage-devices-item {
    background: linear-gradient(180deg, #303030 0%, #1d1d1d 100%);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border: 0.125rem solid #404040;
    border-radius: 0.5rem;

    .list-group-item {
      background-color: initial;
      border-bottom: none;
    }

    .devices-thumbs {
      position: relative;
      margin-right: 1.125rem;

      .device-image {
        display: inline-block;
        position: relative;
        height: 2.75rem;
        width: 2.75rem;

        box-shadow: inset 0 0 0 0.125rem rgba(255, 255, 255, 0.2);
        border-radius: 50%;

        &.skin-black {
          background-image: $device-black-gradient;
        }

        &.skin-blue {
          background-image: $device-blue-gradient;
        }

        &.skin-cyan {
          background-image: $device-cyan-gradient;
        }

        &.skin-gold {
          background-image: $device-gold-gradient;
        }

        &.skin-pink {
          background-image: $device-pink-gradient;
        }

        &.skin-red {
          background-image: $device-red-gradient;
        }

        &.skin-reflekta {
          background-image: $device-reflekta-gradient;
        }

        &:nth-child(n + 2) {
          margin-left: -2.1875rem;
        }

        > img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 80%;
        }
      }

      .extra-devices-badge {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        height: 1.5rem;
        width: 1.5rem;

        background-color: #4f4f4f;
        border-radius: 50%;

        font-size: 0.625rem;
      }
    }

    .devices-counter {
      margin-top: 0.25rem;
      color: rgba(255, 255, 255, 0.3);
    }

    .updatable-devices-badge {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 2rem;
      width: 2rem;
      margin-right: 1.5rem;

      background-color: $primary-violet;
      background-image: $gradient-warning;
      border-radius: 50%;

      font-size: 0.875rem;
      font-weight: 700;
    }
  }
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

.bm-burger-button {
  width: rem(32);
  height: rem(32);
  z-index: 98 !important;
  .bm-icon {
    @include absolute-center;
    width: auto !important;
    height: auto !important;
    font-size: rem(20);
  }
}

.bm-overlay {
  z-index: 1054 !important;
}

.bm-menu-version {
  font-size: rem(14);
  line-height: rem(18);
  color: #6b6b6b;
  margin-top: rem(30);
}
