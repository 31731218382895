.sliding-panel-overlay {
    z-index: $zindex-modal;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    //Generic Slider
    &.sliding-common-wrapper {
        background-color: rgba(0,0,0,0);

        &.sliding-customization-wrapper {
            z-index: 1053;

            .sliding-panel-header {
                border-bottom: none !important;
            }
        }

        &.sliding-device-settings-wrapper {
            z-index: 1054;
        }
        
        &.priority{
            z-index: 6000;
        }

        &.homepage-priority{
            z-index: 9999;
        }

        &.priority-high{
            z-index: 1000001;
        }

        &.overlay-after-open {
            background-color: rgba(0,0,0,0.3);
            transition: background-color 0.5s;
        }

        &.overlay-before-close {
            background-color: rgba(0,0,0,0);
        }

        .sliding-panel {
            display: flex;
            flex-direction: column;
            background: #fff;
            min-width: rem(100);
            height: 100%;
            box-shadow: 0 rem(8) rem(8) rgba(0,0,0,0.5);
            transition: transform 0.5s;
            will-change: transform;
        
            height: 90vh;
            margin-top: 10vh;
            transform: translateY(100%);

            &.full-height {
                height: 100vh;
                background-color: $primary-gray-soft !important;
                margin-top: 0;

                &.no-padding{
                    .sliding-panel-content{
                        padding: 0;
                        opacity: 1;
                    }
                }
                
                .sliding-panel-header {
                    background: $primary-gray-dark-extra;
                    z-index: 9999;

                    .sliding-panel-icon-close{
                        display: none;
                    }

                    .sliding-panel-title-wrapper{
                        margin-left: 0 !important;
                        align-items: center;

                        .sliding-panel-title{
                            color: $white;
                            // font-weight: 800;
                            font-size: rem(26);
                            line-height: rem(26);
                            //text-transform: uppercase;
                        }

                    }
                }
                .sliding-panel-mid {
                    padding-left: 0;
                    padding-right: 0;
                }

                .page-selector-cta{
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &:focus {
                outline-style: none;
            }

            &.content-after-open {
                transform: translateY(0%);
            }

            &.content-before-close {
                transform: translateY(100%);
            }
            .sliding-panel-header {
                display: flex;
                flex: 0 0 rem(64);
                align-items: center;
                background: #ebebeb;
                height: rem(64);
                border-bottom: 1px solid rgba(0,0,0,0.1);

                .sliding-panel-title-wrapper {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    margin-left: rem(32);
                    min-width: 0;

                    .sliding-panel-title {
                        font-size: rem(18);
                        max-width: 80%;
                        text-align: center;
                        /*
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        */
                        text-transform: uppercase;
                        margin: 0;
                        padding: 0;
                        overflow-wrap: break-word;
                        hyphens: auto;
                    }

                    .sliding-panel-icon-close {
                        margin-left: rem(24);
                        padding: rem(16);
                        opacity: 0.7;
                        cursor: pointer;
                    }
                }
            
            }
            
            .sliding-panel-content {
                position: relative;
                overflow-y: auto;
                padding: 24px 32px;
                flex: 1 1 auto;
            }

            &.fw-update-panel {
                .sliding-panel-title {
                    font-weight: 800;
                }
                .sliding-panel-content {
                    background-image: url(../img/app-background-light.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-attachment: fixed;
                }
            }
        
            &.onboarding-panel {
                margin-top: 25vh;
                max-height: 75vh;
                background-color: #242424 !important;
                border-radius: rem(30) rem(30) 0 0;
                mix-blend-mode: normal;

                .page-selector-cta-url {
                    margin-bottom: rem(27);
                }

                &.full-height {
                    margin-top: 0;
                    height: 100%;
                    max-height: 100vh;
                    border-radius: 0;
                    .sliding-panel-icon-close{
                        display: block;
                    }
                } 

                &.form-panel {
                    background-position: bottom !important;
                    height: rem(577);
                    max-height: 80vh;
                }

                &.cookie-policy-panel {
                    .sliding-panel-header {
                        .sliding-panel-title-wrapper {
                            .sliding-panel-title {
                                max-width: 100%;
                            }
                        }
                    } 
                }

                .sliding-panel-header {

                    align-items: center;
                    background-color: $primary-gray-soft !important;
                    border-radius: rem(30) rem(30) 0 0;
                    border-bottom: 0;
                    flex: 0 0 rem(48);
                    min-height: rem(80);
                    padding: 0 0 0 1.25rem;
                    display: flex;
                    padding: 0;
                    padding-bottom: 1rem;
        
                    .sliding-panel-icon-close {
                        color: $white;
                        order: 2;
                        padding: 0;
                        opacity: 1;
                        text-align: center;
                        margin-left: auto;
                        margin-right: rem(15);
                        margin-top: rem(15);
                        width: rem(30);
                        height: rem(30);
                        line-height: rem(30);
                        cursor: pointer;
                        align-self: flex-start !important;
        
                    }

                    .sliding-panel-title-wrapper {
                        align-self: flex-end;
                        margin-left: rem(27);

                        .sliding-panel-title {
                            font-size: rem(25);
                            line-height: 110%;
                        }
                    }  
                }

                .sliding-panel-content {
                    padding: rem(1) rem(27) rem(45) rem(27);
                    font-weight: 400;
                    font-size: rem(14);
                    line-height: rem(18);
                    opacity: 0.6;
                    /*
                    &:after {
                        content: "";
                        background: linear-gradient(180deg, rgba($primary-gray-dark-extra, 0.0001) 0%, $primary-gray-dark-extra 100%);
                        position: fixed;
                        z-index: 2;
                        width: 100%;
                        height: rem(115);
                        bottom: 0;
                        left: 0;
                    }
                    */
                }

                &.mid-height-panel {

                    background-image: url(../img/app-background-light.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-attachment: fixed;
                    
                    .sliding-panel-header {
                        background-color: $primary-gray-dark-extra !important;
                        /*box-shadow: 0px rem(4) rem(6) rgba(0, 0, 0, 0.2);*/
                        min-height: auto;
                        height: auto;
                        flex: 0;
                        padding: 0 rem(10);
                        z-index: 2;
                    }
                    .sliding-panel-icon-close {
                        display: none;
                    }
                    .sliding-panel-title-wrapper {
                        //background-color: green;
                        text-align: center;
                        margin-left: 0;
                        .sliding-panel-title {
                            padding: rem(14) 0;
                            max-width: none;
                            font-size: rem(26);
                            font-weight: 800;
                            line-height: rem(26);
                        }
                    }
                    .sliding-panel-content {
                        opacity: 1;
                        padding: 0;
                    }
                }

                &.disclaimer-panel {
                    .sliding-panel-header {
                        min-height: 60px;

                        .sliding-panel-title-wrapper {
                            align-self: unset;
                            
                            .sliding-panel-title {
                                padding: 0;
                            }
                        }
                    }

                    .sliding-panel-content {
                        padding: 50px 30px;
                        text-align: center;
                    }

                    .page-selector-cta {
                        padding: 1.65625rem;
                    }
                }

                &.low-height-panel {
                    margin-top: 55vh;
                    max-height: 45vh;
                    color: $white;
                    
                    .sliding-panel-title{
                        font-weight: normal;
                        font-size: rem(25);
                        line-height: 110%;
                        color: $white;
                    }

                    .sliding-panel-content {
                        opacity: 1;
                    }
                }

                &.no-bg {
                    background: none;
                }
            }

            &.need-helper-panel {
                .sliding-panel-header {
                    height: auto;
                    min-height: auto;

                    .sliding-panel-icon-close {
                        margin-top: rem(15);
                    }

                    .sliding-panel-title-wrapper {
                        margin-top: rem(30);

                        .sliding-panel-title{
                            text-align: left;
                        }
                    }  
                }

                .need-helper-panel-custom-body-wrapper {
                    .separator {
                        margin: rem(27) rem(-27) rem(27) rem(-27);
                    }
                }
            }

        }

        &.title-left {
            .sliding-panel-title {
                text-align: left !important;
            }
        }
    }

    //Dashboard Slider
    &.sliding-device-wrapper {
        .sliding-panel {
            // background-image: url(../img/homescreen/ePod2/black-global.jpg);
            background-color: black;
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: cover;

            display: flex;
            flex-direction: column;
            border-top-left-radius: rem(16);
            border-top-right-radius: rem(16);
            width: 100%;
            height: 100%;
            transition: transform 0.5s;
            will-change: transform;

            height: calc(100vh - 100px);
            margin-top: 100px;

            transform: translateY(100%);
            &:focus {
                outline-style: none;
            }
            &.content-after-open {
                transform: translateY(0%);
            }
            .sliding-panel-header {
                display: flex;
                height: $sliding-panel-dashboard-header;
                flex: 0 0 $sliding-panel-dashboard-header;
                align-items: center;
                padding: 0 rem(17) 0 $page-sides-padding-y;
                .sliding-panel-icon-close {
                    @include transition-common-link;
                    background-color: rgba($black, 0.3);
                    order: 2;
                    width: rem(30);
                    height: rem(30);
                    border-radius: rem(15);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: rem(12);
                }
                .sliding-panel-title-wrapper {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    min-width: 0;
                    .sliding-panel-title {
                        font-weight: 700;
                        color: rgba($white, 0.3);
                        font-size: rem(12);
                        margin: 0;
                    }
                }
            }
            .sliding-panel-content {
                height: 100%;
            }
            //When it'is closed
            &.collapsed {
                transform: translateY(calc(100% - #{$sliding-panel-dashboard-header}));
                .sliding-panel-header {
                    .sliding-panel-icon-close {
                        transform: rotate(180deg);
                    }
                }
            }
        }


        &.find-my-device {

            &.sliding-panel-overlay{
                height: 0;
            }
    
            .sliding-panel {
                height: calc(100vh - 550px);
                margin-top: 550px;
            }
    
        }




    }

    .notification-check, .form-panel {
        .sliding-panel-title {
            font-size: rem(18) !important;
            font-style: normal !important;
            font-weight: 800 !important;
            line-height: rem(18) !important;
            text-transform: uppercase !important;
            padding: rem(20) rem(26) !important;
        }

        .main-icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            padding-left: rem(14);
            padding-right: rem(14);

            .main-icon {
                font-size: rem(60);
                position: relative;

                .dot-red {
                    position: absolute;
                    width: rem(18);
                    height: rem(18);
                    background: #E53237;
                    top: rem(1);
                    right: rem(1);
                    border-radius: 50%;
                }
            }

            .main-content {
                text-align: center;
                margin-top: rem(40);
                font-size: rem(14);
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .page-selector-cta {
            button.btn {
                height: rem(50);
                max-height: rem(50);
                padding: rem(12) rem(24);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .button-icon {
                width: rem(26);
                height: rem(26);
                border-radius: rem(6);
                margin-right: rem(18);
            }
        }

        .dismiss {
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            text-align: center;

            & > span {
                padding-bottom: 4px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.30);
            }
        }
    }
}

.autotune-icon{
    width: rem(66.48);
    margin: 0 auto;
}
