//Import bootstrap source files
@import "~bootstrap/scss/bootstrap.scss";

html,
body {
  height: 100%;
}

body {
  font-weight: 400;
  line-height: 1.3;
  display: flex;
  //White
  &.body-white-page {
    background-color: $white;
  }
}

#root {
  height: 100%;
  width: 100%;
}

//BUTTONS
.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(16);
}

//IMAGES
.img-full {
  width: 100%;
  max-width: 100%;
  height: auto !important;
}
// IMG RESPONSIVE AUTO
.img-responsive-auto {
  max-width: 100% !important;
  height: auto !important;
  margin: auto;
}

//GENERIC OUTLINE
* {
  outline: none !important;
  outline: 0 !important;
}

//Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-primary;
}
//Fonts
.fs-xs {
  font-size: rem(12);
}
.fs-md {
  font-size: rem(14);
}
.fs-lg {
  font-size: rem(16);
}

//FONT SIZE
.fs-10 {
  font-size: rem(10) !important;
}
.fs-12 {
  font-size: rem(12) !important;
}
.fs-13 {
  font-size: rem(13) !important;
}
.fs-14 {
  font-size: rem(14) !important;
}
.fs-16 {
  font-size: rem(16) !important;
}
.fs-18 {
  font-size: rem(18) !important;
}
.fs-20 {
  font-size: rem(20) !important;
}
.fs-24 {
  font-size: rem(24) !important;
}
.fs-32 {
  font-size: rem(32) !important;
}
.fs-36 {
  font-size: rem(36) !important;
}
.fs-38 {
  font-size: rem(38) !important;
}
.fs-40 {
  font-size: rem(40) !important;
}
.fs-42 {
  font-size: rem(42) !important;
}
.fs-46 {
  font-size: rem(46) !important;
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 1052;
  max-width: 100%;

  .row {
    align-items: center;
    min-height: rem(70);

    background-color: $primary-gray-dark-extra;

    &.secondary {
      background-color: $primary-gray-soft;
    }

    .col {
      padding-left: rem(20);
      padding-right: rem(20);

      &.center {
        flex: 6 1;
      }
    }
  }

  .high-row{ min-height: rem(86) !important; }

  &.transparent {
    .row {
      background-color: transparent !important;
    }
  }

  &-title {
    color: white;
    font-size: rem(18);
    font-weight: 800;
    line-height: rem(18);
    text-align: center;
    text-transform: uppercase;
    overflow-wrap: anywhere;
  }

  &-logo {
    max-width: rem(128);
  }

  &-btn {
    padding: 0;
    background: none;
    border: none;

    > span[class^="bat-icon"] {
      color: white;
      font-size: rem(18);
    }
  }
}

.notification-icon {
  .MuiBadge-root {
    position: absolute;
    left: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1.4rem;

    [class*="bat-icon"] {
      font-size: 1.25rem;
      color: #fff;
    }

    .MuiBadge-dot {
      top: rem(3);
      right: rem(3);
      background-color: #00C7B1;
    }
  }
}

.play-tutorial{
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-tutorial, .play-tutorial-high-header {
  width: rem(32);
  //height: rem(32);

  border-radius: rem(50);

  .play-tutorial-icon {
    height: rem(20);
    width: rem(18);
    margin-left: rem(5);

    background-image: url(../img/play-tutorial-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: rem(19) rem(17.5);
  }

  &.cloud-control-tutorial { border: none !important; }

  span{
    font-size: rem(10);
    font-weight: 600;
    line-height: rem(14);
    text-align: center;
    color: #AAAAAA;
    display: block;
  }
}

.url-text {
  position: relative;
  text-decoration: none;
  color: $white;
  font-size: rem(12);
  font-weight: 600;
  &:after {
    content: "";
    background-color: $primary-gray-light;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
  }
}

.text-bold  b{
  font-weight: bold !important;
}

.loader-overlay {
  //background-color: rgba(red, 0);
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

//HEALTH DISCLAIMER
.page-health-disclaimer {
  @include page-wrapper-position;

  background-color: $black;
  border: rem(5) solid $white;
  // font-family: 'Helvetica', 'Arial', sans-serif;
  // letter-spacing: - rem(0.3);
  // color: $white;
  text-align: center;
  /**/
  height: 20vh;
  //padding: rem(20) rem(10) rem(20) rem(10);
  //padding-left: rem(10);
  //padding-right: rem(10);

  //padding: 1vh rem(10);

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .page-health-disclaimer-wrapper {
    height: calc(20vh - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: rem(10);
    padding-right: rem(10);

    font-size: 3.45vh;
    line-height: 1;
  }

  // Very Extra small devices (portrait phones, less than 375px)
  @include max-xxs {
    //padding: rem(20) rem(10) rem(10) rem(10);
    //font-size: rem(24);
    //line-height: rem(22);
    //font-size: 3vh;
  }
  .page-health-disclaimer-signature {
    margin-left: auto;
    margin-top: rem(5);
    font-size: 70%;
  }
}

//CUSTOM ICONS
.custom-icon {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  width: rem(24);
  height: rem(24);
  background-size: rem(24);
  &.cloud-control {
    &.white {
      //White
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDQzMzYzKSAgLS0+DQo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMaXZlbGxvXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNDhweCIgaGVpZ2h0PSI0OHB4IiB2aWV3Qm94PSItMC41IC0wLjUgNDggNDgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgLTAuNSAtMC41IDQ4IDQ4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxjaXJjbGUgZmlsbD0iI0ZGRkZGRiIgY3g9IjIzLjUiIGN5PSIyMy41MDEiIHI9IjcuMzg1Ii8+DQoJPHBhdGggb3BhY2l0eT0iMC42MDkiIGZpbGw9IiNGRkZGRkYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgICAgIiBkPSJNMjMuNSw0MC4xMTVjLTkuMTYxLDAtMTYuNjE1LTcuNDUzLTE2LjYxNS0xNi42MTUNCgkJYzAtOS4xNjEsNy40NTQtMTYuNjE1LDE2LjYxNS0xNi42MTVjOS4xNjIsMCwxNi42MTUsNy40NTQsMTYuNjE1LDE2LjYxNUM0MC4xMTUsMzIuNjYyLDMyLjY2Miw0MC4xMTUsMjMuNSw0MC4xMTVMMjMuNSw0MC4xMTV6DQoJCSBNMjMuNSw5LjY1NGMtNy42MzUsMC0xMy44NDYsNi4yMTEtMTMuODQ2LDEzLjg0NlMxNS44NjUsMzcuMzQ2LDIzLjUsMzcuMzQ2YzcuNjM1LDAsMTMuODQ2LTYuMjExLDEzLjg0Ni0xMy44NDYNCgkJUzMxLjEzNSw5LjY1NCwyMy41LDkuNjU0TDIzLjUsOS42NTR6Ii8+DQoJPHBhdGggb3BhY2l0eT0iMC4zNDY5IiBmaWxsPSIjRkZGRkZGIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3ICAgICIgZD0iTTIzLjUsNDcuNWMtMTMuMjM0LDAtMjQtMTAuNzY3LTI0LTI0czEwLjc2Ni0yNCwyNC0yNA0KCQljMTMuMjMzLDAsMjQsMTAuNzY3LDI0LDI0UzM2LjczMyw0Ny41LDIzLjUsNDcuNUwyMy41LDQ3LjV6IE0yMy41LDIuMjdjLTExLjcwNywwLTIxLjIzMSw5LjUyMy0yMS4yMzEsMjEuMjMNCgkJYzAsMTEuNzA4LDkuNTI0LDIxLjIzLDIxLjIzMSwyMS4yM2MxMS43MDYsMCwyMS4yMy05LjUyMiwyMS4yMy0yMS4yM0M0NC43MywxMS43OTMsMzUuMjA2LDIuMjcsMjMuNSwyLjI3TDIzLjUsMi4yN3oiLz4NCjwvZz4NCjwvc3ZnPg0K");
    }
  }
}

.inline-alert {
  border-radius: $input-border-radius;
  padding: rem(10) rem(13);
  margin-bottom: rem(20);
  font-size: rem(12);
  line-height: rem(14);
  color: $white;

  &.inline-alert-info {
    background-color: $primary-gray-light;
    background-image: $gradient-inactive;
  }
  &.inline-alert-error {
    background-color: $red;
    background-image: $gradient-error;
  }
  &.hiding {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, opacity 0.5s linear;
  }
  &.showing {
    display: flex;
    visibility: visible;
    transition: visibility 1s, opacity 0.5s linear;
    opacity: 1;
  }
  //Icons
  [class*="bat-icon"] {
    font-size: rem(16);
    margin-right: rem(7);
  }

  .url-text {
    &::after {
      background: $white;
    }
  }
}

//popups
.sweet-alert {
  background-color: $primary-gray-soft !important;
  border-radius: rem(20) !important;

  font-weight: 400;
  font-size: rem(14);
  line-height: rem(18);
  color: rgba($white, 0.6) !important;

  .container {
    text-align: left !important;

    .btn {
      width: 100%;
    }
  }
}

.information-block {
  padding: 0 $page-sides-padding-y;
  margin-bottom: rem(20);
  font-size: rem(12);
  line-height: rem(14);

  .information-block-label {
    vertical-align: middle;
  }
}

.information-block-x {
  padding-left: $page-sides-padding-y;
  padding-right: $page-sides-padding-y;
}

//UNLOCK
.unlock-switch {
  width: 100%;
  height: rem(48);
  position: relative;

  label {
    @include transition-slider;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background: rgba($color: $white, $alpha: 0.2);
    border-radius: rem(24);
  }
  input {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    ~ label i {
      @include transition-slider;
      display: block;
      height: rem(34);
      width: rem(34);
      position: absolute;
      left: rem(7);
      top: rem(7);
      z-index: 2;
      border-radius: inherit;
      background: $white;
    }
    //Toggle
    &:checked ~ label {
      background-color: $success;
    }
    &:checked ~ label i {
      left: auto;
      left: calc(100% - 41px);
    }
  }
}

.unlock-switch-outline {
  width: 100%;
  height: rem(64);
  position: relative;
  label {
    @include transition-slider;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background: rgba($primary-gray-dark-extra, 0.1);
    border-radius: rem(32);
    border: 3px solid rgba($white, 0.25);
    &:after,
    &:before {
      @include transition-slider;
      @include absolute-center-vertical;
      font-family: "bat-icon" !important;
      content: attr(data-icon);
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      vertical-align: middle;
      font-size: rem(22);
      color: $white;
      left: rem(18);
    }
    &:before {
      left: auto;
      right: rem(18);
      opacity: 0.2;
    }
  }
  input {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    ~ label i {
      @include transition-slider;
      display: block;
      height: rem(64);
      width: rem(64);
      position: absolute;
      left: -(rem(3));
      top: -(rem(3));
      z-index: 2;
      border-radius: inherit;
      background-color: rgba($black, 0.1);
      border: 3px solid $white;
      box-shadow: 0px 4px 4px rgba($black, 0.25);
    }
    //Toggle
    &:checked ~ label {
      &:after {
        opacity: 0.2;
      }
      &:before {
        opacity: 1;
      }
    }
    &:checked ~ label i {
      left: auto;
      left: calc(100% - 61px);
    }
  }
  //Unlock > to > Lock
  &.unlock-to-lock {
    label {
      &:after {
        //.bat-icon-lock-outline by IcoMoon
        content: "\e903";
      }
      &:before {
        //.bat-icon-lock-outline-check by IcoMoon
        content: "\e902";
      }
    }
    input {
      ~ label i {
        border-color: $success;
      }
      //Toggle
      &:checked ~ label i {
        border-color: $red;
      }
    }
  }
  //Lock > to > Unlock
  &.lock-to-unlock {
    label {
      &:after {
        //.bat-icon-lock-outline-check by IcoMoon
        content: "\e902";
      }
      &:before {
        //.bat-icon-lock-outline by IcoMoon
        content: "\e903";
      }
    }
    input {
      ~ label i {
        border-color: $red;
      }
      //Toggle
      &:checked ~ label i {
        border-color: $success;
      }
    }
  }
}

//Slide panel from bottom
.bat-slide-bottom {
  //DASHBOARD MY DEVICE
  //background-image: url(../img/homescreen/ePod2/black-global.jpg);

  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  position: fixed;
  z-index: $zindex-modal;
  bottom: 0;

  display: flex;
  flex-direction: column;
  border-top-left-radius: rem(16);
  border-top-right-radius: rem(16);
  transition: transform 0.5s;
  will-change: transform;

  width: 100%;
  height: auto;

  transform: translateY(0%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

  //Inside market-global (header + tab + title)
  &.market-global {
    //background-position: right bottom;
    // height: (
    //   calc(100% - #{$header-height + $top-navigator + $label-title-height})
    // );
    height: 100%;
    position: static;
  }
  //Inside market-light (header + tab)
  &.market-light {
    //background-position: right bottom;
    //height: (calc(100% - #{$header-height + $top-navigator}));
    position: static;
    height: 100%;

    .label-title {
      pointer-events: none;
      .dashboard-carousel-icon-close {
        display: none;
      }
    }
  }
  //Inside market-dark (header)
  &.market-dark {
    //background-position: left top;
    //height: (calc(100% - #{$header-height}));
    position: static;
    height: 100%;

    .label-title {
      pointer-events: none;
      .dashboard-carousel-icon-close {
        display: none;
      }
    }
  }
  //When it'is closed
  &.collapsed {
    transform: translateY(calc(100% - #{$sliding-panel-dashboard-header}));
  }

  &.homepage-panel {
    &.market-light {
      // .label-title {
      //   display: none;
      // }

      // .swiper-container {
      //   margin-top: $label-title-height;
      //   height: calc(100% - #{$label-title-height});
      // }
    }
    //Inside market-dark (header)
    &.market-dark {
      // .label-title {
      //   display: none;
      // }

      // .swiper-container {
      //   margin-top: $label-title-height;
      //   height: calc(100% - #{$label-title-height});
      // }
    }
  }
}

.label-title {
  padding: 0 $page-small-sides-padding-y;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: rgba($white, 0.3);
  font-size: rem(12);
  letter-spacing: rem(1);
  height: $label-title-height;
  text-transform: uppercase;
}

.custom-progress {
  height: rem(8);
  background: rgba($white, 0.2);
  margin-top: rem(8);
  .progress-bar {
    height: rem(6);
    border-radius: rem(5);
    background-color: $primary-green;
  }
}

.stories {
  video {
    object-fit: cover;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.custom-loader-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.5);

  &.force {
    z-index: 9999999;
  }

  .custom-btn {
    gap: rem(10);
  }

  .custom-loader-overlay-inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    .custom-loader-overlay-content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      .spinner {
        width: 50px;
        height: 50px;
        display: inline-block;
        border-width: 2px;
        border-color: rgba(255, 255, 255, 0.05);
        border-top-color: #fff;
        animation: spin 1s infinite linear;
        border-radius: 100%;
        border-style: solid;
      }
    }
  }
}

/* workaround for ios 14.4.2 bug */
.ReactModal__Overlay {
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.app-version-alert {
  padding: rem(30) rem(30) !important;

  h2 {
    font-size: rem(36) !important;
    font-weight: 800 !important;
    line-height: rem(36);
    text-align: center !important;
    color: $white;
    text-transform: uppercase !important;
  }

  .btn {
    color: $primary-gray-soft;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: rem(14);
    line-height: rem(15);
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: rem(50);
  }

  div {
    font-style: normal;
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(18);
    text-align: center;
    color: $white;
  }
}

.nuviu-version-alert {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  background-color: $primary-gray-soft;
  background-image: url(../img/page-linear-gradient-bg-top-left.svg);
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;

  &-enter {
    transform: translateY(100%);
  }

  &-enter-active {
    transform: translateY(0);
    transition: transform 500ms;
  }

  &-exit {
    transform: translateY(0);
  }

  &-exit-active {
    transform: translateY(100%);
    transition: transform 500ms;
  }

  &-header {
    min-height: 3.75rem;
    padding: 0.875rem;

    background-color: $primary-gray-dark-extra;
    box-shadow: 0px rem(4) rem(6) rgba($black, 0.2);

    text-align: center;

    h2 {
      margin: 0;
      font-size: rem(26);
      font-weight: 800;
      line-height: rem(26);
    }
  }

  &-content {
    flex-grow: 1;
    padding: rem(25);
    font-size: rem(14);

    img {
      height: rem(80);
      margin-bottom: rem(20);
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: var(--bs-body-font-family);
      font-size: rem(12);
      font-weight: 700;
      letter-spacing: rem(1.5);

      span {
        margin-right: rem(6);
        font-size: 1rem;
      }
    }

    p {
      max-width: 80%;
      margin: 0.5rem auto;
    }
  }

  &-footer {
    padding: rem(25);
  }
}

.access-denied {
  position: relative;
  z-index: 9999;
  background: linear-gradient(180deg, #202020 -2.01%, #272727 64.01%);

  .myvuse-logo {
    top: 0;
    position: absolute;
    padding-top: rem(26);
    width: rem(112);
  }

  .app-store-logo {
    height: rem(48);
    width: rem(148);
  }
  .play-store-logo {
    height: rem(50);
    width: rem(168);
  }

  .alert-icon {
    margin-top: rem(30);

    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(67);
    height: rem(67);
    border-radius: rem(60);
    background-image: url(../img/Ellipse.webp);
    background-repeat: no-repeat;
    background-position: center;

    span {
      display: block;
      font-size: rem(28);
    }
  }

  h3 {
    font-weight: 800;
    font-size: rem(36);
    letter-spacing: -0.0313rem;
    line-height: rem(36);
    text-align: center;
    color: $white;
    margin-top: rem(20);
  }

  span {
    padding: rem(23) rem(65);
    font-size: rem(14);
    line-height: rem(17);
    text-align: center;
    color: #ffffff;
  }
}

.react-switch {
  .react-switch-bg {
    background: transparent !important;
    border: rem(3) solid rgba(255, 255, 255, 0.25);
  }
  .react-switch-handle {
    background: transparent !important;
    border: rem(3) solid $red !important;
    box-shadow: none !important;
  }

  &.checked {
    .react-switch-handle {
      background: transparent !important;
      border: rem(3) solid $primary-green !important;
    }
  }

  [class*="bat-icon"] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: rem(20) !important;
    margin-right: rem(3);
    margin-left: -(rem(3));
    margin-top: -(rem(3));
  }

  &.home {
    .react-switch-handle,
    .react-switch-bg {
      border-width: rem(2) !important;
    }
  }
}

.lock-switch {
  position: relative;
  margin-bottom: rem(24);

  &-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .react-switch-bg,
  .react-switch-bg > div {
    max-height: rem(60);
  }

  .react-switch-bg {
    background-color: $primary-gray-dark-extra !important;
    box-shadow: 0 rem(3) rem(7) 0 rgba(0, 0, 0, 0.25) inset;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(74) !important;
      color: $primary-gray-light;
      font-size: rem(24);
    }
  }

  .react-switch-handle {
    z-index: 1;
    display: flex !important;
    align-items: center;
    justify-content: center;
    max-height: rem(50);

    background-color: #00adb8 !important;
    background-image: 
      url(../img/homescreen/unlocked.svg),
      linear-gradient(
        40deg,
        #00adb8 0%,
       #00ffe3 100%
      ) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border-radius: rem(10) !important;
    box-shadow: none !important;
  }

  &.checked .react-switch-handle {
    background-color: #79162b !important;
    background-image: 
      url(../img/homescreen/locked.svg),
      linear-gradient(
        140deg,
       #79162b 0%,
       #991f39 100%
      ) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  &-direction {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: rem(24);

    > span {
      font-size: rem(10);

      &:nth-child(1) {
        opacity: 0.25;
      }

      &:nth-child(2) {
        opacity: 0.5;
      }
    }
  }

  &.disabled .lock-switch-direction {
    opacity: 0.5;
  }

  &.checked .lock-switch-direction {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

// Primary button new vis id
.btn-primary {
  background-image: $gradient-primary;
  border: none;
  font-weight: 700;

  &.disabled, &:disabled {
    background-image: $gradient-inactive;
  }
}

.btn-warning {
  background-image: $gradient-warning;
  border: none;

  &.disabled, &:disabled {
    background-image: $gradient-inactive;
  }
}

.btn-danger {
  background-image: $gradient-error;
  border: none;

  &.disabled, &:disabled {
    background-image: $gradient-inactive;
  }
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

@include media-breakpoint-up(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
// Very Extra large devices (Extra large desktops, 1440px and up)
@include media-breakpoint-up(xxl) {
}
// 4K devices (4K, 2560px and up)
@include media-breakpoint-up(4k) {
}

//***************************************
// LESS THAN
//***************************************

// Very Extra small devices (portrait phones, less than 375px)
@include max-xxs {
}
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
}
// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
}
// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
}
// Extra Large devices (desktops, less than 1440px)
@include media-breakpoint-down(xl) {
}
// Extra Large devices (desktops, less than 2560px)
@include media-breakpoint-down(xxl) {
}

//***************************************
// ONLY FOR RANGE
//***************************************

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, From 576px to 767.98)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, From 768px to 991.98px)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, From 992px to 1199.98px)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, From 1200px to 1439.98px)
@include media-breakpoint-only(xl) {
}
// Very Extra large devices (Very Extra large desktops, From 1440px to 2559.98px)
@include media-breakpoint-only(xxl) {
}
// 4K large devices (4K desktops, 2560px and up)
@include media-breakpoint-only(4k) {
}
