
.preferences-wrapper {
    z-index: 2;
    padding: 0 rem(20) rem(20) rem(20);
    height: 100vh;
    overflow: auto;

    .preferences-label {
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $white;
        mix-blend-mode: normal;
        padding-bottom: rem(15);
        padding-top: rem(30);
        display: block;
    }
    
    .preferences-description {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $white;
        padding-bottom: rem(15);
        display: block;
    }

    .preferences-optin-wrapper {
        background: $primary-gray-dark-extra;
        border-radius: rem(8);
        padding: rem(15) rem(20);
        margin-bottom: rem(10);
    
        font-size: rem(14);
        line-height: rem(18);
        color: $primary-gray-medium;

        &.disabled {
            opacity: 0.5;
        }

        .preferences-optin-subtitle,
        .preferences-optin-title {
            color: $white;
        }

        .preferences-optin-text {
            &.dark {
                color: $primary-gray-light;
            }
        }

        .form-switch {
            padding-left: 15px;
            margin-bottom: 0;

            .form-check-input {
                margin-left: 0;
                margin-top: 0;
            }
        }
    }

    .preferences-language-box {
        background: $primary-gray-dark-extra;
        mix-blend-mode: normal;
        border: 2px solid $primary-green;
        border-radius: rem(8);
        padding: rem(15) rem(16);

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: $white;
        }
    }
}

.manage-account {
    padding-bottom: rem(100);
    &-details {
        padding-top: rem(36);
        padding-bottom: rem(36);
        margin-bottom: rem(53);

        border-bottom: 2px solid #454545;

        .page-selector-cta {
            padding-top: rem(21);
            padding-bottom: 0;
        }
    }

    &-name, &-email {
        font-size: rem(14);
        font-weight: 700;
        line-height: rem(18);
        text-align: center;
        text-transform: uppercase;
    }

    &-name {
        margin-top: rem(16);
    }

    &-email {
        color: #AAA;
    }

    &-section {
        margin-bottom: rem(27);

        color: white;
        font-size: rem(12);

        &-title {
            padding: 0 rem(27);
            margin-bottom: rem(14);

            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        &-content {
            padding: 0 rem(27);

            font-weight: 500;
            line-height: rem(14);
        }

        .page-selector-cta {
            padding-bottom: 0;
        }

        &.logout {
            padding: rem(78) 0;
            margin: 0;
            //background: #2a2a2a;
            .page-selector-cta {
                padding-top: 0;
            }
        }
    }
}
