.time-tabs {
    position: relative;
    display: flex;
    align-items: center;
    height: rem(40);
    overflow: hidden;
    margin-left: rem(32);
    margin-right: rem(32);

    background-color: $primary-gray-soft;
    box-shadow: 0 rem(2) rem(10) rgba(0, 0, 0, 0.25);
    border-top-left-radius: rem(8);
    border-top-right-radius: rem(8);

    .cursor {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: calc(100% / 3);
        background-color: $primary-gray-dark-extra;

        transition: transform 0.25s ease-out;
    }

    > span {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 3);
        height: 100%;

        font-size: rem(12);
        font-weight: 700;
        letter-spacing: rem(1.2);
        line-height: rem(14);
        text-align: center;
        text-transform: uppercase;
    }

    &-content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: rem(400);
        box-sizing: border-box;
        padding-left: rem(10);
        padding-right: rem(10);
        margin: 0 rem(32) rem(25);
    
        border-bottom-left-radius: rem(8);
        border-bottom-right-radius: rem(8);
    
        text-align: center;
    }
}