.notification-wrapper {
    z-index: 2;
    padding: 0 rem(0) rem(20) rem(0);
    overflow: auto;
    &.notification-wrapper-props {
        height: calc(100vh - 230px);
    }
}



.notification-preferences-wrapper {
    @include page-wrapper-position;
    padding-top: rem(40);
    padding-left: $page-sides-padding-y;
    padding-right: $page-sides-padding-y;
}

.notification-preferences-box {
    background: $primary-gray-dark-extra;
    border-radius: rem(8);
    padding: rem(20) rem(8);
    margin: rem(15) 0 rem(10) 0;
    .notification-preferences-text {
        font-size: rem(14);
        line-height: rem(18);
        color: $primary-gray-medium;
        strong {
            line-height: rem(18);
            font-weight: 600;
            color: $white;
        }
    }
}

.notification-preferences-label {
    font-weight: 700;
    font-size: rem(12);
    line-height: rem(14);
    /* identical to box height, or 100% */

    letter-spacing: 1px;
    text-transform: uppercase;

    color: #FFFFFF;

    mix-blend-mode: normal;
}

.notification-preferences-marketing {
    background: $primary-gray-dark-extra;
    mix-blend-mode: normal;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: rem(40);
    padding: rem(20);

    .notification-preferences-label {
        margin: 0;
    }

    .marketing-wrapper {
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(14);
        /* or 117% */


        color: #FFFFFF;
        margin-top: rem(40);
    }
}


.notification-preferencas-intro-wrapper {
    font-size: rem(14);
    line-height: rem(18);

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: rem(18);
    margin-right: rem(18);
    z-index: 2;

    span {
        margin-bottom: rem(24);
    }

    img {
        max-width: rem(73);
        margin-top: rem(38);
        margin-bottom: rem(38);
    }
}

.update-settings-cta {
    button {
        max-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        img.button-icon {
            width: rem(26);
            height: rem(26);
            border-radius: rem(6);
            margin-right: rem(18);
        }
    }

    .view-notification-label {
        height: rem(60);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(12);
        font-weight: 600;
        line-height: rem(23);

        span {
            border-bottom: 1px solid rgba(255, 255, 255, 0.30);
            padding-bottom: rem(2);
        }
    }
}