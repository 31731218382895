.spinner-modal-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  background: rgba(29, 29, 27, 0.80);
  backdrop-filter: blur(5px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;

  .spinner-header {
    position: absolute;
    left: 0;
    top: 0;

    .row {
      align-items: center;
      min-height: rem(70);

      .col {
        padding-left: rem(20);
        padding-right: rem(20);
      }
    }
  }

  .spinner-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .spinner-container {
      width: 75px;
      height: 75px;
      position: relative;

      .spinner-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: rem(32);
          width: rem(32);
          height: rem(27);
          margin-bottom: rem(4);//visual center
        }
      }

      /*.spinner {
        animation: rotate 2s linear infinite;
      }
      
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }*/
    }

    .spinner-label {
      color: #FFF;
      text-align: center;
      font-size: rem(14);
      font-style: normal;
      font-weight: 600;
      line-height: rem(18);
      margin-top: rem(26);
      height: rem(36);
      max-width: rem(210);
      overflow: visible;
    }
  }
}