body {
    &.battery-settings {
        overscroll-behavior-y: none;
        
        .page-header {
            background-color: transparent;
            @include reset-box-shadow;
        }
    }
}

.battery-settings-wrapper {
    @include page-wrapper-position;
    padding: rem(40) rem(25);

    .label-title {
        color: white;
    }
}
.progress-circle-battery-settings {
    @include progress-circle(rem(48), rem(90), 261, 5);
    .percent svg circle {
        transform:translate(3px,3px);
    }
}
.battery-settings-values {
    margin-bottom: rem(40);
    .battery-settings-values-percent {
        min-width: rem(175);
        padding: rem(27) rem(29);

        background-color: rgba(29, 29, 27, 0.5);
        border-radius: rem(8);
        
        font-size: rem(50);
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: rem(30);
        text-align: center;
    }
}
.battery-settings-alerts {
    font-size: rem(12);
    line-height: rem(14);
    margin-bottom: rem(20);
    [class*=bat-icon] {
        font-size: rem(16);
        margin-right: rem(8);
    }
}
.battery-settings-box {
    background: rgba($primary-gray-darker, 0.75);
    box-shadow: 0px 4px 4px rgba($black, 0.25);
    border-radius: rem(8);
    padding: rem(15) rem(20);
    margin-bottom: rem(10);

    font-size: rem(14);
    line-height: rem(18);
    color: $primary-gray-medium;
    .battery-settings-box-title {
        color: $white;
        font-weight: 600;
    }
    //Last
    &.last {
        margin-top: rem(50);
    }

    &.disabled {
        opacity: 0.5;
    }

    .btn {
        &.btn-outline-secondary {
            font-size: rem(18);
            font-weight: 700;
            line-height: rem(20);
            padding: rem(3) rem(18);
            white-space: nowrap;
            &.time {
                padding: rem(3) rem(5);
            }
            //Disabled
            &.disabled,
            &:disabled {
                color: $white;
                border-color: $primary-gray-light;
                opacity: 0.2;
            }
        }
    }
}

//.battery-settings-bg {
//    clip-path: polygon(0 0, 0% 70%, 80% 0);    
//    background: linear-gradient(180deg, rgba(81, 81, 81, 0.3) 29.33%, rgba(40, 40, 40, 0.3) 64.01%);;
//    position: fixed;
//    z-index: 1;
//    width: 100%;
//    height: 100%;
//    top: 0;
//    left: 0;
//    &:after {
//        content: "";
//        position: absolute;
//        top: 0;
//    }
//}


.battery-settings-bg-value {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    overflow: hidden;
    .page-linear-gradient-bg-top-left {
        z-index: 2;
    }
}

.battery-settings-intensity {
    @include transition-common-link;
    @include absolute-center-horizontal;
    background-color: $primary-green;
    filter: blur(85px);
    width: 140%;
    height: rem(109);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    //position: fixed;
    z-index: 2;
    top: 0;
    &.low {
        background-color: white;
    }
}

.ios-picker {
    &.battery-picker {
        /*margin-top: auto;*/
        .wheel-picker-container {
            .wheel-picker-cursor {
                z-index: -1;
                &:before {
                    background-color: $primary-gray-dark-extra;
                    content: "";
                    height: rem(36) !important;
                    width: 100%;
                    top: 0px;
                    left: 0px;
                    bottom: auto;
                    transform: none;
                    z-index: 1;
                    border-radius: rem(5);
                }
            }
            .wheel-picker-cursor-panel{
                &:before {
                    height: rem(43) !important;
                }
            }
        }
    }
}

.low-battery-alert{
    &-preview{
        margin-top: rem(13);

        .preview-symbol, .preview-title{
            float: left;
        }
    
        .preview-settings{
            float: right;
        }
    
        .preview-symbol{
            width: rem(29);
            height: rem(29);
            border: 2px solid #6B6B6B;
            border-radius: 100%;
        }

        .preview-symbol::after {
            content: "";
            display: inline-block;
            position: relative;
            top: rem(4.4);
            left: rem(8);
            border-style: solid;
            border-width: rem(6.22) 0 rem(6.22) rem(12.45);
            border-color: transparent transparent transparent white;
        }
    
        .preview-title{
            margin-left: rem(10);
            font-size: rem(10);
            font-weight: 700;
            line-height: rem(12);
            letter-spacing: rem(1);
            text-align: left;
            color: #fff;
            line-height: rem(29);
        }

        .preview-settings{
            width: rem(22);
            height: rem(22);
            margin-top: rem(3.5);
        }
    }

    &-radio{

        display: flex;
        margin-bottom: rem(27);

        input[type="radio"]{
            margin-left: rem(44);
            visibility: hidden;
        }

        input[type='radio']:after {
            width: rem(29);
            height: rem(29);
            border-radius: rem(29);
            content: '';
            background-color: transparent;
            border: 2px solid #6B6B6B;
            
            display: inline-block;
            visibility: visible;
            
        }
    
        input[type='radio']:checked:after {
            width: rem(29);
            height: rem(29);
            border-radius: rem(29);
            content: '';
            background: radial-gradient(circle, #00C7B1 55%, transparent 55%);
            border: 2px solid #6B6B6B;

            display: inline-block;
            visibility: visible;
        }

        label{
            font-size: rem(14);
            font-weight: 700;
            line-height: rem(15);
            letter-spacing: rem(1);
            text-align: left;
            color: #fff;
            margin: rem(7) 0 0 rem(35);
        }

    }
}

.fw-update-vrr{
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: #0000001A;
    backdrop-filter: blur(90px);
    -webkit-backdrop-filter: blur(90px);

    &-header{
        width: 100%;
        height: rem(86);
        background-color: #1D1D1B;
        display: flex;
        align-items: center;

        .fw-update-vrr-title{

            width: rem(200);
            font-size: rem(18);
            font-weight: 800;
            line-height: rem(18);
            text-align: center;
            margin: 0 auto;

        }

        .bat-icon-close{
            position: fixed;
            top: rem(34);
            right: rem(27);
        }

    }

    &-body{
        
        position: absolute;
        top: rem(157);
        

        .fw-update-vrr-icon{
            width: rem(68);
            height: rem(68);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .fw-update-vrr-title{
            font-size: rem(40);
            font-weight: 800;
            line-height: rem(38);
            letter-spacing: rem(-0.5);
            text-align: center;
            color: #fff;
            width: 100%;
            margin-top: rem(92);
        }

        .fw-update-vrr-descr{
            font-size: rem(14);
            font-weight: 400;
            line-height: rem(16.8);
            text-align: center;
            color: #fff;
            width: rem(294);
            margin: rem(24) auto 0;
        }

    }

    &-cta{

        width: rem(337);
        height: rem(50);
        border-radius: rem(8);
        background: linear-gradient(92.69deg, #5709A4 17.73%, #750BDD 87.7%);
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: rem(60);
        left: 50%;
        transform: translateX(-50%);

        .fw-update-vrr-cta-text{
            font-size: rem(14);
            font-weight: 700;
            line-height: rem(15);
            letter-spacing: rem(1);
            color: #fff;
        }

        .bat-icon-download{
            color: #fff;
            margin-left: rem(19);
        }

    }

}