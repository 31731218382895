.device-switcher {
  z-index: 1054;
  background-color: var(--bs-body-bg);

  .dashboard-carousel {
    display: flex;
    flex-direction: column;

    .swiper-container {
      height: 100%;
      padding-bottom: 1rem;
    }
  }

  .label-title {
    height: 4.375rem;
    justify-content: space-between;
  }

  &.market-dark .label-title,
  &.market-light .label-title {
    pointer-events: initial;
  }

  &-icon-close {
    color: white;
    font-size: 0.875rem;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1054;

    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.75);
  }

  .sortable-list {
    height: 100%;
    padding-bottom: rem(15);

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      /* Hide scrollbar for Chrome, Safari and Opera */
      width: 0;
      height: 0;
      display: none;
    }

    &.sortable-list-horizontal {
      overflow-x: auto;
      width: 100%;
      grid-auto-flow: column;

      display: grid;
      grid-gap: rem(10);

      scroll-snap-type: x mandatory;

      .sortable-item {
        // scroll-behavior: smooth;
        scroll-snap-align: center;
        justify-content: center;

        &.selected-slide {
          scroll-snap-align: none;
        }
      }
    }
  }

  .sortable-list-dragging .swiper-container {
    overflow-x: auto;
  }

  .swiper-slide {
    opacity: 1 !important;
  }

  .sortable-item {
    display: flex;
    flex-direction: column;

    height: 100%;

    -webkit-user-select: none;
  }

  .device-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: rem(16);

    background-color: black;
    background-position-x: right;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    color: white;

    transition: transform 0.25s ease-in-out;
    will-change: transform;

    height: 80%;
    width: rem(150);

    @media (min-height: 600px) {
      height: 78%;
      width: rem(176);
    }

    @media (min-height: 700px) {
      height: 80%;
      width: rem(200);
    }

    @media (min-height: 800px) {
      height: 82%;
      width: rem(215);
    }

    &-header {
      align-self: flex-end;
    }
  }

  .selected-slide {
    &.device-card {
      transform: scale(0.85);
    }
  }

  .device-reorder-icon {
    display: flex;
    flex-wrap: wrap;
    align-self: center;

    width: 2.1875rem;
    margin-top: 1.75rem;
    margin-bottom: -0.3125rem;

    > span {
      height: 0.3125rem;
      width: 0.3125rem;
      margin-right: 0.3125rem;
      margin-bottom: 0.3125rem;

      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 50%;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  .btn-remove-device {
    $height: 1.5rem;

    box-sizing: border-box;
    height: $height;
    padding: 0 1rem;

    border: 0.125rem solid var(--bs-danger);
    border-radius: calc($height / 2);

    font-size: rem(10);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;

    &:disabled {
      opacity: 0.5;
    }

    @media (max-height: 600px) {
      $height: rem(16);
      padding: 0 rem(10);
      font-size: rem(9);
    }
  }

  .device-card-name {
    margin-bottom: rem(8);
    font-size: rem(14);
    font-weight: 300;
    line-height: 1.5rem;

    @media (max-height: 600px) {
      margin-bottom: rem(4);
      font-size: rem(16);
    }
  }

  .device-card-info {
    display: flex;
    justify-content: space-between;
    gap: rem(8);

    @media (max-height: 600px) {
      flex-direction: column;
    }
  }

  .device-card-connection-status {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    font-size: rem(8.4);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;

    @media (max-height: 600px) {
      font-size: rem(9);
    }

    &:before {
      height: 0.5rem;
      width: 0.5rem;

      background-color: var(--bs-red);
      border-radius: 50%;

      content: "";
    }

    &.connected:before {
      background-color: var(--bs-green);
    }
  }

  .device-card-battery-status {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    font-size: rem(8);

    > i {
      font-size: rem(14) !important;
    }

    &.dark-market {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      flex-direction: column;

      font-size: 2rem;

      .percent {
        height: 6rem;
        width: 6rem;

        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }

      [class*="bat-icon-battery"] {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: -0.75rem;

        font-size: 2.25rem;
      }
    }

    .device-card-battery-status-label {
      font-weight: 300;
      font-size: rem(12);
      line-height: rem(18);
      text-align: center;
      letter-spacing: rem(0.52);
      text-transform: uppercase;
    }
  }

  &-footer {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: center;

    position: relative;
    padding-bottom: rem(15);
  }

  .btn-pair-device {
    $height: 2.25rem;

    position: absolute;
    right: 1rem;
    min-width: 4.75rem;
    padding: 0;

    height: $height;
    border-radius: calc($height / 2);
    border: 0.125rem solid rgba(255, 255, 255, 0.4);

    font-size: 1rem;
    text-align: center;
  }

  &-tip {
    max-width: 15.625rem;

    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    opacity: 0;
    will-change: transform, opacity;
    @include carousel-tip-animation;
  }

  &-max-limit-popup {
    .sliding-panel-header {
      padding: 1.5rem !important;
    }

    .sliding-panel-title {
      line-height: 1 !important;
    }

    .sliding-panel-content {
      p,
      .btn {
        margin: 0 1.875rem;
      }
    }
  }
}

.device-switcher-page {
  .sliding-panel-overlay {
    z-index: 1055;
  }
}
