.data-syncing{
    /*width: rem(143);*/
    /*height: rem(30);*/
    width: fit-content;
    position: absolute;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    padding: rem(8) rem(15);
    box-sizing: border-box;
    gap: rem(10);
    z-index: 99;
    border-radius: rem(30);
    background-color: #005f7d;
    background-image: linear-gradient(#04a19e, #048490);
    display: table;

    font-size: rem(10);
    font-weight: 700;
    letter-spacing: rem(1);
    text-align: center;

    span{
        display: table-cell;
        vertical-align: middle;
    }

    .data-syncing-loader {
        border-width: rem(1);
        border-style: solid;
        border-color: #FFF #FFF #FFF #FFFFFF4D;
        width: rem(12);
        height: rem(12);
        border-radius: 50%;
        animation: spin 1s linear infinite;
        position: relative;
        
        margin: 0 0 0 rem(10);
    
        &:before, &:after {
            content: '';
            width: rem(1);
            height: rem(1);
            border-radius: 50%;
            background: #FFF;
            position: absolute;
            left: rem(0.5);
        }
        
        &:before {
            top: rem(0.7);
        }
        
        &:after {
            bottom: rem(0.7);
        }
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

}

.homescreen-data-syncing{ top: rem(165); }
.ut-data-syncing{ top: rem(385); }
.pt-data-syncing{ top: rem(290); }

.data-syncing-panel{

    &-info{
        width: 84%;
        margin-left: 8%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin-top: rem(-68.5);
        
        .data-syncing-info-loader {
            border-width: rem(3);
            border-style: solid;
            border-color: #00C7B1 #00C7B1 #00C7B1 #FFFFFF4D;
            width: rem(40);
            height: rem(40);
            border-radius: 50%;
            animation: spin 1s linear infinite;
            position: relative;
            
            margin: 0 auto rem(45);
        
            &:before, &:after {
                content: '';
                width: rem(3);
                height: rem(3);
                border-radius: 50%;
                background: #00C7B1;
                position: absolute;
                left: rem(2);
            }
            
            &:before {
                top: rem(3);
            }
            
            &:after {
                bottom: rem(3);
            }
        }
        
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

    }

}