body {
  &.pairing {
    .page-header {
      background-color: transparent;
      @include reset-box-shadow;
    }
  }
}

.pairing-wrapper {
  @include page-wrapper-position;
}

.pairing-title {
  padding: rem(20) $page-sides-padding-y;
  text-align: center;
  position: relative;
  z-index: 2;
  h1,
  h2 {
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(18);
    color: $white;
    margin-bottom: rem(5);
  }
  h2 {
    font-size: rem(12);
    line-height: rem(16);
    color: $primary-gray-medium;
  }
}

.pairing-content {
  margin-bottom: rem(23);
  font-size: rem(12);
  text-align: center;
}

.rsw_2Y {
  height: 100%;
}
.rsw_2f {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pairing-device {
  height: 45vh;
  width: 100%;
  &.pairing-device-locked,
  &.pairing-device-unlocked {
    @include transition-slider;
    background-image: url(../img/pair-device-locked.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &.pairing-device-unlocked {
    background-image: url(../img/pair-device-unlocked.webp);
  }
}

.pairing-unlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: rem(40) $page-sides-padding-y;

  [class*="bat-icon"] {
    @include transition-slider;
    font-size: rem(30);
    line-height: 0;
    color: $white;
    opacity: 0.3;
    &.active {
      opacity: 1;
    }
  }
  .unlock-switch {
    max-width: rem(192);
    margin: 0 rem(10);
  }
  .unlock-switch-outline {
    max-width: rem(230);
    margin: auto;
  }
  .unlock-text {
    text-align: center;
    margin-bottom: rem(20);
    font-size: rem(12);
    letter-spacing: rem(1);
    text-transform: uppercase;
  }
}

.device-pair-paired-disc {
  z-index: 2;
  margin-left: rem(54);
  margin-top: rem(25);
  margin-right: rem(54);
  margin-bottom: rem(40);
  text-align: center;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $white;
  }
}

.pairing-customization-panel {
  backdrop-filter: blur(10px);
  background: linear-gradient(180deg, #32353500, #1F2122FF) !important;
  margin-top: 0 !important;
  height: 100vh !important;

  .sliding-panel-header {
    background-color: #1D1D1B !important;
    height: rem(72) !important;
    flex: 0 0 rem(72) !important;
    position: relative;
    margin-bottom: rem(36);

    .sliding-panel-icon-close {
      order: 2;
      padding: 0;
      padding-right: 1.5rem;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    .sliding-panel-title-wrapper {
      margin-right: 2rem;

      .sliding-panel-title {
        width: 100%;
        max-width: 100% !important;
        font-size: rem(18);
        font-weight: 800;
        line-height: rem(18);
        text-transform: uppercase;
      }
    }
  }

  .sliding-panel-content {
    padding: 0 0 0 0 !important;
    /*
            height: 100%;
            display: flex;
            flex-direction: column;
        */
  }

  .page-selector-cta {
    .page-selector-cta-url {
      display: none;
    }
  }
}

.pairing-loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(29, 29, 27, 0.8);
  backdrop-filter: blur(10px);

  .pairing-loading-overlay-label {
    color: $white;
    text-align: center;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;

    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    margin-top: rem(36);

    > span {
        display: block;
        font-weight: 400;
        white-space: nowrap;
    }
  }
}

.pairing-device-name {
  z-index: 2;
  margin-top: rem(29);

  h1 {
    font-weight: 300;
    font-size: rem(26);
    line-height: rem(26);
    text-align: center;
  }
}

.pairing-device-photo {
  background-image: url(../img/pairing-completed/ePod2/black-origami.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
}

.pairing-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
}

.pairing-sm-video {
  width: 90%;
  height: 54vh;
  object-fit: cover;
  position: fixed;
  z-index: 2;
  left: 5%;
  top: 21vh;
  border-radius: 20px;
}

.pairing-steps {
  height: 100%;
  z-index: 2;
}

.pairing-bg-gradient {
  height: 50%;
  background: linear-gradient(#282828, transparent);
  position: absolute;
  width: 100%;
  z-index: 1;
  top: $header-height;
}

.activate-device-info, .pair-device-info{
  text-align: center;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.sliding-device-check-bluetooth-wrapper {
  .sliding-panel-title {
    font-size: 18px !important;
    font-weight: 800 !important;
    line-height: 18px !important;
    text-align: center !important;
    padding: rem(21) rem(16) !important;
  }
}