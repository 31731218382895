.shop-orders-history-wrapper {
    background-color: #EFEFEF;
    width: 100vw;

    .shop-orders-history-image {        
        position: relative;
        &:before {
            content: "";
            position: absolute;
            background: linear-gradient(180deg, rgba($black, 0) 0%, $black 100%);
            opacity: 0.5;
            width: 100%;
            height: 100%;
            z-index: 2;
        } 
        img {
        position: relative;
            z-index: 1;
            width: 100%;
            max-height: 12.5rem;
            object-fit: contain;
        }
    }


    .shop-orders-history-title {
        position: relative;
        z-index: 1030;
        margin-top: -9rem;
        width: 100%;
        padding-bottom: rem(27);

        h2 {
            text-transform: uppercase;
            text-align: center;
            color: $white;
            margin-bottom: 0;
        }

        .shop-filter {
            margin-top: 0;

            .label-title {
                color: $white;
                padding-left: 0;
            }
        }
    }

    .shop-orders-history-list {
        padding: rem(27) rem(17);

        .shop-reorder-summary {
            position: inherit;
            bottom: 0;
            box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
            margin-bottom: rem(20);
        }
    }

}

.order-detail-wrapper {
    background: $white;
    
    .order-details-label {
        width: 100vw;
        display: flex;
        justify-content: flex-start;
        padding: rem(15) rem(25);
        align-items: center;
        justify-content: space-between;
        
    
        &.dark{
            color: $white;
            background: $primary-gray-dark-extra;
            .packs-label {
                h4 {
                    color: $white;   
                }
            }
        }
    
        &.light{
            color: $primary-gray-dark-extra;
            background: $primary-gray-light-extra;
            .packs-label {
                h4 {
                    color: $primary-gray-dark-extra;   
                }
            }
        }
    
        &.danger{
            color: $red;
            background: #F0DDE1;

            .packs-label {
                h4 {
                    color: $red;   
                }
            }
        }
    
        span {
            font-weight: 700;
            font-size: rem(12);
            line-height: rem(14);
            letter-spacing: rem(1.2);
            text-transform: uppercase;
        }

        .packs-label {
            display: flex;
            align-items: center;

            span {
                color: #AAAAAA;

                h4 {
                    display: inline;
                    margin: 0;
                }
            }
        }
        
    }
}
