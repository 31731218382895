.usage-tracker {
  .page-linear-gradient-bg {
    z-index: -1;
  }

  /*&.usage-stats {
    background-image: linear-gradient(
      180deg,
      rgba(109, 109, 109, 0.25) 0%,
      rgba(0, 95, 125, 0) 100%
    );
  }*/

  .dark {
    color: $primary-gray-light;
  }

  &-header {
    position: sticky;
    top: 0;
    z-index: 1053;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(70);
    padding: 0 rem(32);

    background-color: $primary-gray-soft;

    > div {
      width: 20%;

      &:last-child {
        text-align: right;
      }
    }

    .tutorial-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(45);
      width: rem(45);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border: rem(2) solid rgba(255, 255, 255, 0.25);

      > img {
        transform: translateX(rem(2));
      }
    }

    .title {
      width: auto;
      color: white;
      font-weight: 700;
      font-size: rem(12);
      line-height: rem(14);
      letter-spacing: rem(1.8);
      text-align: center;
      text-transform: uppercase;
    }

    .close-icon {
      font-size: rem(14);
    }
  }

  &-overlay {
    &.sliding-common-wrapper {
      display: flex;
      align-items: flex-end;
      z-index: 1053;

      .sliding-panel.onboarding-panel.mid-height-panel {
        height: rem(400);

        background-image: none;
        border-radius: rem(8);
      }

      .devices-list {
        margin: 0;
        padding: rem(25) 0;

        > li {
          box-sizing: border-box;
          padding: rem(10) rem(25);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);

          text-transform: uppercase;

          .device {
            display: flex;
            align-items: center;
            gap: rem(20);
          }

          .device-name {
            overflow: hidden;
            max-width: rem(250);

            color: #dedede;
            font-size: rem(12);
            font-weight: 600;
            letter-spacing: 1px;
            line-height: rem(15);
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .device-number {
            color: rgba(255, 255, 255, 0.3);
            font-size: rem(10);
            font-weight: 700;
            letter-spacing: 1px;
            line-height: rem(12);
          }

          .bullet {
            box-sizing: border-box;
            height: rem(14);
            width: rem(14);
            border: rem(2) solid rgba(255, 255, 255, 0.1);
            border-radius: 50%;
          }

          &.selected .bullet {
            background-color: white;
          }
        }
      }
    }
  }

  &-wrapper {
    box-sizing: border-box;
    padding: 0 rem(25);
  }

  &-stats-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    height: rem(67);
    width: rem(67);
    margin: rem(30) auto rem(14);

    border-radius: 50%;
    border: rem(2) solid $primary-green;

    > span {
      font-size: rem(28);
    }
  }

  &-title {
    margin-bottom: rem(60);

    font-size: rem(40);
    font-weight: 800;
    line-height: rem(38);
    text-align: center;
  }

  &-powered-by {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(4);

    font-size: rem(12);

    img {
      width: rem(60);
    }
  }

  .btn-outline-light {
    border-color: rgba(255, 255, 255, 0.2);
  }

  &-device-select {
    display: flex;
    justify-content: flex-end;
    margin: rem(24) rem(32);

    &-btn {
      display: flex;
      align-items: center;
      gap: rem(8);
      min-height: rem(24);
      padding: rem(5) rem(16);
      box-sizing: border-box;

      /*box-shadow: inset 0 0 0 rem(2) rgba(255, 255, 255, 0.3);*/
      border: rem(1) solid #AAA;
      border-radius: rem(13);

      color: white;
      font-size: rem(10);
      font-weight: 700;
      letter-spacing: 1px;
      line-height: rem(12);
      text-transform: uppercase;

      > div {
        overflow: hidden;
        max-width: rem(120);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.skin-black {
        background-image: $epod2-black-gradient;

        &.ePod3 {
          background-image: $epod3-black-gradient;
        }

        &.SmartBox{
          background-image: $smartBox-black-gradient;
        }

      }

      &.skin-blue {
        background-image: $epod2-blue-gradient;

        &.ePod3 {
          background-image: $epod3-blue-gradient;
        }

        &.SmartBox{
          background-image: $smartBox-blue-gradient;
        }
      }

      &.skin-cyan {
        background-image: $epod2-cyan-gradient;

        &.ePod3 {
          background-image: $epod3-cyan-gradient;
        }
      }

      &.skin-gold {
        background-image: $epod2-gold-gradient;

        &.ePod3 {
          background-image: $epod3-gold-gradient;
        }
      }

      &.skin-pink {
        background-image: $epod2-pink-gradient;

        &.ePod3 {
          background-image: $epod3-pink-gradient;
        }

        &.SmartBox{
          background-image: $smartBox-pink-gradient;
        }
      }

      &.skin-red {
        background-image: $epod2-red-gradient;

        &.ePod3 {
          background-image: $epod3-red-gradient;
        }
      }

      &.skin-reflekta {
        background-image: $epod2-reflekta-gradient;
      }
    }
  }

  &-tabs { margin-top: 0; }

  &-reconnect-container {
    margin: 0 rem(32);
    height: rem(63);
    background: #1D1D1B;
  }

  &-reconnect-sub {
    width: 100%;
    height: 100%;
    padding: 0 rem(12);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-puffs-slider {
    justify-content: space-between;
    box-shadow: 0 rem(8) rem(10) rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(180deg, #202020 -2.01%, #272727 64.01%);
    padding-top: rem(20);
    padding-bottom: rem(20);
    gap: rem(20);

    &.monthly {
      min-height: auto;
      gap: rem(15);
    }

    + div {
      margin-top: 0;
    }
  }

  &-puffs-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(30);
      width: rem(30);
      padding: 0;

      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 50%;

      &.hide {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }

    > div {
      font-size: rem(18);
      font-weight: 800;
      line-height: rem(18);
    }

    .text-transparent {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  &-bubble {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: rem(145);
    width: rem(145);
    margin: auto;
    border-radius: 50%;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: rotate(270deg);
      background: radial-gradient(
        circle,
        rgba(0, 199, 177, 1) 5%,
        rgba(0, 95, 125, 1) 80%,
        rgba(0, 53, 47, 1) 100%
      );
      box-shadow: inset rem(10) rem(15) rem(20) rem(5) rgba(0, 0, 0, 0.25);
      background-size: 120% 130%;
      border-radius: 50%;
      filter: blur(rem(0.5));
      content: "";
    }

    &.disconnected {
      opacity: 0.5;
    }

    &.no-puffs {
      background-color: transparent;
      background-image: url("../img/usage-tracker/no-puffs-bubble.svg");
      background-size: cover;

      &:before {
        content: none;
      }
    }

    &.syncing{
      opacity: 0.1;
    }

    > div {
      position: relative;
      z-index: 1;
    }
  }

  &-puff-count {
    font-size: rem(50);
    font-weight: 800;
    line-height: rem(50);
  }

  &-puff-label {
    color: rgba(255, 255, 255, 0.5);
    font-size: rem(14);
    font-weight: 700;
    letter-spacing: rem(1.2);
    text-transform: uppercase;
  }

  &-last-refresh {
    font-size: rem(10);
    font-weight: 700;
    letter-spacing: rem(1.2);
    line-height: rem(14);
    text-transform: uppercase;

    > div:first-child {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &-weekdays {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    width: 100%;
    margin: 0;
    padding: 0 rem(10);
    list-style: none;

    &.syncing{
      opacity: 0.1;
    }

    > li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        justify-content: flex-end;
        color: rgba(255, 255, 255, 0.5);
        font-size: rem(10);
        font-weight: 700;
        letter-spacing: rem(1.2);
        line-height: rem(12);
        text-align: right;
        text-transform: uppercase;
      }

      .weekday {
        width: rem(30);
        color: rgba(255, 255, 255, 0.5);
        font-weight: 700;
        font-size: rem(10);
        letter-spacing: rem(1.2);
        line-height: rem(19);
        text-transform: uppercase;
      }

      .bar {
        flex-grow: 1;
        padding-left: rem(15);
        padding-right: rem(25);
        text-align: left;

        > span {
          display: inline-block;
          height: rem(10);
          min-width: rem(16);

          background-color: #00c7b1;
          background-image: linear-gradient(90deg, #00c7b1 0%, #005f7d 100%);
          border-radius: rem(5);
        }
      }

      .puffs {
        font-weight: 800;
        font-size: rem(25);
        line-height: rem(30);
      }

      &.no-data {
        .puffs {
          color: $secondary;
        }

        .bar > span {
          background-color: $secondary;
          background-image: none;
        }
      }
    }
  }

  &-section {
    margin-top: -#{rem(10)};
    padding-top: rem(25);
    padding-bottom: rem(20);
    background-color: $dark;
    border-top-left-radius: rem(16);
    border-top-right-radius: rem(16);

    &.comparison {
      background-color: #272727;
      box-shadow: inset 0 rem(25) rem(40) rgba(0, 0, 0, 0.25);

      .usage-tracker-section-title {
        > span:nth-child(1) {
          color: $green;
        }

        > span:nth-child(2) {
          font-size: rem(9);
        }
      }
    }

    &.sessions {
      background-color: #212121;
      box-shadow: inset 0 rem(4) rem(10) rgba(0, 0, 0, 0.15);
    }

    &-title {
      padding: rem(10) rem(20) rem(10) rem(32);

      font-weight: 700;
      font-size: rem(12);
      line-height: rem(14);
      letter-spacing: rem(0.8);
      text-transform: uppercase;

      .info-icon {
        font-size: rem(18);
      }
    }

    &-content {
      padding: rem(25) rem(32);

      .chart {
        background: #212121;
        border-radius: rem(8);
        box-shadow: 0 rem(8) rem(10) rgba(0, 0, 0, 0.15);
        padding-left: rem(10);
        padding-bottom: rem(4);
      }
    }

    .session-info {
      position: relative;
      box-sizing: border-box;
      padding: rem(20) 0 rem(20) rem(60);
      background-color: #005f7d;
      background-image: url("../../_assets/img/usage-tracker/what-is-a-session-bg.webp");
      background-size: cover;

      .usage-icon,
      .session-close-icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 50%;
      }

      .usage-icon {
        left: rem(18);
        top: rem(10);
        height: rem(40);
        width: rem(40);
        border: rem(2) solid rgba(255, 255, 255, 0.2);

        > span {
          font-size: rem(16);
        }
      }

      .session-close-icon {
        top: rem(9);
        right: rem(14);
        height: rem(28);
        width: rem(28);
        background: rgba(0, 0, 0, 0.1);

        > span {
          font-size: rem(12);
        }
      }

      &-title {
        padding-left: rem(18);
        padding-right: calc(rem(20) + rem(28));
        margin: 0 auto rem(12);
        font-size: rem(14);
        line-height: rem(18);
        font-weight: 800;
        text-transform: uppercase;
      }

      &-content {
        padding-left: rem(18);
        padding-right: rem(18);
        margin: 0 auto;
        font-size: rem(12);
        font-weight: 600;
        line-height: rem(16);
      }
    }
  }

  &-stats-box {
    flex-grow: 1;
    padding: rem(8);

    background-color: #313131;
    box-shadow: 0 rem(8) rem(10) rgba(0, 0, 0, 0.15);
    border-radius: rem(8);

    &-top {
      gap: rem(9);
    }

    &-label {
      font-weight: 700;
      font-size: rem(12);
      line-height: rem(20);
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &-value {
      font-weight: 800;
      font-size: rem(36);
      line-height: rem(30);
      text-transform: uppercase;
    }

    &.session {
      .usage-tracker-stats-box-label {
        margin-bottom: rem(16);
        font-size: rem(10);
        line-height: rem(20);
      }
    }

    .bar {
      height: rem(15);
      min-width: rem(16);
      margin-top: rem(8);

      background-color: $green;
      background-image: linear-gradient(90deg, #00c7b1 0%, #005f7d 100%);
      border-radius: rem(8);
    }

    &.avg {
      position: relative;
      display: flex;
      height: rem(100);
      padding: 0;
      background-color: $dark;

      .indicator {
        position: absolute;
        left: 50%;
        bottom: rem(36);
        transform: translateX(-50%);
        z-index: 1;
        height: rem(10);
        width: rem(10);

        background-color: #232323;
        border: rem(2) solid white;
        border-radius: 50%;

        &.current {
          background-color: $green;
          border-color: $green;

          &:before {
            position: absolute;
            left: 50%;
            bottom: calc(100% + 0.5625rem);
            transform: translateX(-50%);
            color: $green;
            content: attr(data-value);
            font-weight: 800;
            font-size: rem(28);
            line-height: rem(19);
          }

          &.left:before {
            left: 0;
            transform: none;
          }

          &.right:before {
            left: initial;
            right: 0;
            transform: none;
          }
        }
      }

      .bottom {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(40);
        width: 100%;
        margin-top: auto;
        background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.0005) 100%
        );

        font-weight: 700;
        font-size: rem(10);
        letter-spacing: rem(1.2);
        line-height: rem(19);
        text-transform: uppercase;

        &:before {
          position: absolute;
          left: 0;
          bottom: 100%;
          height: rem(2);
          width: 100%;

          background-image: linear-gradient(-90deg, #00c7b1 0%, #005f7d 100%);
          content: "";
        }
      }
    }
  }

  .preset-icon {
    position: relative;
    height: rem(24);
    width: rem(24);

    background-color: #1d1d1b;
    box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.5);
    border-radius: 50%;

    .circle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      border: 1px solid white;
      border-radius: 50%;

      &.outer {
        height: rem(16);
        width: rem(16);
      }

      &.inner {
        height: rem(8);
        width: rem(8);
      }
    }

    &.low .outer {
      display: none;
    }

    &.med .inner {
      display: none;
    }
  }

  &-disable-panel {
    &.sliding-panel-overlay.sliding-common-wrapper
      .sliding-panel.onboarding-panel.mid-height-panel
      .sliding-panel-title-wrapper
      .sliding-panel-title {
      max-width: 80%;
      margin: 0 auto;
    }

    .dark {
      color: $primary-gray-light;
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(18);
      text-align: center;
    }
  }

  .chart {
    height: rem(300);
    width: 100%;
    user-select: none;
  }
}
