@keyframes alert-slide-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes alert-slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.hard-age-verification {
  .page-header {
    background: none;
    box-shadow: none;
  }

  .alt {
    width: 100%;
    max-width: 100%;
    padding-left: rem(26);
    padding-right: rem(26);
  }

  &-scan-tips {
    padding: 0 !important;
    color: white !important;
    animation: alert-slide-left 0.5s both;

    &.out {
      animation: alert-slide-right 0.5s both;
    }

    h2 {
      margin: 0 !important;
    }

    &-header {
      position: relative;
      min-height: rem(60);
      background-color: $primary;
      background-image: $gradient-primary;
      font-size: rem(18);
      font-weight: 800;
      line-height: rem(20);
      display: flex;
      align-items: center;

      & > div {
        padding-left: calc(rem(25) + 1rem + rem(6));
        padding-right: calc(rem(25) + 1rem + rem(6));
      }

      .close-icon {
        position: absolute;
        top: 50%;
        right: rem(25);
        transform: translateY(-50%);
        font-size: 1rem;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 1.5rem 15%;
      text-align: left;

      li {
        display: flex;
      }

      > li:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    img {
      width: rem(24);
      margin-right: rem(25);
    }
  }

  &-title{
    margin-top: rem(35);

    h1 {
      margin-bottom: rem(25);
    }
  }

  &-title-ca{
    margin-top: rem(36);

    h1 {
      margin-bottom: rem(42);
    }

  }

  &-title, &-title-ca {
    margin-left: rem(26);
    margin-right: rem(26);

    h1 {
      font-size: rem(24);
      font-weight: 400;
      line-height: rem(26);
    }

    p, ul {
      color: $primary-gray-medium;
      font-size: rem(14);

      &.info {
        color: white;
        font-size: rem(12);
        font-style: normal;
        font-weight: 400;
        line-height: rem(14);

        li{
          margin-top: rem(10);
        }

      }
    }

    .alert-icon, .id-icon {
      display: block;
      font-size: rem(62);
    }

    .alert-icon{ margin: rem(106) auto rem(98); }

    .id-icon{ margin: rem(106) auto rem(98); }
  }

  &-info{

    padding: rem(12) rem(26);

    ul {
      margin-left: rem(46);
      margin-right: rem(26);
      padding: 0;
    }

    .alert-icon { left: rem(-26); }

  }

  &-info-ca{

    padding: rem(12) rem(49);

    .alert-icon { left: rem(-49); }

    ul{ padding: 0; }
    ol{ padding-left: rem(14); }

  }

  &-info, &-info-ca {
    background-color: $primary-gray-dark-extra;
    color: $primary-gray-medium;
    font-size: rem(12);

    ol{ margin: rem(10) 0; }

    ul {
      margin-top: 0;
      margin-bottom: 0;
      list-style: none;

      > li {
        position: relative;

        &:not(:last-child) {
          margin-bottom: rem(12);
        }
      }
    }

    .alert-icon {
      position: absolute;
      top: 0;
      left: rem(-26);
      font-size: 1rem;
    }
  }

  &-no-bg {
    background-color: transparent;
    padding: 1rem 0;
    color: $primary-gray-medium;
    font-size: rem(14);
    max-width: 23.75rem;
    text-align: center;
    margin: 0 auto;
  }

  &-info-bottom, &-info-bottom-ca{

    color: white;
    text-align: center;
    font-size: rem(12);
    font-style: normal;
    font-weight: 400;
    line-height: rem(14);

    & > div {
      padding: rem(16) rem(16);
      white-space: pre-line;

      &.large {
        padding: rem(16) rem(26);
      }

      a {
        color: #fff
      }
    }

  }

  &-failure-desc {
    max-width: rem(315);
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(50);
    font-size: rem(14);
    text-align: center;

    &.alt {
      margin-top: 0;
      text-align: left;
    }

    > div:first-child {
      margin-bottom: 1rem;
    }
  }

  &-support {
    width: calc(100% - 3.125rem);
    box-sizing: border-box;
    margin: rem(50) auto;
    background-color: $primary-gray-dark-extra;
    font-size: rem(14);

    &.alt {
      margin: 0 auto;
      background-color: transparent;
      position: absolute;
      top: rem(564);

      small {
        font-size: rem(12);
        font-weight: 600;
      }

      ul > li {
        margin-bottom: 0 !important;
        padding: 1rem 0;
        border-top: 1px solid $secondary;
        border-bottom: 1px solid $secondary;

        &:last-child {
          border-top: none;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: rem(26);
      margin-bottom: 0;

      > li:not(:last-child) {
        margin-bottom: rem(12);
      }
    }
  }

  &-processing {
    &-text {
      max-width: rem(250);
      margin-top: rem(75);
      margin-left: auto;
      margin-right: auto;
      font-size: rem(24);
      line-height: rem(26);
      text-align: center;
    }

    &-subtext{
      width: 100%;
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(18);
      text-align: center;
      color: white;
      top: rem(247);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-lottie-wrapper, &-lottie-wrapper-ca {
    position: relative;
    margin: rem(35) 0;

    .lottie-container{

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

    }

    .lottie-content {

      img {
        display: block;
        height: rem(28);
        margin-left: auto;
        margin-right: auto;
      }

      .url-text {
        font-size: rem(12);
      }
    }

    .tips-label{

      margin-top: 6px !important;
      width: 100px;
      text-align: center;

    }
  }

  &-lottie-wrapper-ca{
    margin-bottom: rem(24) !important
  } 

  &-powered-by {
    position: fixed;
    bottom: 4vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    opacity: 0.5;
    text-align: center;
  }

  &-consent-container {
    height: calc(100vh - $header-height);
  }
}

.face-capture-wrapper {
  & > div {
    & > div {
      & > div:last-child {
        margin-top: rem(35);
      }
    }
  }
}