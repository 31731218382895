.notification-permission-panel-wrapper {
  z-index: 2;
  height: 100%;

  .notification-permission-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .buttons-container {
      padding-top: 10px;
    }

    .instructions-container {
      ol {
        li {
          text-align: left;
          padding: 10px 0;

          .icon {
            padding: 0 10px;
            width: 50px;
          }
        }
      }
    }
  }
}