.feature-widget-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  row-gap: rem(24);
  column-gap: rem(16);
  margin-top: rem(0);
  margin-bottom: rem(24);

  &-title {
    margin-bottom: rem(30);

    color: white;
    font-size: rem(12);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .feature-widget {
    aspect-ratio: 1 / 1;
    min-height: auto;

    &.full {
      aspect-ratio: auto;
      grid-column: 1 / -1;
    }
  }
}

.feature-widget {
  position: relative;
  min-height: rem(100);
  overflow: hidden;

  background-color: $primary-gray-dark-extra;
  background-position: center;
  background-size: cover;
  border-radius: rem(15);
  box-shadow: 0 rem(3) rem(7) 0 rgba(0, 0, 0, 0.25) inset;

  color: white;
  font-size: rem(10);
  font-weight: 700;
  line-height: rem(12);
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &.flat {
    box-shadow: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  > div {
    position: relative;
    z-index: 1;
  }

  &-icon {
    margin-bottom: rem(10);
    font-size: rem(20);

    > img {
      max-height: rem(24);
      max-width: 80%;
    }
  }

  &-label {
    display: flex;
    align-items: flex-end;
    min-height: rem(24);
    padding: 0 rem(5);
    overflow-wrap: anywhere;
  }

  .user-avatar {
    height: rem(36);
    width: rem(36);
    font-size: rem(16);
  }
}
