.cookie-policy-alert {
    max-height: 80vh;

    h2 {
        text-align: left !important;
        color: $white;
        font-style: normal;
        font-weight: normal !important;
    }

    div:nth-child(2) {
        &:first-of-type{
            overflow-y: auto;
        }
    }

    .cookie-element-wrapper {
        background: $primary-gray-dark-extra;
        border-radius: 10px;

        padding: rem(22) rem(15) rem(13) rem(15);

        .cookie-element-category {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;

            color: #FFFFFF;
        }

        .cookie-element-title {
            font-weight: 600;
            font-size: rem(12);
            line-height: rem(14);
            display: flex;
            align-items: center;

            color: #FFFFFF;
        }

        .cookie-element-life {
            font-weight: 700;
            font-size: rem(10);
            line-height: rem(12);
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #AAAAAA;

        }

        .cookie-element-description {
            font-size: rem(12);
            line-height: rem(14);
            color: #AAAAAA;
        }
    }
}