.dashboard {
  .sliding-panel-overlay {
    z-index: 9999;
  }
}

.dashboard-nav-link {
  background: $primary-gray-dark;
  box-shadow: 0px rem(4) rem(10) rgba(0, 0, 0, 0.15);
  padding: rem(17) rem(25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: rem(64);
  z-index: 2;
  height: rem(62);

  .dashboard-link {
    text-decoration: none;
    mix-blend-mode: normal;
    font-style: 400;
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(14);
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: rem(0.9);
    text-transform: uppercase;

    color: #ffffff;

    padding: rem(7) rem(10);

    &.active-link {
      background: $primary-green;
      border-radius: rem(8);
      /*
            background-image: linear-gradient(to right, #282828, $primary-green);
            animation: gradient 3s ease-in;
            background-size: 1000% 400%;
            */
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 40% 60%;
  }

  100% {
    background-position: 0% 100%;
  }
}

//HEADER
.dashboard-header {
  background-color: $primary-gray-dark;
  -webkit-box-shadow: 0 3px 3px 0 rgba($black, 0.5);
  box-shadow: 0 3px 3px 0 rgba($black, 0.5);
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  width: 100%;
  height: rem(64);
  .dashboard-header-top {
    padding: rem(20) rem(20);
  }
  .logo {
    background-image: url(../img/atoms-branding-logoapp-horiz.svg);
    background-repeat: no-repeat;
    background-size: rem(104) rem(16);
    width: rem(104);
    height: rem(16);
  }
  h4 {
    line-height: rem(16);
  }
}

$global-height: (calc(100% - (#{$header-height + $top-navigator})));
$dark-height: (calc(100% - #{$header-height}));
$not-tabs-height: (
  calc(100% - (#{$header-height} + #{$health-warning-height}))
);
$light-height: (
  calc(100% - (#{$header-height + $top-navigator} + #{$health-warning-height}))
);

// body {
//   &.warning-active {
//     .dashboard-page {
//       height: calc(100% - #{$header-height + $dashboard-navigator}) !important;
//     }
//   }
// }

.dashboard-page {
  display: flex;
  flex-direction: column;
  // height: calc(100% - #{$header-height + $dashboard-navigator});

  h1 {
    text-transform: uppercase;
    font-size: rem(40);
    line-height: rem(38);
    text-align: center;
    padding: 0;
    margin-bottom: rem(50);
    // Very Extra small devices (portrait phones, less than 375px)
    @include max-xxs {
      font-size: rem(42);
      line-height: rem(36);
    }
  }

  &.dashboard-dark {
    // height: $dark-height !important;
    .dashboard-page-content {
      margin: 0 rem(17) rem(46) rem(17);
      margin-top: auto;
      .dashboard-page-title {
        margin: 0 rem(4) rem(34) rem(4);
        h1 {
          font-style: normal;
          font-weight: 800;
          font-size: rem(40);
          line-height: rem(38);
          text-align: center;
          text-transform: uppercase;
          color: $white;
          text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  &.dashboard-light {
    min-height: $light-height !important;

    &.no-tabs {
      height: $not-tabs-height !important;

      video {
        height: $not-tabs-height !important;
      }
    }

    &:not(.no-tabs) {
      video {
        //height: $light-height !important;
      }
    }

    .dashboard-page-content {
      margin: 0 rem(17) rem(46) rem(17);
      > div:first-child > div:first-child {
        top: rem(30);
        filter: none !important;

        div {
          height: rem(3) !important;
          background-color: rgba($white, 0.3) !important;
          div {
            background-color: $white !important;
          }
        }
      }
      &.dashboard-video {
        > div {
          div {
            &:nth-child(2) {
              > div {
                height: 100%;
                //Video
                video {
                  height: 100vh !important;
                }
              }
            }
          }
        }
      }

      //Video
      video {
        object-fit: cover;
        width: 100vw !important;
        //height: 100vh !important;
        //position: fixed;
        //top: 0;
        bottom: 0;
        left: 0;
      }
      .page-bottom-cta {
        padding-left: $page-small-sides-padding-y;
        padding-right: $page-small-sides-padding-y;
      }
    }
  }
}

.dashboard-page-bg {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: black;
}

//Carousel
.dashboard-carousel {
  height: 100%;
  //display: flex;
  //flex-direction: column;
  .label-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $sliding-panel-dashboard-header;
  }

  .dashboard-carousel-epod-reconnect {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem(95);
    height: rem(25);
    padding: 0;
    margin-left: rem(5);

    border-radius: rem(13);
    font-size: rem(10);
    font-weight: 700;
    line-height: rem(12);
    letter-spacing: 1px;
  }

  .dashboard-carousel-icon-close {
    @include transition-common-link;
    background-color: rgba($black, 0.3);
    order: 2;
    width: rem(30);
    height: rem(30);
    border-radius: rem(15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12);
    margin-left: auto;
  }

  .swiper-container {
    height: calc(100% - #{$sliding-panel-dashboard-header});
  }

  .swiper-slide {
    opacity: 0 !important;
    height: auto;
    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  .swiper-pagination {
    $height: 2rem;

    display: flex;
    align-items: center;
    position: initial;
    height: $height;
    width: auto;
    padding: 0 1.25rem;

    border-radius: calc($height / 2);

    &-border {
      border: 3px solid rgba(255, 255, 255, 0.3);
    }

    .swiper-pagination-bullet {
      @include transition-common-link;
      width: rem(8);
      height: rem(8);
      background-color: rgba($white, 1);
      margin: 0 rem(8) 0 0;
      border-radius: rem(4);

      &:last-child {
        margin-right: 0;
      }

      &.swiper-pagination-bullet-active {
        width: rem(26);
      }

      &:only-child {
        display: none;
      }
    }
  }
}

.bat-slide-bottom.collapsed {
  .dashboard-carousel .label-title:after {
    transform: rotate(180deg);
  }
}

//PROGRESS CIRCLE
$dashboard-progress-circle-size: rem(46);

.progress-circle-dashboard {
  @include progress-circle(rem(18), $dashboard-progress-circle-size, 132, 2);
}

//Slide panel from bottom
.bat-slide-bottom {
  //When it'is closed
  &.collapsed {
    .dashboard-carousel-icon-close {
      transform: rotate(180deg);
    }
  }
}

.dashboard-carousel-epod {
  padding: 0 $page-small-sides-padding-y rem(20) $page-small-sides-padding-y;
  h2 {
    margin-bottom: rem(8);
    color: $white;
    font-size: rem(26);
    line-height: rem(26);
    font-weight: 300;
  }

  .dashboard-carousel-epod-status {
    background-color: rgba($black, 0.2);
    border-radius: rem(20);
    display: inline-flex;
    align-items: center;
    padding: rem(7) rem(12);
    margin-bottom: rem(5);
    font-size: rem(10);
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    letter-spacing: rem(1);
    span {
      width: rem(8);
      height: rem(8);
      border-radius: rem(4);
      display: inline-block;
      margin-right: rem(5);
    }
    &.connected {
      span {
        background-color: $green;
      }
    }
    &.disconnected {
      span {
        background-color: $red;
      }
    }
  }
  .dashboard-carousel-epod-values {
    width: 70%;
    padding: rem(10) 0 rem(30) 0;
    .dashboard-carousel-epod-values-item {
      margin-bottom: rem(5);
      .dashboard-carousel-epod-values-item-icon {
        //background-color: red;
        position: relative;
        width: $dashboard-progress-circle-size;
        height: $dashboard-progress-circle-size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid rgba($white, 0.25);
        &:after {
          @include absolute-center;
          content: "";
          background-color: rgba($black, 0.1);
          width: $dashboard-progress-circle-size - rem(1);
          height: $dashboard-progress-circle-size - rem(1);
          border-radius: 50%;
        }
      }
      [class*="bat-icon"] {
        font-size: rem(18);
        color: $white;
      }
      .dashboard-carousel-epod-values-item-label {
        text-transform: uppercase;
        color: $white;
        font-size: rem(16);
        font-weight: 700;
        letter-spacing: rem(0.8);
        margin-left: rem(15);

        overflow: hidden;
        text-overflow: ellipsis;

        span {
          display: block;
          font-weight: 700;
          font-size: rem(10);
          color: rgba($white, 0.25);
          letter-spacing: rem(1.8);
        }
      }
      //Unlock Switch
      .unlock-switch-outline {
        max-width: rem(115);
        height: rem(46);
        label {
          &:after,
          &:before {
            font-size: rem(20);
            left: rem(10);
          }
          &:before {
            left: auto;
            right: rem(10);
          }
        }
        input {
          ~ label i {
            height: rem(46);
            width: rem(46);
          }
          //Toggle
          &:checked ~ label i {
            left: auto;
            left: calc(100% - 43px);
          }
        }
      }
      //Disabled
      &.disabled {
        opacity: 0.1;
        pointer-events: none;
      }
    }
  }
  .dashboard-carousel-epod-settings {
    position: absolute;
    right: $page-small-sides-padding-y;
    bottom: rem(15);
    font-size: rem(12);
    letter-spacing: rem(1.2);
    font-weight: 700;
    color: rgba($white, 0.3);

    .update-available {
      font-style: normal;
      font-weight: 700;
      font-size: rem(10);
      line-height: rem(12);
      letter-spacing: 1px;
      text-transform: uppercase;

      color: $white;
      background: $primary-violet;
      border-radius: rem(58);
      padding: rem(6) rem(11);
    }

    [class*="bat-icon"] {
      font-size: rem(12);
      color: $white;
      margin-left: rem(5);
    }
  }

  &.dashboard-carousel-epod-add {
    h2 {
      color: $white;
      font-size: rem(60);
      line-height: rem(50);
    }
    .dashboard-carousel-epod-add-btn {
      position: absolute;
      z-index: 2;
      right: rem(18);
      bottom: rem(23);
      background-color: transparent;
      mix-blend-mode: normal;
      border: 3px solid rgba($white, 0.25);
      backdrop-filter: blur(rem(55));
      width: rem(62);
      height: rem(62);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: -0.0938rem;
        left: -0.0938rem;
        background-color: rgba($black, 0.35);
        width: rem(60);
        height: rem(60);
        border-radius: 50%;
      }
    }
  }
}

.connecting-overlay {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  background: rgba(29, 29, 27, 0.8);
  backdrop-filter: blur(5px);

  &-wrapper {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(25);
  }

  &-label {
    display: flex;
    flex-direction: column;
    gap: rem(7);

    color: $white;
    font-size: rem(14);
    font-weight: 600;
    line-height: rem(18);
    text-align: center;

    > span {
      font-weight: 300;
    }
  }
}

.dashboard-bell-badge {
  .MuiBadge-root {
    position: absolute;
    left: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1.4rem;

    [class*="bat-icon"] {
      font-size: 1.25rem;
      color: #fff;
    }

    .MuiBadge-dot {
      background-color: $plum;
    }
  }
}

@keyframes carousel-tip-fade-in {
  from {
    transform: translateY(1.875rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@mixin carousel-tip-animation {
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-name: carousel-tip-fade-in;
  animation-timing-function: ease-out;
}

.dashboard-carousel-cta {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .dashboard-carousel-tip {
    max-width: 11.5rem;
    margin: 0 auto 0.875rem;

    font-size: 0.75rem;
    font-weight: 700;
    line-height: 0.875rem;
    text-align: center;
    text-transform: uppercase;

    opacity: 0;
    @include carousel-tip-animation;
  }

  .btn {
    $height: 3rem;

    height: $height;
    width: $height;
    box-sizing: border-box;
    padding: 0;

    border-radius: calc($height / 2);

    font-size: 1rem;

    background-color: #1D1D1B54;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}

.iframe-only-shop {
  height: calc(100% - (#{$header-height + $dashboard-navigator} + #{$health-warning-height}));
}