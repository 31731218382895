.dashboard-navbar {
    position: fixed;
    bottom: 0;
    background: #1d1d1b;
    width: 100%;
    z-index: 9999;
    height: $dashboard-navigator;

    .dashboard-navbar-buttons {
        height: 100%;
        display: flex;
        align-items: center;
        //justify-content: space-around;
        padding-left: 2px;
        padding-right: 2px;

        .dashboard-navbar-button {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            //justify-content: center;
            padding-top: 8px;
            position: relative;
            height: 100%;
            margin-left: 2px;
            margin-right: 2px;

            .label {
                font-size: 9px;
                line-height: 11px;
                font-weight: 600;
                color: #aaaaaa;
                margin-top: 0.35rem;
                text-align: center;
                white-space: pre-line;
            }

            &.btn-center {
                width: 48px;
                margin-left: 6px;
                margin-right: 6px;
                padding-top: 16px;
            }

            img {
                width: 32px;

                &.device {
                    width: 48px;
                }
            }

            &.active {
                &:before {
                    content: '';
                    position: absolute;
                    height: 4px;
                    top: 0px;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    margin: 0;
                    border-radius: 4px;
                    background: linear-gradient(90deg, rgba(255,112,67,1) 0%, rgba(249,168,37,1) 33%, rgba(129,199,132,1) 66%, rgba(129,212,250,1) 100%);
                    width: 100%;
                    max-width: 48px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                  }

                .label {
                    color: #ffffff;
                }                
            }
        }
    }
}