.advanced-settings {
    @include page-wrapper-position;
    .list-group {
        border-radius: rem(8);
        margin: rem(10) 0 rem(30) 0;
        .list-group-item {
            background-color: $primary-gray-dark-extra;
            color: $primary-gray-light;
            border: 0;
            border-bottom: 1px solid $primary-gray-light;
            padding: rem(15) rem(20);
            font-weight: 600;
            font-size: rem(14);
            span {
                color: $white;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
.advanced-settings-wrapper {
    padding: rem(50) $page-small-sides-padding-y rem(20) $page-small-sides-padding-y;
}

.advanced-settings-warning {
    background-color: $primary-gray-dark-extra;
    border-radius: rem(8);
    margin: rem(18) 0;
    font-size: rem(12);
    line-height: rem(14);
    color: $white;
    span {
      display: block;
      padding: rem(15) rem(20);
    }
    .btn {
      &.btn-dark {
        border-width: 2px;
        border-color: $red;
      }
    }
  }