.page {
    background-color: $primary-gray-dark;
    height: 100%;
    display: flex;
    flex-direction: column;
}

//DIAGONAL BG
.page-linear-gradient-bg,
.page-linear-gradient-bg-top-left {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    top: 0;
    left: 0;
    background-image: url(../img/app-background-light.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
}

// .page-linear-gradient-bg-top-left {
//     background-image: url(../img/page-linear-gradient-bg-top-left.svg);
// }

.page-header {
    background-color: $primary-gray-dark;
    box-shadow: 0px 4px 6px rgba($black, 0.2);
    position: relative;
    z-index: 1052;
    header {
        min-height: $header-height;
    }
    
    //BUTTON ICON
    .page-header-icon {
        position: absolute;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: 0;
        width: rem(32);
        height: rem(32);
        left: rem(20);
        line-height: 1;
        [class*=bat-icon] {
            font-size: rem(20);
            color: $white;
        }
        //Positioning on right
        &.on-right {
            left: auto;
            right: rem(20);
            font-size: rem(16);

            .bat-icon-close {
                font-size: rem(14);
            }
        }
    }
    .page-header-logo {
        display: flex;
        width: rem(123.5);
        height: rem(19);
        img {
            height: rem(19);
        }
    }
    .page-header-label {
        font-size: rem(12);
        font-weight: 700;
        letter-spacing: rem(1.8);
        text-transform: uppercase;
    }
    .page-header-title {
        background-color: $primary-gray-dark-extra;
        min-height: $header-title-height;
        text-align: center;
        padding: rem(14);

        h1 {
            text-transform: uppercase;
            font-size: rem(26);
            font-weight: 800;
            line-height: rem(26);
            padding: 0;
            margin: 0;
            word-break: break-all; // fix for DE long texts
                // Very Extra small devices (portrait phones, less than 375px)
            @include max-xxs {
                font-size: rem(22);
                line-height: rem(22);
            }
        }
    

    }

    .page-header-title-onboarding {
        @extend .page-header-title;
    
        h1 {
            font-size: rem(19);
            line-height: rem(30);
    
            @include max-xxs {
                font-size: rem(16);
                line-height: rem(24);
            }
        }
    }
}

.page-label {
    text-transform: uppercase;
    font-size: rem(12);
    font-weight: 700;
    letter-spacing: 1px;
}


//PAGE BOTTOM CTA
.page-selector-cta {
    background-color: $primary-gray-soft;
    position: relative;
    z-index: 2;
    // min-height: rem(137);
    padding: rem(27) rem(27) rem(60) rem(27);
    box-shadow: 0px 0px 20px rgba($black, 0.25);

    &.sticky {
        position: sticky;
        bottom: 0;
        z-index: 1052;
    }

    &.transparent {
        @include reset-box-shadow;
        background-color: transparent;
    }

    &.with-url {
        padding-bottom: rem(27);
    }

    .page-selector-cta-url {
        text-align: center;
        margin-top: rem(23);
        font-size: rem(12);

        &.my-vuse {
            margin-top: rem(18);
        }
    }
}

.page-wrapper {
    @include page-wrapper-position;
}
.page-bottom-cta {
    @include page-wrapper-position;
    padding: 0 $page-sides-padding-y rem(20) $page-sides-padding-y;
    margin-top: auto;

    .btn {
        font-weight: 700;
    }
}

.page-bottom-cta-url {
    margin-top: rem(15);
    font-size: rem(12);
    text-align: center;
}

//Page Disclaimer
.page-disclaimer {
    margin-top: rem(50);
    .page-disclaimer-title {
        font-size: rem(24);
        line-height: rem(26);
    }
    .page-disclaimer-subtitle {
        margin-top: rem(25);
        font-size: rem(14);
        line-height: rem(18);
    }
}




.page-box-disclaimer {
    padding: rem(20) $page-sides-padding-y;
    margin-bottom: rem(20);
    font-size: rem(12);
    line-height: rem(14);
    &.bg {
        background-color: $primary-gray-dark-extra;
    }
}








// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

@include media-breakpoint-up(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
// Very Extra large devices (Extra large desktops, 1440px and up)
@include media-breakpoint-up(xxl) {
}
// 4K devices (4K, 2560px and up)
@include media-breakpoint-up(4k) {
}

//***************************************
// LESS THAN
//***************************************

// Very Extra small devices (portrait phones, less than 375px)
@include max-xxs {
}
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
}
// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
}
// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
}
// Extra Large devices (desktops, less than 1440px)
@include media-breakpoint-down(xl) {
}
// Extra Large devices (desktops, less than 2560px)
@include media-breakpoint-down(xxl) {
}

//***************************************
// ONLY FOR RANGE
//***************************************

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, From 576px to 767.98)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, From 768px to 991.98px)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, From 992px to 1199.98px)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, From 1200px to 1439.98px)
@include media-breakpoint-only(xl) {
}
// Very Extra large devices (Very Extra large desktops, From 1440px to 2559.98px)
@include media-breakpoint-only(xxl) {
}
// 4K large devices (4K desktops, 2560px and up)
@include media-breakpoint-only(4k) {
}
