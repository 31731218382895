@charset "UTF-8";

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraLightItalic.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "bat-icon";
  src: url("../fonts/bat-icon.eot?ylfj0s");
  src: url("../fonts/bat-icon.eot?ylfj0s#iefix") format("embedded-opentype"),
    url("../fonts/bat-icon.ttf?ylfj0s") format("truetype"),
    url("../fonts/bat-icon.woff?ylfj0s") format("woff"),
    url("../fonts/bat-icon.svg?ylfj0s#bat-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bat-icon-"],
[class*=" bat-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "bat-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bat-icon-pp:before {
  content: "\e946";
}
.bat-icon-tac:before {
  content: "\e947";
}
.bat-icon-contacts:before {
  content: "\e948";
}
.bat-icon-account-help:before {
  content: "\e949";
}
.bat-icon-preferences:before {
  content: "\e94a";
}
.bat-icon-loyalty:before {
  content: "\e94b";
}
.bat-icon-angle-left:before {
  content: "\e942";
}
.bat-icon-angle-right:before {
  content: "\e943";
}
.bat-icon-angle-up:before {
  content: "\e944";
}
.bat-icon-angle-down:before {
  content: "\e945";
}
.bat-icon-battery-h:before {
  content: "\e908";
}
.bat-icon-external-link:before {
  content: "\e94e";
}
.bat-icon-info:before {
  content: "\e967";
}
.bat-icon-mobile:before {
  content: "\e93f";
}
.bat-icon-reopen-app:before {
  content: "\e93c";
}
.bat-icon-toggle:before {
  content: "\e93d";
}
.bat-icon-next:before {
  content: "\e93e";
}
.bat-icon-star-filled:before {
  content: "\e93b";
}
.bat-icon-vuse-brand-plain:before {
  content: "\e93a";
}
.bat-icon-user:before {
  content: "\e939";
}
.bat-icon-search:before {
  content: "\e938";
}
.bat-icon-nicotine-18:before {
  content: "\e933";
}
.bat-icon-nicotine-12:before {
  content: "\e934";
}
.bat-icon-nicotine-06:before {
  content: "\e935";
}
.bat-icon-nicotine-03:before {
  content: "\e936";
}
.bat-icon-nicotine-zero:before {
  content: "\e937";
}
.bat-icon-star-outline:before {
  content: "\e932";
}
.bat-icon-filter:before {
  content: "\e931";
}
.bat-icon-accessories:before {
  content: "\e92d";
}
.bat-icon-device:before {
  content: "\e92e";
}
.bat-icon-subscriptions-notification:before {
  content: "\e92f";
}
.bat-icon-pod-notification:before {
  content: "\e930";
}
.bat-icon-lock-outline:before {
  content: "\e921";
}
.bat-icon-bluetooth-full-reg:before {
  content: "\e922";
}
.bat-icon-bluetooth-full:before {
  content: "\e923";
}
.bat-icon-alert-triangle:before {
  content: "\e924";
}
.bat-icon-alert-triangle-outline:before {
  content: "\e95f";
}
.bat-icon-trash:before {
  content: "\e925";
}
.bat-icon-settings:before {
  content: "\e950";
}
.bat-icon-offline:before {
  content: "\e926";
}
.bat-icon-logout:before {
  content: "\e928";
}
.bat-icon-info-circle:before {
  content: "\e929";
}
.bat-icon-upload:before {
  content: "\e92a";
}
.bat-icon-battery-90:before {
  content: "\e92b";
}
.bat-icon-battery-30:before {
  content: "\e92c";
}
.bat-icon-hamburger:before {
  content: "\e901";
}
.bat-icon-lock-outline-check:before {
  content: "\e902";
}
.bat-icon-unlock-outline:before {
  content: "\e903";
}
.bat-icon-find-my-vape:before {
  content: "\e904";
}
.bat-icon-plus:before {
  content: "\e905";
}
.bat-icon-puff:before {
  content: "\e94f";
}
.bat-icon-battery-10:before {
  content: "\e907";
}
.bat-icon-battery:before {
  content: "\e908";
}
.bat-icon-battery-in-charge:before {
  content: "\e909";
}
.bat-icon-location-mark:before {
  content: "\e94d";
}
.bat-icon-bluetooth:before {
  content: "\e90b";
}
.bat-icon-cart:before {
  content: "\e90c";
}
.bat-icon-vuse-logo:before {
  content: "\e90d";
}
.bat-icon-vuse-brand:before {
  content: "\e90e";
}
.bat-icon-bell:before {
  content: "\e955";
}
.bat-icon-download:before {
  content: "\e910";
}
.bat-icon-unlock:before {
  content: "\e915";
}
.bat-icon-phone:before {
  content: "\e912";
}
.bat-icon-chevron-left:before {
  content: "\e957";
}
.bat-icon-chevron-right:before {
  content: "\e958";
}
.bat-icon-chevron-up:before {
  content: "\e959";
}
.bat-icon-chevron-down:before {
  content: "\e956";
}
.bat-icon-lock:before {
  content: "\e914";
}
.bat-icon-alert-circle:before {
  content: "\e918";
}
.bat-icon-eye-on:before {
  content: "\e919";
}
.bat-icon-eye-off:before {
  content: "\e91a";
}
.bat-icon-battery-full:before {
  content: "\e91b";
}
.bat-icon-back:before {
  content: "\e91c";
}
.bat-icon-map:before {
  content: "\e91d";
}
.bat-icon-usage:before {
  content: "\e953";
}
.bat-icon-close:before {
  content: "\e95a";
}
.bat-icon-check:before {
  content: "\e920";
}
.bat-icon-brightness-high:before {
  content: "\e969";
}
.bat-icon-brightness-low:before {
  content: "\e96a";
}
.bat-icon-haptic-high:before {
  content: "\e96b";
}
.bat-icon-haptic-medium:before {
  content: "\e96c";
}
.bat-icon-haptic-low:before {
  content: "\e96d";
}
.bat-icon-volume-high:before {
  content: "\e96e";
}
.bat-icon-volume-low:before {
  content: "\e96f";
}
