/* Required styles */
.swipe-to-delete {
  position: relative !important;
  padding: 0 !important;
  overflow: hidden !important;

  .js-delete {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 1 !important;
  }
  .js-content {
    position: relative !important;
    z-index: 2 !important;

    user-select: none !important;
  }
  .js-content :first-child {
    position: relative !important;
  }

  .js-transition-delete-right, .js-transition-delete-left, .js-transition-cancel, .js-transition-cancel-fast {
    transition-property: left !important;
    transition-duration: .7s;
    transition-timing-function: ease-in-out;
  }
  .js-transition-delete-right {
    left: 100% !important;
  }
  .js-transition-delete-left {
    left: -100% !important;
  }
  .js-transition-cancel {
    left: 0 !important;
  }
  .js-transition-cancel-fast {
    left: 0 !important;
    transition-duration: .01s;
  }
}