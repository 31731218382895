.loyalty {
  .page-header {
    box-shadow: none;
  }
}

.loyalty-optin {
  &-content {
    margin: 0 rem(50);
  }

  &-text {
    margin-top: rem(30);

    font-size: rem(14);
    line-height: rem(16.8);
    text-align: center;
  }

  .loyalty-logo {
    height: rem(55);
  }
}

.loyalty-dashboard {
  .loyalty-logo {
    height: rem(30);
  }

  .loyalty-summary {
    margin-bottom: rem(60);
    padding: rem(25) rem(40);

    background-color: $primary-gray-dark;
    background-image: linear-gradient(
      180deg,
      rgba(109, 109, 109, 0.4) 0%,
      rgba(0, 95, 125, 0) 100%
    );
    box-shadow: 0 rem(10) rem(10) rgba(0, 0, 0, 0.25);
  }

  .loyalty-stats {
    text-align: center;

    &-title {
      margin-bottom: rem(10);
      color: rgba(255, 255, 255, 0.5);
      font-size: rem(12);
      font-weight: 700;
      letter-spacing: rem(1.2);
      line-height: rem(14);
      text-transform: uppercase;
    }

    &-value {
      font-size: rem(36);
      font-weight: 800;
      line-height: rem(26);
    }

    &.fs-small .loyalty-stats-value {
      font-size: rem(26);
      font-weight: 700;
    }
  }

  .loyalty-tier-indicator {
    height: rem(265);
    width: rem(265);
    margin: rem(35) auto;
    text-align: center;

    &-icon {
      height: rem(50);
      margin-top: -#{rem(20)};
      margin-bottom: rem(10);
    }

    &-title {
      font-size: rem(26);
      font-weight: 800;
      line-height: rem(26);
      text-transform: uppercase;
    }

    &-subtitle {
      margin-top: rem(8);
      padding: 0 rem(68);
      color: $primary-gray-medium;
      font-size: rem(12);
      line-height: rem(16);
    }

    .CircularProgressbar-trail {
      stroke: rgba(0, 0, 0, 0.4);
    }

    .CircularProgressbar-path {
      stroke: #00c7b1;
    }
  }

  .loyalty-badges {
    &-title {
      margin-bottom: rem(30);

      font-weight: 800;
      font-size: rem(26);
      line-height: rem(26);
      text-align: center;
    }

    &-group {
      margin: 0 rem(25) rem(70);

      &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(35);
        padding: rem(15);
        box-sizing: border-box;
        background-color: $primary-gray-dark-extra;
        box-shadow: inset 0 rem(4) rem(4) rgba(0, 0, 0, 0.25);
        border-radius: rem(10);
      }

      &-icon {
        margin-bottom: rem(8);
      }

      &-count,
      &-name {
        font-size: rem(12);
        font-weight: 700;
        letter-spacing: rem(1.2);
        line-height: rem(14);
      }

      &-name {
        color: $primary-gray-light;
        text-transform: uppercase;
      }
    }

    &-icons {
      display: grid;
      grid-template-columns: repeat(auto-fill, rem(105));
      justify-content: space-between;
      gap: rem(12);
    }
  }

  .loyalty-badge {
    text-align: center;

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: rem(24);
      margin-top: rem(8);
      font-size: rem(10);
      font-weight: 700;
      line-height: rem(12);
      letter-spacing: rem(1.2);
      text-transform: uppercase;
    }

    &-subtitle {
      color: $primary-gray-light;
    }

    img {
      width: 100%;
    }
  }

  &.ca {
    .loyalty-badges-icons {
      grid-template-columns: repeat(auto-fill, rem(93.55));
      row-gap: rem(30);
    }
  }
}
