.pod-tracker{

    display: block;
    overflow: auto;
    padding-bottom: rem(97);

    &-tabs {
        margin-top: rem(50);
    }

    .page-linear-gradient-bg{z-index: -2;}

    &-content-box{
        background-color: #1D1D1B;
        position: relative;
        z-index: 1;
        padding-top: rem(19);
        padding-bottom: rem(15);
        margin-bottom: rem(72);
        box-sizing: border-box;
    }

    &-content-box-empty{
        @extend .pod-tracker-content-box;
        padding-top: rem(23) !important;
    }

    &-graph{
        width: rem(276);
        height: rem(276);
    }

    &-graph-empty{
        width: rem(268);
        height: rem(268);
    }

    &-usage-count{

        .pods-used-count{
            top: rem(119);
            width: rem(68.44);
            font-size: rem(48.81);
            font-weight: 800;
            line-height: rem(48.81);
            text-align: center;
            color: #FFF;
        }

        .pods-used-label{
            top: rem(173);
            width: rem(94);
            font-size: rem(10);
            font-weight: 700;
            line-height: rem(12);
            letter-spacing: rem(1);
            text-align: center;
            color: #AAAAAA;
        }

        .pods-used-count, .pods-used-label{
            position: absolute;
            left: 50%; 
            transform: translateX(-50%);
        }

    }

    &-most-used-label{

        margin-top: rem(10);
        font-size: rem(10);
        font-weight: 700;
        line-height: rem(14);
        letter-spacing: rem(1.2);
        color: #aaa;
        text-align: center;
        text-transform: uppercase;

        > span{ color: #fff; }

    }

    &-avg-usage-time{

        width: 100%;
        background-color: #2A2A2A;
        box-sizing: border-box;
        padding: rem(10) rem(12) rem(12);
        border-radius: rem(8);
        margin-top: rem(25);

        .avg-usage-label{
            height: rem(19);
            font-size: rem(10);
            font-weight: 700;
            line-height: rem(12);
            letter-spacing: rem(1);
            text-align: left;
            color: #aaa;
        }

        .avg-usage-value{
            margin-top: rem(20);
            font-size: rem(40);
            font-weight: 800;
            line-height: rem(38);
            letter-spacing: rem(-0.5);
            text-align: left;
            color: #fff;

            > span{
                font-size: rem(18);
                line-height: rem(18);
            }
        }

    }

    &-view-more{
        font: inherit;
        outline: inherit;
        background: none;
	    color: inherit;
        padding: rem(10) rem(15);
        border-radius: rem(58);
        border: 2px solid #AAAAAA;
        color: #FFF;
        font-size: rem(10);
        font-weight: 700;
        line-height: rem(12.19);
        letter-spacing: rem(1);
        text-align: center;
    }

    &-title{
        font-size: rem(12);
        font-weight: 700;
        line-height: rem(14);
        letter-spacing: rem(1);
        text-align: left;
        color: #fff;
        margin-top: rem(40);
        margin-bottom: rem(13);
    }

    &-usage-breakdown{

        width: 100%;

        .breakdown-label{
            margin: rem(40) o rem(13);
        }

        .flavour-group-box{
            width: 100%;
            /*height*/
            background-color: #2A2A2A;
            border-radius: rem(8);
            padding-bottom: rem(17);
            box-sizing: border-box;
            margin-bottom: rem(7);

            .box-header{
                width: 100%;
                height: rem(5);
                border-radius: rem(8) rem(8) 0 0;
            }

            .box-content{
                display: flex;
            
                .box-flavour-group-icon{

                    width: rem(40);
                    height: rem(40);
                    border-radius: rem(48);
                    background-color: #1D1D1B;
                    margin: rem(18) rem(8) 0 rem(14);
                    padding: rem(5.71);
                    box-sizing: border-box;

                    img{
                        width: 100%;
                    }

                }

                .box-flavour-group-info{

                    margin-top: rem(23.5);

                    &-name{
                        font-size: rem(14);
                        font-weight: 700;
                        line-height: rem(12);
                        text-align: left;
                        color: #fff;
                    }

                    &-usage{
                        margin-top: rem(5);
                        font-size: rem(10);
                        font-weight: 700;
                        line-height: rem(12);
                        letter-spacing: rem(1);
                        text-align: left;
                        color: #AAAAAA;
                    }
        
                    &-most-used-badge{
                        margin: rem(9) 0 0 rem(-4);
                        background-color: #FFF;
                        padding: rem(5) rem(7.5);
                        box-sizing: border-box;
                        border-radius: rem(66);
                        color: #1D1D1B;
                        font-size: rem(10);
                        font-weight: 700;
                        line-height: rem(9.78);
                        letter-spacing: rem(1.22);
                        text-align: center;
                        width: fit-content;
                        box-shadow: 0px 4.89px 19.55px 0px #00000014,
                                    0px 4.89px 19.55px 0px #00000014,
                                    0px 4px 4px 0px #00000040,
                                    0px 1px 7px 0px #00000040;
                    }

                }

                .box-flavour-group-arrow{
                    height: rem(6);
                    rotate: -90deg;
                    margin: rem(37) rem(17) 0 auto;
                }

            }

        }

        .view-more-breakdown{
            margin-top: rem(8);
        }

    }

    &-history{

        width: 100%;
        padding: 0 rem(32) rem(22);
        box-sizing: border-box;
        margin-bottom: rem(14);

        .hisotry-label{
            margin-bottom: rem(16);
        }

        .month-subtitle{
            font-size: rem(12);
            font-weight: 700;
            line-height: rem(14);
            letter-spacing: rem(1);
            text-align: left;
            color: #AAAAAA;
            margin-bottom: rem(17);
            text-transform: uppercase;
        }

        .flavour-box{
            width: 100%;
            margin-bottom: rem(10);
            border-radius: rem(8);
            background-color: #1D1D1B;
            display: flex;
            flex-direction: row;

            .box-bar{
                width: rem(6);
                height: auto;
                height: rem(114);
                border-radius: rem(8) 0 0 rem(8);
            }

            .box-content{

                margin: rem(9) rem(25) rem(17);
                height: auto;

                .box-flavour-header{

                    display:flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: rem(11);

                    &-icon{
                        width: rem(29);
                        height: rem(29);
                        background-color: #2A2A2A;
                        margin-right: rem(9);
                        border-radius: rem(30.93);
                        padding: rem(3.7);
                        box-sizing: border-box;

                        img{
                            width: 100%;
                        }

                    }

                    &-name{
                        font-size: rem(14);
                        font-weight: 800;
                        line-height: rem(18);
                        text-align: left;
                        text-transform: uppercase;
                    }

                }

                .box-flavour-info{

                    display:flex;
                    flex-direction: row;
                    align-items: center;

                    &-labels{

                        margin-right: rem(17);

                        > div{ color: #AAAAAA; }

                    }

                    &-values{

                        > div{
                            color: #FFFFFF;
                            text-transform: uppercase;
                        }
                        
                    }

                    &-labels > div, &-values > div{
                        font-size: rem(10);
                        font-weight: 700;
                        line-height: rem(12);
                        letter-spacing: rem(1);
                        text-align: left;
                        margin-bottom: rem(7);                    
                    }

                }

            }

        }

        .view-more-history{
            margin: rem(4) auto 0;
            display: block;
        }

    }

    .pod-tracker-missing-wrapper {
        margin: rem(66) rem(66) 0;

        .pod-tracker-missing-title {
            text-align: center;
            font-size: rem(26);
            font-style: normal;
            font-weight: 800;
            line-height: rem(26);
            letter-spacing: rem(-0.5);
            text-transform: uppercase;
        }
        .pod-tracker-missing-description {
            margin-top: rem(28);
            text-align: center;
            font-size: rem(14);
            font-style: normal;
            font-weight: 400;
            line-height: rem(18);
        }

        .pod-tracker-missing-image {
            display: flex;
            justify-content: center;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            padding-left: rem(66);
            padding-right: rem(66);

            img {
                max-height: 50vh;
                width: auto;
            }
        }
    }
}