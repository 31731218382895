.more-menu {
  height: auto;

  .feature-widget-group {
    gap: rem(8);
    margin-top: rem(16);
    margin-bottom: rem(16);

    &-title {
      margin-bottom: 0;
    }
  }

  .feature-widget-icon {
    font-size: rem(32);
  }

  .app-version {
    color: white;
    font-size: rem(10);
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
