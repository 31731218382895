body {
    &.onboarding,
    &.onboarding-benefits {
        .page-header {
            background-color: transparent;
            @include reset-box-shadow;
        }
    }   
}

.onboarding-page {
    position: relative;
    z-index: 2;
}
//PAGE BACKGROUND
.onboarding-page-bg,
.onboarding-page-benefits-bg {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $primary-gray-dark;
}
.onboarding-page-stories {
    margin-top: - $header-height;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: rem(260);
        z-index: 1;
        background: linear-gradient(0deg, rgba($primary-gray-dark-extra, 0.0001) 0%, $primary-gray-dark-extra 100%);

    }
    .onboarding-page-stories-video {
        //height: rem(460);
        height: 67vh;
        position: relative;
        > div {
            height: 100% !important;
            > div {
                &:first-child {
                    top: rem(60);
                    filter: none !important;
                    div {
                        height: rem(3) !important;
                        background-color: rgba($white, 0.3) !important;
                        div {
                            background-color: $white !important;
                        }
                    }
                }
            }
        }
        //Video
        video {
            object-fit: cover;
            width: 100vw !important;
            height: 100vh !important;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}


.onboarding-welcome {
    background: linear-gradient(0deg, $primary-gray-dark 0%, rgba($primary-gray-dark, 0) 100%);
    padding: rem(145) $page-sides-padding-y rem(21) $page-sides-padding-y;
    @media (orientation: landscape) {
        padding: rem(21) rem(25);
    }
    h1 {
        text-transform: uppercase;
        font-size: rem(40);
        font-weight: 800;
        line-height: rem(38);
        text-align: center;
        padding: 0;
        margin-bottom: rem(50);
        // Very Extra small devices (portrait phones, less than 375px)
        @include max-xxs {
            font-size: rem(42);
            line-height: rem(36);
        }
    }
    &.onboarding-welcome-benefits {
        background: #2A2A2A;
        padding: rem(30) $page-sides-padding-y rem(21) $page-sides-padding-y;
        text-align: center;
        h1 {
            margin-bottom: rem(10);
        }
        > span {
            font-size: rem(12);
            line-height: rem(14);
            color: $white;
        }
    }
}

.onboarding-welcome-title{
    padding: rem(10) $page-sides-padding-y;
    h1 {
        font-weight: 800;
        font-size: rem(40);
        line-height: rem(38);
        text-transform: uppercase;
        color: $white;
        margin-bottom: 0;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }
}

.onboarding-welcome-label {
    font-size: rem(14);
}
.onboarding-welcome-tools {
    margin: rem(25) 0 rem(30) 0;
    display: flex;
    overflow: auto;
    .onboarding-welcome-tools-item {
        border: 0;
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: rem(61);
        margin-right: rem(22);
        font-size: rem(12);
        font-weight: 500;
        text-align: center;
        color: $white;
        .onboarding-welcome-tools-item-icon {
            background-color: $primary-gray-dark-extra;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: rem(61);
            height: rem(61);
            margin-bottom: rem(6);

            position: relative;
            z-index: 1;
            &:after {
                content: "";
                background-image: url(../img/onboarding-welcome-tools-item-icon.webp);
                background-repeat: no-repeat;
                background-size: rem(61) rem(61);
                border-radius: 50%;
                width: rem(61);
                height: rem(61);
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;        
            }
            &.cloud-control {
                background: linear-gradient(180deg, #8F2EEE 0%, #750BDD 100%);
            }
            &.find-my-vape {
                background: linear-gradient(180deg, #197066 0%, #2E615C 100%);
            }
            &.battery-status {
                background: linear-gradient(180deg, #DD485A 0%, #D70436 100%);
            }
            //Icons
            .custom-icon {
                width: rem(26);
                height: rem(26);
                background-size: rem(26);
            }
            [class*=bat-icon] {
               font-size: rem(20);
                line-height: 0;
            }                
        }
    }
}
.onboarding-copyright {
    text-align: center;
    font-size: rem(12);
    font-weight: 400;
    margin-top: rem(15);
}

.onboarding-benefits {
    .page-selector-cta {
        > p {
            display: block;
            margin-bottom: rem(15);

            font-size: rem(12);
            line-height: rem(14);
            text-align: center;
        }
    }
}


// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

@include media-breakpoint-up(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
// Very Extra large devices (Extra large desktops, 1440px and up)
@include media-breakpoint-up(xxl) {
}
// 4K devices (4K, 2560px and up)
@include media-breakpoint-up(4k) {
}

//***************************************
// LESS THAN
//***************************************

// Very Extra small devices (portrait phones, less than 375px)
@include max-xxs {
}
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
}
// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
}
// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
}
// Extra Large devices (desktops, less than 1440px)
@include media-breakpoint-down(xl) {
}
// Extra Large devices (desktops, less than 2560px)
@include media-breakpoint-down(xxl) {
}

//***************************************
// ONLY FOR RANGE
//***************************************

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, From 576px to 767.98)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, From 768px to 991.98px)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, From 992px to 1199.98px)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, From 1200px to 1439.98px)
@include media-breakpoint-only(xl) {
}
// Very Extra large devices (Very Extra large desktops, From 1440px to 2559.98px)
@include media-breakpoint-only(xxl) {
}
// 4K large devices (4K desktops, 2560px and up)
@include media-breakpoint-only(4k) {
}
