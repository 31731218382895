$shop-category-item-height: rem(108);
$shop-reorder-summary-height: rem(158);

body {
    &.pdp-open {
        overflow: hidden;
    }
}

.MuiTabs-root {

    background-color: $primary-gray-dark-extra;

    .MuiButtonBase-root{
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 14px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        mix-blend-mode: normal;
    }
}

.shop-home {
    background-color: $primary-gray-light-extra;
    .label-title {
        height: auto;
        color: $primary-gray-dark-extra;
    }
}

.shop-reorder,
.shop-category {
    background-size: cover;
    background-position: bottom center;
    position: relative;
    padding-left: $page-small-sides-padding-y;
    padding-right: $page-small-sides-padding-y;
    padding-bottom: calc($shop-category-item-height / 2);
    margin-bottom: calc($shop-category-item-height / 2) + rem(30);
    &:before {
        content: "";
        position: absolute;
        left: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba($primary-gray-dark-extra, 0) 0%, $primary-gray-dark-extra 100%);
        height: 100%;
        width: 100%;
        opacity: 0.7;
    }
    .row {
        margin-left: - rem(4);
        margin-right: - rem(4);
        > * {
            padding-right: rem(4);
            padding-left: rem(4);
        }            
    }
    .shop-category-item {
        position: relative;
        z-index: 2;
        bottom: - $shop-category-item-height;
        background-color: $white;
        border: none;
        border-radius: rem(8);
        width: 100%;
        padding: rem(20) rem(10);
        height: $shop-category-item-height;

        &.selected {
            border: 3px solid $primary-green;
        }

        em {
            @include absolute-center-horizontal;
            bottom: rem(10);
            font-style: normal;
            text-transform: uppercase;
            color: $primary-gray-dark-extra;
            font-size: rem(13);    
        }

        [class*=bat-icon] {
            display: block;
            color: $primary-gray-soft;
            font-size: rem(30);
            margin-bottom: rem(10);
            &.bat-icon-device {
                font-size: rem(46);
            }
        }
    

    }
}

.shop-reorder {
    padding-bottom: calc($shop-reorder-summary-height / 2);
    margin-bottom: calc($shop-reorder-summary-height / 2) + rem(30);
    max-height: rem(162);
}

.shop-filter {
    margin-top: rem(20);
    padding: 0 $page-small-sides-padding-y;
    .label-title {
        font-size: rem(10);
        padding: 0;
        margin-bottom: rem(7);
    }
    .shop-filter-all,
    .shop-filter-icon {
        background-color: $white;
        border: 2px solid #D8D8D8;
        border-radius: rem(8);
        height: rem(46);
        padding: 0 rem(18);
        color: $primary-gray-dark-extra;
        font-size: rem(12);
        font-weight: 700;
        [class*=bat-icon] {
            font-size: rem(14);
        }

    }
    .shop-filter-icon {
        width: rem(46);
        padding: 0;
        margin-left: rem(8);

        [class*=bat-icon] {
            font-size: rem(20);
        }
        
    }

}
.shop-product-list {
    padding: rem(20) $page-small-sides-padding-y 0 $page-small-sides-padding-y;
    text-align: center;
    color: $primary-gray-dark-extra;

    .card {
        margin-bottom: rem(15);
        border-radius: rem(8);
        border: none;

        img {
            max-height: rem(200);
            object-fit: contain;
        }

        .card-body {
            min-height: rem(81);
            align-items: center;

            .card-title {
                margin-bottom: 0;
            }
        }
    }
}
.shop-product {
    background-color: $white;
    border-radius: rem(4);
    color: $primary-gray-dark-extra;
    margin-bottom: rem(10);

    $shop-product-img-width: rem(93);
    .shop-product-img {
        background-color: $white;
        position: relative;
        border-top-left-radius: rem(4);
        border-bottom-left-radius: rem(4);
        overflow: hidden;
        width: $shop-product-img-width;
        min-height: rem(105);
        img {
            @include absolute-center;            
            object-fit: cover;
            width: $shop-product-img-width;
        }
    }
    .shop-product-description {
        width: calc(100% - #{$shop-product-img-width});
        padding: rem(15) rem(20);
        justify-content: space-between;

        .shop-product-title {
            font-size: rem(12);
            line-height: rem(14);
            color: $primary-gray-soft;
            font-weight: 500;
            margin-bottom: rem(5);
            span {
                background: $white;
                border: 1px solid $primary-gray-border;
                border-radius: rem(4);
                font-size: rem(10);
                line-height: 1;
                letter-spacing: rem(1);
                font-weight: 700;
                padding: rem(4) rem(12.5);
            }
        }
        .shop-product-measure-unit-nicotine {
            font-style: normal;
            font-weight: 700;
            font-size: rem(10);
            line-height: rem(12);
            letter-spacing: rem(1);
            text-transform: uppercase;
            color: #AAAAAA;
            margin-top: auto;
            margin-bottom: rem(3);
        }
        .shop-product-details {
            font-weight: 700;
            font-size: rem(12);
            letter-spacing: rem(1.2);
            color: $primary-gray-border;
            span {
                font-style: normal;
                text-transform: uppercase;
                color: $primary-gray-dark-extra;
                font-size: rem(20);
                letter-spacing: 0;
                &.favourite-nicotine {
                    border: 2px solid rgba(0, 0, 0, 0.1);
                    border-radius: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    font-size: rem(9) !important;
                    padding: 0.08rem 0.3rem;
                    margin-right: 0.2rem;
                    text-transform: none;
                }
            }
        }
    }
}

.shop-pdp-page {
}

.shop-pdp-wrapper {
    overflow: scroll;
}

.shop-pdp {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: $zindex-modal + 1;
    background-color: $white;
    color: $primary-gray-soft;
    padding-top: calc(20vh + #{$header-height}); //If HEADER is Fixed
    //padding-bottom: rem(91); //As .shop-pdp-actions height

    .label-title {
        padding-left: $page-sides-padding-y;
        padding-right: $page-sides-padding-y;
        font-size: rem(10);
        color: $primary-gray-soft;
    }

    .page-header {
        @include transition-common-link;
        left: 0;
    }
}

.shop-pdp-backdrop {
    @include transition-common-link;
    background-color: rgba($black, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: $zindex-modal;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    //OPEN FROM RIGHT
    .pdp-open & {
        visibility: visible;
        opacity: 0.8;
    }
}


.shop-pdp-image {
    margin-bottom: rem(30);
    position: relative;

    
    .new {
        position: absolute;
        z-index: 2;
        top: rem(20);
        left: $page-sides-padding-y;
        background-color: $white;
        border-radius: rem(8);
        padding: rem(9) rem(20);
        font-size: rem(12);
        line-height: rem(12);
        font-weight: 700;
    }
    img {
        position: relative;
        z-index: 1;
        width: 100%;
        max-height: rem(200);
        object-fit: contain;
    }
}
.shop-pdp-title {
    padding-left: $page-sides-padding-y;
    padding-right: $page-sides-padding-y;
    font-size: rem(30);
    color: $primary-gray-dark-extra;
    margin-bottom: rem(30);
}
.shop-pdp-description {
    padding-left: $page-sides-padding-y;
    padding-right: $page-sides-padding-y;
    font-size: rem(14);
    line-height: rem(18);
    margin-bottom: rem(30);
    width: 100vW;
}

.shop-pdp-list-item-nic {
    border: 2px solid rgba($black, 0.1);
    border-radius: rem(20);
    padding: rem(2) rem(16);
    font-size: rem(12);
    margin-left: $page-sides-padding-y;
}

.shop-pdp-list-item-qty {
    .shop-pdp-list-item-qty-min,
    .shop-pdp-list-item-qty-add {
        background-color: $primary-green;
        padding: 0;
        width: rem(30);
        height: rem(30);
        border-radius: rem(4);
        text-align: center;
        line-height: rem(24);
        font-size: rem(24);
        color: $white;
        border: none;

        &:disabled {
            background-color: $primary-gray-border;
        } 
    } 

    .shop-pdp-list-item-qty-num {
        width: rem(38);
        margin: 0 rem(5);
        font-style: normal;
        text-transform: uppercase;
        color: $primary-gray-dark-extra;
        font-size: rem(20);
        text-align: center;
    }
}

.shop-pdp-list {
    .shop-pdp-list-item {
        border-top: 1px solid $primary-gray-border;
        padding: rem(27) $page-sides-padding-y;

        .bat-icon-star-filled,
        .bat-icon-star-outline {
            font-size: rem(25);
            color: $primary;
        }

        .shop-pdp-list-item-nic-icon {
            font-size: rem(14);
            margin-left: rem(30);

            [class*=bat-icon] {
                font-size: rem(48);
                line-height: 0;
                height: rem(10);
                margin-right: rem(8);
            }
        }

        //&:last-child {
            //border-bottom: 1px solid $primary-gray-border;
        //}
    }
}

.shop-pdp-actions {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: $white;
    box-shadow: 0px 0px 40px rgba($black, 0.1);
    border-top: 1px solid $primary-gray-border;
    padding: rem(20) $page-sides-padding-y;

    .MuiBadge-root {
        font-size: rem(20);

        .MuiBadge-badge {
            color: $primary-gray-soft;
            height: rem(16);
            width: rem(16);
            min-width: rem(16);
            background: $primary-green;
        }
    }

    .btn {
        margin-left: rem(10);
        //Disabled
        &.disabled,
        &:disabled {
            background-color: $primary-gray-border;
            border-color: $primary-gray-border;
            color: $primary-gray-medium;
        }
    }
}

.price {
    font-weight: 800;
    font-size: rem(30);
    line-height: rem(18);
    color: $primary-gray-soft;
}

.MuiDrawer-root {
    .MuiPaper-root {
        border-radius: 8px;
        padding-bottom: rem(30);
        text-transform: uppercase;

        .drawer-header {
            padding: rem(18) rem(25);
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $primary-gray-border;
        }

        .filter-category-el {
            
            padding: rem(18) rem(25);
            font-weight: bold;
            font-size: 14px;
            line-height: 15px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $primary-gray-soft;

            border-top: 1px solid $primary-gray-border;

            &:last-child {
                border-bottom: 1px solid $primary-gray-border;
            }

            &.filter-selected {
                background: $primary-gray-light-extra;
            }
        }
    }
}

.filter-panel-overlay {
    z-index: 1053 !important;
}

.filter-panel {

    height: 100vh !important;
    margin-top: 0 !important;

    .sliding-panel-header {
        flex-direction: row-reverse;
        background: #1D1D1B !important;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);

        .sliding-panel-icon-close {
            margin-right: rem(18) !important;
        }

        .sliding-panel-title-wrapper {
            margin-left: 4rem !important;

            .sliding-panel-title {
                font-weight: 800;
                font-size: rem(26);
                line-height: rem(30);
                text-align: center;
                text-transform: uppercase;
                color: $white;
            }
        }
       
    }

    .sliding-panel-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .filters-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;

        .MuiAccordion-root {
            box-shadow: none;
            margin-top: 0;
            margin-bottom: 0;

            .MuiAccordionSummary-root {
                border-bottom: 1px solid $primary-gray-border;

                .MuiAccordionSummary-content {
                    font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #1D1D1B;
                    mix-blend-mode: normal;
                }
            }
        }

        .filters-cta {
            margin-top: auto;
            padding: rem(30) rem(17) rem(20) rem(17);
            border-top: 1px solid $primary-gray-border;
            
            .btn {
                width: 100%;
            }
        }
    }
}

.shop-basket-panel-wrapper {
    background-repeat: no-repeat;
    background-size: cover;


    width: 100%;
    position: fixed;
    z-index: 1053;
    bottom: 0;

    display: flex;
    flex-direction: column;
    transition: transform 0.5s;
    will-change: transform;

    width: 100%;
    height: 100vh;

    transform: translateY(0%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);    


    background: $primary-gray-soft;

    &.collapsed {
        transform: translateY(calc(100% - 4.375rem));
        border-top-left-radius: rem(16);
        border-top-right-radius: rem(16);

        .basket-panel-icon-close {
            transform: rotate(180deg);
        }
    }

    .shop-basket-panel {
        display: flex;
        flex-direction: column;
        height: 100%;

        .shop-basket-panel-overlay {
            height: rem(57);
            max-height: rem(57);
            padding: 0 1.125rem;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: rgba(255, 255, 255, 0.3);
            font-size: 0.75rem;
            letter-spacing: 0.0625rem;
            text-transform: uppercase;


            .basket-panel-icon-close {
                @include transition-common-link;
                background-color: rgba($black, 0.3);
                order: 2;
                width: rem(30);
                height: rem(30);
                border-radius: rem(15);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: rem(12);

                [class*=bat-icon] {
                    color: $white;
                }
            }
        }

        .shop-basket-products-list {
            max-height: calc(100vh - 16rem);
            overflow: scroll;
            background-color: $primary-gray-light-extra;
            height: 100%;

            
        } 

        .shop-basket-panel-overlay {
            padding-top: 0.5rem;
            margin-bottom: 1rem;
        }

    }


    .shop-basket-overlay {
        .MuiBadge-badge {
            background: $primary-green;
            color: $primary-gray-soft;
            font-weight: bold;
            font-size: rem(10);
            line-height: rem(12);
            height: rem(15);
            width: rem(15);
            min-width: rem(15);
        }

        .shop-basket-total {
            font-style: normal;
            font-weight: 800;
            font-size: 30px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: $white; 
            padding-left: rem(20);
        }

        [class*=bat-icon] {
            font-size: rem(22);
            color: $white;
        }
    }

    .shop-basket-overlay {
        display: flex;
    }

    .label-title {
        //height: rem(104);
    }

    .shop-basket-cta-wrapper {
        margin-top: auto;
        padding: rem(24) rem(20) rem(20) rem(17);

        .shop-basket-total {
            display: flex;
            justify-content: space-between;
            font-style: normal;
            font-weight: 900;
            font-size: rem(30);
            line-height: rem(18);
            display: flex;
            align-items: center;
            text-transform: uppercase;
            margin-bottom: rem(12);

            .total-label {
                color: #6B6B6B;
            }

        }

        .delivery-label {
            font-style: normal;
            font-weight: 500;
            font-size: rem(13);
            line-height: rem(18);
            color: $white;
        }

        .btn {
            margin-top: rem(20);
        }
    }

    .shop-basket-product-wrapper {

    }
}

.shop-basket-products-el {
    min-height: rem(180);
    display: flex;
    flex-direction: column;
    padding: rem(22);
    padding-left: rem(10);
    border-bottom: 1px solid $primary-gray-border;
    background-color: $white;
    color: $primary-gray-dark-extra;    

    &.disabled {
        color: #1D1D1B;
        pointer-events: none;
        mix-blend-mode: normal;

        div:not(.ms-auto):not(.col-9):not(.row) {
            opacity: 0.35;

        }

        .price,
        .shop-pdp-list-iten-close-btn,
        .shop-pdp-list-item-qty {
            display: none !important;
        }
    }

    .shop-pdp-list-item-unavailable {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0.3rem;
        border: none;
        margin-bottom: 0;
        color: $red;
        background: #F0DDE1;
        margin-top: rem(32);
        border-radius: 4px;
    }

    .shop-pdp-list-item-img {
        object-fit: contain;
        max-width: rem(100);
    }

    .shop-pdp-list-iten-close-btn {
        background-color: $danger;
        padding: 0;
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 1rem;
        text-align: center;
        line-height: 0.75rem;
        font-size: 0.75rem;
        color: $white;
        border: none;
    }
}

.shop-product-tag {
    font-weight: bold;
    font-size: rem(10);
    line-height: rem(12);
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0.3rem;
    border: none;
    margin-bottom: 0;
}

.subscription-modal,
.webview-modal-priority,
.optin-modal,
.story-modal,
.checkout-shop-modal{
    position: absolute;
    overflow: auto;
    outline: none;
    width: 100vw;
    height: 100vh;
    background-color: black;
    
}

.pdp-shop-modal {
    position: absolute;
    overflow: auto;
    outline: none;
    padding: rem(20);
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.pdp-notification-center-modal {
    position: absolute;
    overflow: auto;
    outline: none;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
}

.subscription-modal-overlay,
.webview-modal-priority-overlay,
.optin-modal-overlay,
.checkout-shop-modal-overlay,
.story-modal-overlay,
.pdp-shop-modal-overlay,
.pdp-notification-center-modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba($white, 0.75);
    z-index: 1055;
    
    &.ReactModal__Overlay {
        //opacity: 0;
        transform: translateX(100%);
        transition: all 500ms ease-in-out;

        &.notification {
            transform: unset;
            scale: 0;
        }
    }
    
    &.opened {
        opacity: 1;
        transform: translateX(0px);

        &.notification {
            transform: unset;
            scale: 1;
        }
    }
    
    &.ReactModal__Overlay--before-close{
        //opacity: 0;
        transform: translateX(100%);

        &.notification {
            transform: unset;
            scale: 0;
        }
    }
    
}

.pdp-notification-center-modal-overlay {
    z-index: 9999;
}

.optin-modal-higher-overlay {
    z-index: 1000000 !important;
}

.pdp-shop-modal-overlay {
    z-index: 1054;

    &.notification {
        z-index: 10000;
    }
}

.webview-modal-priority-overlay {
    z-index: 10000;
}

.shop-reorder-summary {
    position: relative;
    z-index: 2;
    bottom: - calc($shop-reorder-summary-height / 2);
    background-color: $white;
    border: none;
    width: 100%;
    height: $shop-reorder-summary-height;
    padding: 0;
    border-radius: rem(8);
    display: flex;
    flex-direction: column;

    .shop-reorder-summary-number {
        border-radius: rem(8) rem(8) 0 0;
        display: flex;
        background: $primary-gray-light-extra;
        justify-content: center;
        font-weight: bold;
        font-size: rem(12);
        line-height: rem(14);
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: $primary-gray-dark-extra;
        padding: rem(15);
        width: 100%;
        align-items: center;
    }

    .shop-reorder-summary-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $primary-gray-light-extra;
        padding: rem(15) rem(20);
        width: 100%;

        h2 {
            margin-bottom: 0;
            font-size: rem(20);
            line-height: rem(18);
            color: $primary-gray-dark-extra;
        }
    }
    .shop-reorder-summary-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: rem(13) rem(20);

        span {
            font-weight: 500;
            font-size: rem(14);
            line-height: rem(18);
            color: $primary-gray-dark-extra;
        }

        .btn {
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
        }

    }
}

.card-img-overlay {
}

.swiper-slide-flavour {

    &.swiper-slide {
        text-align: center;
        width: auto;
        margin-right: rem(15);
    }


    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    //margin-right: 15px;
    height: rem(296);

    img {
        //position: absolute;
        //left: 50%;
        //top: 40%;
        //bottom: auto;
        //right: auto;
        //transform: translate(-50%, -50%);
        object-fit: cover;
        width: 8.8125rem;
        //margin-bottom: 0.5rem;
    }
    
    .card {
        height: rem(296);
        width: rem(250);
        background: rgba($black, 0.3);
        border-radius: 8px;

        button {
            width: rem(30); 
            height: rem(30); 
            padding: rem(6) rem(6); 
            border-radius: rem(15); 
            font-size: rem(10); 
            text-align: center; 
            span {
                color: $white;
                font-size: rem(13);

            }
            
            background: rgba(0, 0, 0, 0.1);
        }

        .card-body {
            height: rem(296);
            width: rem(250);
            
            span {
                font-style: normal;
                font-weight: 400;
                font-size: rem(12);
                line-height: rem(14);
                text-align: center;
                color: $white;

                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.swiper-slide-favourite {
    
    &.swiper-slide {
        text-align: center;
        width: auto;
        margin-right: rem(15);
    }


    .card {
        background: $white;
        border-radius: rem(8);
        width: rem(160);
        height: rem(261);
        border: none;

        .card-img-top {
            height: rem(130);
            object-fit: contain;
            width: 75%;
        }

        .card-body {
            color: $primary-gray-dark-extra;
            padding: rem(12);
            font-weight: 500;
            font-size: rem(12);
            line-height: rem(15);

            text-align: left;

            .favourite-nicotine {
                border: 2px solid rgba(0, 0, 0, 0.1);
                border-radius: 1.25rem;
                font-size: 0.75rem;
                padding: 0.2rem 1rem;
                margin-top: rem(10);
            }

            .favourite-qty-label {
                color: $primary-gray-border;
            }
        }

        .available {
            background-color: rgba(0, 199, 177, 0.15);
            padding: rem(7) rem(10);

            span {
                font-weight: bold;
                font-size: rem(12);
                line-height: rem(14);
                text-align: center;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                color: $primary-green;
                opacity: 1;
            }
        }

        .out-of-stock {
            background: #F0DDE1;
            padding: rem(7) rem(10);

            span {
                font-weight: bold;
                font-size: rem(12);
                line-height: rem(14);
                text-align: center;
                letter-spacing: 1.2px;
                text-transform: uppercase;
    
                color: $red;
            }
        }
    }
    
}

.swiper-container{
    width: 100%;
}
.swiper-wrapper {
    width: 50%;
}
.device-carousel .swiper-wrapper {
    width: 100%;
}
.reorder-section {
    padding: rem(10) 0 rem(28) 0;
    border-top: 1px solid #1D1D1B;
    
    .reorder-favourites-btn {
        width: 90%;
        margin: rem(30) rem(15)  rem(15) rem(15);
        text-transform: uppercase;
    }
}

.reorder-label {
    .reorder-label-desc {
        font-size: rem(14);
        line-height: rem(18);
        color: #1D1D1B;
        padding: rem(12) rem(20);
    }
    
    .reorder-label-btn {
        background: $primary-green;
        border-radius: rem(58);
        font-weight: bold;
        font-size: rem(10);
        line-height: rem(12);

        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;

        padding-top: rem(6);
        padding-bottom: rem(6);

        margin-right: rem(18);
        margin-left: rem(18);

        color: $white;
    }

    &.reorder-label-order {
        position: absolute;
        bottom: 6rem;
        z-index: 1;
        width: 95%;

        .label-title {
            color: $white;
        }
    }
}

.shop-reorder-feature {
    &.disabled {
        color: #1D1D1B;
        pointer-events: none;
        mix-blend-mode: normal;
        opacity: 0.35; 
    }
}

.shop-reorder-feature-toggle-wrapper {
    color: #2A2A2A;
    background-color: $white;
    border-radius: 0.5rem;
    padding: 1.25rem 0.5rem;
    margin: 0 rem(15) 0 rem(15);
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(16);

    .reminder-label {
        font-weight: normal;
        line-height: rem(18);

    }

    .reminder-value {
        font-style: normal;
        font-weight: 800;
        font-size: rem(20);
        line-height: rem(20);
        display: inline;
        text-align: right;
        text-transform: uppercase;
        color: #2A2A2A;
        padding: rem(5) rem(10);
        border: 2px solid #DEDEDE;
        border-radius: 8px;
    }
}


.shop-reorder-feature-badge {

    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
    background-color: transparent;
    padding: rem(20) rem(20) rem(16) rem(20);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .btn {
        width: 40%;
        align-self: flex-start;
        margin-top: rem(17);

        font-weight: bold;
        font-size: rem(12);
        line-height: rem(12);
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .shop-reorder-feature-badge-desc {
        width: 70%;
        font-size: rem(14);
        line-height: rem(18);
    }
}

.optin-modal {
    z-index: 1055;

    .optin-wrapper {
        height: auto;
    }

    .optin-header {
        background-color: inherit;
    }

    .optin-selector-cta {
        background-color: inherit;
        margin-bottom: rem(20);
    }

    .optin-icon {
        
        &.mint {
            border: 3px solid $primary-green;
        }

        span {
            font-size: 28px;
            display: flex;
            align-items: center;
        }
    }
}

.shop-pdp-list-item-qty-readonly {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-weight: bold;
        font-size: rem(12);
        line-height: rem(12);
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $primary-gray-dark-extra;
    }

    h4 {
        margin-bottom: 0;
        margin-left: rem(5);
    }
}

.shop-wrapper {
    & > div {
        backdrop-filter: blur(5px);
    }

    background-repeat: no-repeat;
    background-size: cover;

    .shop-wrapper-copy {
        margin-top: auto;
        margin-bottom: auto;
        padding-left: rem(20);
        padding-right: rem(20);
        text-align: center;

        h3 {
            font-weight: 800;
            font-size: rem(40);
            letter-spacing: -0.5px;
            line-height: rem(38);
            color: $white;
        }
    
        span {
            font-style: normal;
            font-weight: 400;
            font-size: rem(14);
            line-height: rem(18);
            color: $white;
        }
    }

    .page-bottom-cta {
        padding-left: rem(20);
        padding-right: rem(20);
        margin-top: rem(40);
        margin-bottom: rem(40);
    }
    
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

@include media-breakpoint-up(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
// Very Extra large devices (Extra large desktops, 1440px and up)
@include media-breakpoint-up(xxl) {
}
// 4K devices (4K, 2560px and up)
@include media-breakpoint-up(4k) {
}

//***************************************
// LESS THAN
//***************************************

// Very Extra small devices (portrait phones, less than 375px)
@include max-xxs {
}
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
}
// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
}
// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
}
// Extra Large devices (desktops, less than 1440px)
@include media-breakpoint-down(xl) {
}
// Extra Large devices (desktops, less than 2560px)
@include media-breakpoint-down(xxl) {
}

//***************************************
// ONLY FOR RANGE
//***************************************

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, From 576px to 767.98)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, From 768px to 991.98px)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, From 992px to 1199.98px)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, From 1200px to 1439.98px)
@include media-breakpoint-only(xl) {
}
// Very Extra large devices (Very Extra large desktops, From 1440px to 2559.98px)
@include media-breakpoint-only(xxl) {
}
// 4K large devices (4K desktops, 2560px and up)
@include media-breakpoint-only(4k) {
}
