body {
    &.vault-setup {
        .page-header {
            background-color: transparent;
            @include reset-box-shadow;
        }
    }   
}

.container {
    &.container-vault-ios-picker {
        padding-left: 0;
        padding-right: 0;
        .row {
            margin-left: - rem(9);
            margin-right: - rem(9);
            > * {
                padding-right: rem(9);
                padding-left: rem(9);
            }
        }
    }
}


.rsw_2Y {
    height: 100%;
}
.rsw_2f {
    height: 100%;
    display: flex;
    flex-direction: column;    
}


.vault-intro {
    padding: rem(40) rem(50);
    text-align: center;
    h1 {
        font-weight: 800;
        font-size: rem(26);
        line-height: rem(26);
        text-transform: uppercase;
        padding: 0;
        margin: 0 0 rem(20) 0;
    }
    p {
        margin-bottom: 0;
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(18);
    }    
}

.vault-wrapper {
    padding: 0 $page-sides-padding-y;

    .page-content {
        font-size: rem(14);
        color: $white;
        margin-top: rem(17);
        margin-bottom: rem(38);
    }

    .inline-alert {
        margin-top: rem(20);
    }
}

.vault-ios-picker {
    margin-top: rem(20);
    .ios-picker {
        background-color: $primary-gray-dark;
        .wheel-picker-container {
            .wheel-picker-column {
                -webkit-mask-box-image: linear-gradient(to top, #0000, #0000 5%, #fff 20%, #fff 80%, #0000 95%, #0000);
            }
            .wheel-picker-inner {
                -webkit-mask-box-image: none;//Hide here, but translate on ".wheel-picker-column"
                font-size: rem(30);
                padding: 0;
                .wheel-picker-cursor {
                    width: 100%;
                }
            }
        }
        //SUCCESS
        &.success {
            .wheel-picker-cursor {
                border-color: $success;
            }
        }
        //error
        &.error {
            .wheel-picker-cursor {
                border-color: $danger;
            }
        }
    }
}

.vault-ios-picker-disclaimer {
    margin-top: rem(30);
    font-size: rem(12);
    font-weight: 400;
    line-height: rem(18);
}

.vault-security-question {
    padding: 0 $page-sides-padding-y;
    .page-label {
        margin-bottom: rem(14);
    }

    .btn {
        margin-bottom: rem(10);
        width: 100%;
    }

    .vault-setup-security-question {
        .btn {
            background: $primary-gray-darker;
        }
    }

    .vault-restore-security-question {
        background: $primary-gray-dark-extra;
        mix-blend-mode: normal;
        /* Dark Grey */

        border: rem(2) solid $primary-gray-light;
        border-radius: rem(8);
        font-style: 400;
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(18);
        /* or 129% */

        display: flex;
        align-items: center;

        /* White */

        color: $white;

        padding: rem(15) rem(20);
        margin-bottom: rem(15);
    }

    input {
        &.error {
            border-color: $danger;  
        }
    }
    
}

.vault-restore {
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    .page-header-title {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    }
    //PAGE BOTTOM CTA
    .page-selector-cta {
        min-height: auto;
        padding-bottom: rem(30);
    }

}

.vault-restored-bg {
    background-color: $primary-gray-dark-extra;

}

.vault-restored {
    padding: 0 $page-sides-padding-y;
    text-align: center;
    margin-bottom: 4vh;
    h1 {
        text-transform: uppercase;
        font-size: rem(26);
        font-weight: 800;
        line-height: rem(26);
        padding: 0;
        margin: 0;
    }
}


.vault-bg-video {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: fixed;
    z-index: 0;
    left: 0;
    top: 0;
}

.vault-pin {
    background-color: $primary-gray-dark;
    mix-blend-mode: normal;
    border-radius: 8px;
    text-align: center;
    height: 87px;
    width: 70px;
    color: $white;
    border: 2px solid #6B6B6B;

    font-size: rem(26);
    font-weight: 800;
    line-height: rem(26);
    margin: 0;

    &:focus {
        color: $white;
        background-color: $primary-gray-dark;
        border: 2px solid #6B6B6B;
    }

    &.success {
        border: 2px solid #00C7B1;
    }

    &.error {
        border-color: $danger;  
    }
}

