.bat-accordion-wrapper {
    padding: rem(10) $page-sides-padding-y;
    .MuiPaper-root {
    }
    .MuiAccordion-root {
        background-color: $primary-gray-dark-extra;
        &.Mui-expanded {
            margin: 0 0 rem(10) 0;
            &:last-child {
                margin-bottom: rem(10);
            }
        }
    }
    .MuiAccordionSummary-root {
        min-height: rem(46);
        transform-origin: center center;
        em {
            display: inline-block;
            min-width: 20px;
            font-style: normal;
        }
        &:before {
            @include transition-common-link;
            position: absolute;
            right: rem(10);
            width: rem(20);
            height: rem(20);
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: "bat-icon" !important;
            content: attr(data-icon);
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            vertical-align: middle;

            font-size: rem(14);
            color: $white;
            //.bat-icon-chevron-down by Fontastic
            //content: "\6b";
            
            //.bat-icon-chevron-down by IcoMoon
            content: "\e956";
        }
        &.Mui-expanded {
            min-height: rem(46);
            &:before {
                transform: rotate(180deg);
            }
        }
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
        border: 2px solid $primary-gray-light;
        border-radius: $input-border-radius !important;
        margin-bottom: rem(10);
    }
    .MuiAccordionSummary-content {
        margin: 0;
        font-size: rem(14);
        font-weight: 500;
        color: $white;
        &.Mui-expanded {
            margin: 0;
        }

        span {
            width: 90%;
        }
    }
    .MuiAccordionDetails-root {
        font-size: rem(12);
        line-height: rem(14);
        color: $primary-gray-medium;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            word-break: break-word;
        }
    }

}


// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

@include media-breakpoint-up(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
// Very Extra large devices (Extra large desktops, 1440px and up)
@include media-breakpoint-up(xxl) {
}
// 4K devices (4K, 2560px and up)
@include media-breakpoint-up(4k) {
}

//***************************************
// LESS THAN
//***************************************

// Very Extra small devices (portrait phones, less than 375px)
@include max-xxs {
}
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
}
// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
}
// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
}
// Extra Large devices (desktops, less than 1440px)
@include media-breakpoint-down(xl) {
}
// Extra Large devices (desktops, less than 2560px)
@include media-breakpoint-down(xxl) {
}

//***************************************
// ONLY FOR RANGE
//***************************************

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, From 576px to 767.98)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, From 768px to 991.98px)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, From 992px to 1199.98px)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, From 1200px to 1439.98px)
@include media-breakpoint-only(xl) {
}
// Very Extra large devices (Very Extra large desktops, From 1440px to 2559.98px)
@include media-breakpoint-only(xxl) {
}
// 4K large devices (4K desktops, 2560px and up)
@include media-breakpoint-only(4k) {
}

#js-mobile-console {
    display: block;
    position: absolute;
    top: 1400px;
    left: 0;
    width: 200px;
}