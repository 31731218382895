// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  4k: 2560px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 1023.996px,
  lg: 1023.997px,
  xl: 1023.998px,
  xxl: 1023.999px,
  4k: 1024px
  //xl: 1140px,
  //xxl: 1360px,
  //4k: 1600px
) !default;


// EXTRA Small devices
$screen-xxs-min: 375px;

// Extra small devices (portrait phones, less than 576px)
@mixin max-xxs {
    @media (max-width: #{$screen-xxs-min - .02}) {
        @content;
    }
}

//FROM PX TO REM
$baseFontSize : 16; // = $font-size-base 1rem
@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return calc($pixels / $context) * 1rem;
}



// Common transition speed
$transition-common-speed: 300ms;

//Grid
$grid-gutter-width:           20px !default;

//Colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$primary:                       #F8AB28 !default;
$primary-gray-light-extra:      #EFEFEF !default;
$primary-gray-light:            #6B6B6B !default;
$primary-gray-middle:           #CCCCCC !default;
$primary-gray-medium:           #aaaaaa !default;
$primary-gray-soft:             #2A2A2A !default;
$primary-gray-dark:             #282828 !default;
$primary-gray-dark-extra:       #1D1D1B !default;
$primary-gray-darker:           #161616 !default;
$primary-gray-border:           #DEDEDE !default;
$primary-green:                 #00C7B1 !default;
$primary-violet:                #750BDD !default;

$secondary:     $primary-gray-light !default;

$red:    #991F39 !default;
$green:    $primary-green !default;
$plum:   #7d3679;
$dark:   $primary-gray-dark-extra;

// Gradients
$gradient-primary: linear-gradient(130.85deg, #E48318 8.46%, #EEB300 85.52%);
$gradient-warning: linear-gradient(92.69deg, #5709A4 17.73%, #750BDD 87.7%);
$gradient-inactive: linear-gradient(91.56deg, #595959 11.77%, #6B6B6B 92.1%);
$gradient-error: linear-gradient(92.62deg, #79162B 12.37%, #991F39 90.72%);

$device-black-gradient: linear-gradient(180deg, #202020 0%, #272727 100%);
$device-blue-gradient: linear-gradient(180deg, #2175F4 0%, #0454CB 100%);
$device-cyan-gradient: linear-gradient(180deg, #81CDC8 0%, #b5edea 100%);
$device-gold-gradient: linear-gradient(180deg, #A37F57 0%, #D1B493 100%);
$device-pink-gradient: linear-gradient(180deg, #DBB6B6 0%, #E2C9C9 100%);
$device-red-gradient: linear-gradient(180deg, #EE5E62 0%, #E53237 100%);
$device-reflekta-gradient: linear-gradient(180deg, #D4D4D4 0%, #737373 100%);

$epod2-black-gradient: linear-gradient(180deg, #2a2a2a 0%, #2a2a2a 100%);
$epod2-blue-gradient: linear-gradient(180deg, #2175f4 0%, #0454cb 100%);
$epod2-red-gradient: linear-gradient(180deg, #ee5e62 0%, #e53237 100%);
$epod2-gold-gradient: linear-gradient(180deg, #d1b493 0%, #a37f57 100%);
$epod2-cyan-gradient: linear-gradient(180deg, #b5edea 0%, #609895 100%);
$epod2-pink-gradient: linear-gradient(180deg, #e2c9c9 0%, #ca9c9d 100%);
$epod2-reflekta-gradient: linear-gradient(180deg, #ebebeb 0%, #636363 100%);

$epod3-black-gradient: linear-gradient(180deg, #64585e 0%, #252223 100%);
$epod3-blue-gradient: linear-gradient(180deg, #2987ca 0%, #00509d 100%);
$epod3-red-gradient: linear-gradient(180deg, #ea2a37 0%, #9a1e26 100%);
$epod3-gold-gradient: linear-gradient(180deg, #dfbe8b 0%, #ba822d 100%);
$epod3-cyan-gradient: linear-gradient(180deg, #37cbe3 0%, #007d92 100%);
$epod3-pink-gradient: linear-gradient(180deg, #ffcbce 0%, #ad7578 100%);

$smartBox-black-gradient: linear-gradient(180deg, #6b6b6b 0%, #1d1d1b 100%);
$smartBox-blue-gradient: linear-gradient(180deg, #4574ba 0%, #002947 100%);
$smartBox-pink-gradient: linear-gradient(180deg, #d0c3bf 0%, #ab9c98 100%);

// Body
$font-family-sans-serif:        'Montserrat' !default;
$font-family-primary:           $font-family-sans-serif;

$body-bg:                       $primary-gray-dark !default;
$body-color:                    $white !default;

// Forms
$label-margin-bottom:                   rem(5) !default;

$input-padding-y:                       rem(13.6) !default;
$input-padding-x:                       rem(15) !default;

$input-bg:                              $white !default;
//$input-disabled-bg:                     $gray-200 !default;

$input-color:                           $primary-gray-dark !default;
$input-border-color:                    $input-bg !default;

$input-border-radius:                   rem(8) !default;
$input-border-radius-lg:                rem(8) !default;
$input-border-radius-sm:                rem(8) !default;
$input-placeholder-color:               $primary-gray-medium !default;

$input-font-size:                       rem(14) !default;

//CUSTOM SWITCH
$custom-control-indicator-size:         rem(20) !default;
$custom-switch-width:                   $custom-control-indicator-size * 2 !default;

// Buttons
$btn-border-radius:           $input-border-radius !default;
$btn-border-radius-lg:        $input-border-radius !default;
$btn-border-radius-sm:        $input-border-radius !default;

$btn-padding-y:               rem(17) !default;
$btn-padding-x:               $input-padding-x !default;
$btn-font-family:             $font-family-sans-serif !default;
$btn-font-size:               rem(14) !default;
$btn-line-height:             1 !default;


//PAGE SIDES PADDING
$page-sides-padding-y: rem(26.5);

//PAGE SIDES PADDING
$page-small-sides-padding-y: rem(18);

//LINK
@mixin a-decoration-none {
    text-decoration: none;
}

//PAGE WRAPPER POSITION
@mixin page-wrapper-position {
    position: relative;
    z-index: 2;
}

//COMMON TRANSITION
@mixin transition-common-link {
    -webkit-transition: all $transition-common-speed ease-in-out;
    transition: all $transition-common-speed ease-in-out;
}
//SLIDER TRANSITION
@mixin transition-slider {
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
//RESET RADIUS
@mixin reset-radius {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
//RESET BOX SHADOW
@mixin reset-box-shadow {
    box-shadow: none;
}

//MENU RESET ALL
@mixin reset-menu-list {
    list-style :none;
    padding: 0;
    margin: 0;
}
@mixin absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
@mixin absolute-center-vertical {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
@mixin absolute-center-horizontal {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
//FLEX
@mixin display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@mixin align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

//HEADER
$header-height: rem(70);
$top-navigator: rem(56);
$health-warning-height: 20vh;
$header-title-height: rem(60);
$label-title-height: rem(40);
$dashboard-navigator: rem(97);

//SLIDING PANEL
$zindex-modal: 1050 !default;
$sliding-panel-dashboard-header: rem(70);


//PROGRESS CIRCLE
@mixin progress-circle($icon-font-size, $progress-circle-size, $progress-circle-circonference, $stroke-width) {
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    flex-direction:column;
    [class*=bat-icon] {
        @include absolute-center;
        font-size: $icon-font-size !important;
    }
    .percent {
        background-color: rgba($black, 0.1);
        position:relative;
        width: $progress-circle-size;
        height: $progress-circle-size;
        border-radius: 50%;
        svg {
            width: $progress-circle-size;
            height: $progress-circle-size;
            position: relative;
            transform: rotate(-90deg);
            circle {
                width: $progress-circle-size;
                height: $progress-circle-size;
                fill:none;
                stroke-width:$stroke-width;
                stroke:#000;
                transform:translate(2px,2px);
                stroke-dasharray: $progress-circle-circonference;
                stroke-dashoffset: $progress-circle-circonference;
                stroke-linecap:round;
                &.circle-first {
                    stroke-dashoffset:0;
                    stroke: rgba($white, 0.25);
                }
                &.circle-second {
                    //Settings inline
                }
            }   
        }
    }
}
