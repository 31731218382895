.vuseworld-container {
	.vuseworld-section {
		margin-bottom: rem(30);

		.vuseworld-section-header {
			padding: 0 rem(27);
			display: flex;
			justify-content: space-between;

			.vuseworld-section-title {
				font-size: rem(12);
				font-weight: 700;
				letter-spacing: rem(1.2);
				line-height: rem(14);
				color: $primary-gray-light;
				margin-bottom: rem(20);
			}

			.vuseworld-blog-header-view-all {
				position: relative;
				font-size: rem(12);
				font-style: normal;
				font-weight: 700;
				color: $primary-gray-light;

				p {
					margin-right: 20px;
				}

				i {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 16px;
					font-weight: 200;
					color: $white;
				}
			}
		}

		.vuseworld-section-body {
			padding-left: rem(27);
			padding-right: rem(27);
			img {
				width: 100%;
			}
		}

		.vuseworld-carousel-container {
			padding-right: 0;
			margin: 0;
			padding-bottom: rem(45);

			.swiper-wrapper {
				width: 100%;
				.swiper-slide {
					width: 90%;
					margin-right: rem(15);
					img {
						width: 100%;
					}
				}
			}

			.swiper-pagination {
				position: absolute;
				width: auto;
				text-align: left;
				height: rem(8);
				line-height: 0;
				bottom: rem(20);
				left: $page-sides-padding-y;
				.swiper-pagination-bullet {
					@include transition-common-link;
					width: rem(8);
					height: rem(8);
					background-color: rgba($white, 1);
					margin: 0 rem(8) 0 0;
					border-radius: rem(4);
					&:last-child {
						margin-right: 0;
					}
					&.swiper-pagination-bullet-active {
						width: rem(26);
					}
					&:only-child {
						display: none;
					}
				}
			}
		}
	}
}

.vuseworld-stories-container {
	.swiper-slide {
		max-width: rem(70);

		.story-dot-container {
			.story-dot {
				background-size: cover;
				background-position: center center;
				height: rem(70);
				border-radius: rem(40);

				&.new {
					border: 2px solid $primary-green;
				}
			}

			.story-dot-label {
				font-weight: 600;
				font-size: rem(8);
				line-height: rem(14);
				display: flex;
				align-items: center;
				text-align: center;
				letter-spacing: rem(0.8);
				text-transform: uppercase;
				margin-top: rem(8);
				display: block;
				color: $white;
			}
		}
	}
}

.story-modal-overlay {
	&.ReactModal__Overlay {
		transform: translateY(100%);
		transition: all 500ms ease-in-out;
	}

	&.opened {
		opacity: 1;
		transform: translateX(0px);
	}

	&.ReactModal__Overlay--before-close {
		transform: translateY(100%);
	}
}

.story-modal {
	.tutorial-icon {
		border: none !important;
	}

	.feature-tutorial-wrapper {
		.feature-tutorial-header {
			position: absolute;
		}
		.feature-tutorial-stories {
			video {
				position: absolute;
			}

			.story-element-video-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				.story-element-video {
					width: auto;
					max-width: 100%;
					max-height: 100%;
					margin: auto;
				}
			}

			.story-element-img-wrapper {
				.story-element-img {
					width: auto;
					max-width: 100%;
					max-height: 100%;
					margin: auto;
				}
			}

			.story-element-see-more-wrapper {
				position: absolute;
				margin: auto;
				bottom: 0px;
				z-index: 9999;
				width: 100%;
				height: auto;
			}
            
            .see-more-btn {
                width: 90vw;
                margin: rem(20);
            }
		}
	}
}
