//BTN 
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    opacity: 1;
}
.btn {
    position: relative;
    font-weight: 600;
    letter-spacing: rem(1);
    //Primary
    &.btn-primary {
        font-weight: 700;
        //isabled
        &.disabled,
        &:disabled {
            color: $primary-gray-dark-extra;
            background-color: $primary-gray-light;
            border-color: $primary-gray-light;
        }
    }
    //Warning
    &.btn-warning {
        background-color: $primary-violet;
        border-color: $primary-violet;
        color: $white;
        font-weight: 700;
        //isabled
        &.disabled,
        &:disabled {
            color: $primary-gray-dark-extra;
            background-color: $primary-gray-light;
            border-color: $primary-gray-light;
        }
    }
    //Outline Secondary
    &.btn-outline-secondary {
        color: $white;
        //Disabled
        &.disabled,
        &:disabled {
            color: $primary-gray-light;
            background-color: transparent;
            border-color: $primary-gray-light;
        }
        &.static {
            background-color: transparent;
            border-color: $primary-gray-light;
        }
    }
    //OUTLINE
    &[class*=btn-outline] {
        border-width: 2px;
        padding-top: rem(16);
        padding-bottom: rem(16);
    }
    //Link
    &.btn-form-link {
        padding: 0;
        vertical-align: inherit;
        font-weight: bold;
        color: $primary-gray-dark;
        font-size: rem(14);
        text-decoration: none;
        box-shadow: none !important;
    }
    //Focus
    &:focus {
        box-shadow: none !important;
    }
    //FLAGS
    .flag {
        margin-right: rem(10);
        display: inline-block;
        position: relative;
        top: -1px;            
        img {
            height: rem(24);
            margin: -7px 0;
            border-radius: rem(20);
        }
    }
    //ICONS
    .icon {
        @include absolute-center-vertical;
        right: rem(15);
        font-size: rem(20);
    }
}
//BTN Outline Secondary
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show
/*,.btn-outline-secondary:hover*/
{
    color: $white;
    background-color: transparent;
    border-color: $green;
}

.btn-outline-secondary:hover {
    color: $white;
    background-color: transparent;
}

.form-mandatory, .form-optional {
    float: right;
    font-size: rem(12);
    color: $primary-gray-light;
    margin-top: rem(4);
}
//FORM
.form-control {
    font-weight: 600;

    &.error {
        border: rem(2) solid $red !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}
.form-control-toggle {
    position: relative;
    &.form-control-toggle-confirm {
        margin-top: rem(10);
    }
    .form-control {
        padding-right: rem(50);
    }

    //Show/Hide passord
    
    .toggle-icon {
        @include absolute-center-vertical;
        position: absolute;
        z-index: 1;
        right: rem(10);
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(30);
        height: rem(30);

        &.toggle-pw {
            &:before {
                font-family: "bat-icon" !important;
                content: attr(data-icon);
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                speak: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                vertical-align: middle;
    
                font-size: rem(24);
                color: $primary-gray-dark-extra;
            }
            &.hide {
                &:before {
                    //.bat-icon-eye-off by Fontastic
                    //content: "\65";

                    //.bat-icon-eye-off by IcoMoon
                    content: "\e91a";                    
                }
            }
            &.show {
                &:before {
                    //.bat-icon-eye-on by Fontastic
                    //content: "\66";

                    //.bat-icon-eye-on by IcoMoon
                    content: "\e919";                    
                }
            }
        }   
        
    }

}

//CHECKBOX
.form-check:not(.form-switch) {
    display: flex;
    align-items: center;

    font-size: rem(14);
    line-height: rem(18);
    padding-left: rem(40);
    .form-check-input {
        margin-left: - rem(40);
    }    
    .form-check-input {
        width: rem(29);
        height: rem(29);
        box-shadow: none !important;
        margin-right: rem(10);
        flex-shrink: 0;
        &:checked {
            background-color: $primary-green;
            border-color: $primary-green;
        }
        &:checked[type="checkbox"] {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjA2NjYxIDEwLjMzMzJMMC4yMzMyNzYgNS42ODU3NEwxLjU4NjYxIDQuMzg0NDVMNS4wNjY2MSA3LjczMDYxTDEyLjQxMzMgMC42NjY1MDRMMTMuNzY2NiAxLjk2Nzc4TDUuMDY2NjEgMTAuMzMzMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
            background-size: rem(14);
        }
    }
}

.form-switch {
    .form-check-input {
        width: rem(60);
        height: rem(26);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"), $gradient-inactive;        
        background-color: $primary-gray-light;
        border: 0;
        &:checked {
            background-size: rem(26), contain;
            background-color: $primary;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"), $gradient-primary;
        }
        &:focus {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");        
            box-shadow: none;
            &:checked {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"), $gradient-primary;
            }
        }
    }
}

//spinner
.spinner-border {
    border-right-color: currentColor !important;
    border: 0.19rem solid rgba(29, 29, 27, 0.1);
    width: 1.8rem;
    height: 1.8rem;

    &.spinner-border-sm {
        border-width: rem(2);
        width: 1rem;
        height: 1rem;
    }
}

.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: rem(40) 0;

  &.no-margin-top {
      margin-top: rem(20);
  }

  .form-check-label {
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
  }
  .form-switch {
      input {
          margin-top: 0;
      }
  }
}

.form-check-label {
    font-weight: 600;
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

@include media-breakpoint-up(xs) {
}
// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {
}
// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {
}
// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
}
// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {
}
// Very Extra large devices (Extra large desktops, 1440px and up)
@include media-breakpoint-up(xxl) {
}
// 4K devices (4K, 2560px and up)
@include media-breakpoint-up(4k) {
}

//***************************************
// LESS THAN
//***************************************

// Very Extra small devices (portrait phones, less than 375px)
@include max-xxs {
}
// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {
}
// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
}
// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
}
// Extra Large devices (desktops, less than 1440px)
@include media-breakpoint-down(xl) {
}
// Extra Large devices (desktops, less than 2560px)
@include media-breakpoint-down(xxl) {
}

//***************************************
// ONLY FOR RANGE
//***************************************

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-only(xs) {
}
// Small devices (landscape phones, From 576px to 767.98)
@include media-breakpoint-only(sm) {
}
// Medium devices (tablets, From 768px to 991.98px)
@include media-breakpoint-only(md) {
}
// Large devices (desktops, From 992px to 1199.98px)
@include media-breakpoint-only(lg) {
}
// Extra large devices (large desktops, From 1200px to 1439.98px)
@include media-breakpoint-only(xl) {
}
// Very Extra large devices (Very Extra large desktops, From 1440px to 2559.98px)
@include media-breakpoint-only(xxl) {
}
// 4K large devices (4K desktops, 2560px and up)
@include media-breakpoint-only(4k) {
}
