

@keyframes animate__slideInDownCustom1 {
    from {
        transform: translateY(-100%) scale(1.0);
    }
    to {
        transform: translateY(0) scale(1.0);
    }
}
@keyframes animate__slideOutUpCustom1 {
    from {
        transform: translateY(0) scale(1.0);
    }
    to {
        transform: translateY(-100%) scale(1.0);
    }
}
.animate__slide-enter--custom:first-of-type {
    animation-name: animate__slideInDownCustom1;
    animation-timing-function: linear;
    animation-duration: 0.6s;
}
.animate__slide-exit--custom:first-of-type {
    animation-name: animate__slideOutUpCustom1;
    animation-timing-function: linear;
    animation-duration: 0.6s;
}
@keyframes animate__slideInDownCustom2 {
    from {
        transform: translateY(-100%) scale(0.9);
    }
    to {
        transform: translateY(0) scale(0.9);
    }
}
@keyframes animate__slideOutUpCustom2 {
    from {
        transform: translateY(0) scale(0.9);
    }
    to {
        transform: translateY(-100%) scale(0.9);
    }
}
.animate__slide-enter--custom:nth-of-type(2) {
    animation-name: animate__slideInDownCustom2;
    animation-timing-function: linear;
    animation-duration: 0.6s;
}
.animate__slide-exit--custom:nth-of-type(2) {
    animation-name: animate__slideOutUpCustom2;
    animation-timing-function: linear;
    animation-duration: 0.6s;
}
@keyframes animate__slideInDownCustom3 {
    from {
        transform: translateY(-100%) scale(0.8);
    }
    to {
        transform: translateY(0) scale(0.8);
    }
}
@keyframes animate__slideOutUpCustom3 {
    from {
        transform: translateY(0) scale(0.8);
    }
    to {
        transform: translateY(-100%) scale(0.8);
    }
}
.animate__slide-enter--custom:nth-of-type(3) {
    animation-name: animate__slideInDownCustom3;
    animation-timing-function: linear;
    animation-duration: 0.6s;
}
.animate__slide-exit--custom:nth-of-type(3) {
    animation-name: animate__slideOutUpCustom3;
    animation-timing-function: linear;
    animation-duration: 0.6s;
}

.notifications-container, .notifications-container-dashboard {
    width: 100%;
    left: 0;

    .notification-container {
        margin: rem(10) rem(26);
        padding-left: rem(24);
        padding-right: 0;
        padding-top: rem(16);
        padding-bottom: rem(21);
        border-radius: rem(8);

        display: flex;
        flex-wrap: nowrap;

        background-color: $white;
        color: $primary-gray-dark-extra;

        .is-banner::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: rem(8);
            background: #00C7B1;
        }

        .notification-icon {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
        }

        .notification-body {
            padding: 0;
        }

        .notification-inner-title {
            font-family: $font-family-primary;
            font-weight: 700;
            font-size: rem(12);
            line-height: rem(14);
            letter-spacing: rem(1.2);
            text-transform: uppercase;
            color: $primary-gray-dark-extra;
            word-break: break-word;
            flex: 4;
            margin-right: 22%;
            margin-top: rem(4);
            margin-bottom: rem(4);
        }

        .notification-inner-body {
            font-family: $font-family-primary;
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(14);
            color: $primary-gray-soft;
            margin-right: 17%;
        }

        .notification-timeframe {
            font-family: $font-family-primary;
            font-weight: 400;
            font-size: rem(10);
            line-height: rem(14);
            color: #6B6B6B;
            text-align: left;
            flex: 1;
            margin-right: 30%;
        }

        .notification-close-btn {
            position: absolute;
            top: rem(2);
            right: rem(2);
            width: rem(28);
            height: rem(28);
            border-radius: 50%;
            border: none;
            background: none;
        }

        &.notification-sticky {
            background-color: $primary-violet;
            color: $white;

            .notification-title {
                color: $white;
            }

            .notification-body {
                color: $white;
            }
        }

        &.notification-status-error, &.notification-status-firmware {
            color: $white;

            .notification-inner-title {
                color: $white;
            }

            .notification-inner-body {
                color: $white;
            }
        }
        &.notification-status-error {
            background: linear-gradient(92.62deg, #79162B 12.37%, #991F39 90.72%);
        }
        &.notification-status-firmware {
            background: linear-gradient(92.69deg, #5709A4 17.73%, #750BDD 87.7%);
            ;
        }
    }
}

.notifications-container-dashboard {
    .notification-container {
        position: absolute;
        left: 0;
        box-shadow: 0px 4px 4px 0px #00000040;
        top: 0;
        transition: all 0.6s linear !important;
        width: calc(100% - rem(40));
        margin: rem(10) rem(20);
        z-index: 20;

        &:first-of-type {
            z-index: 100;
            transform: scale(1.0);
            z-index: 12 !important;
        }

        &:nth-of-type(2) {
            top: rem(11);
            transform: scale(0.9);
            z-index: 10 !important;
        }
        &:nth-of-type(3) {
            top: rem(22);
            transform: scale(0.8);
            z-index: 8 !important;
        }
    }
}


.notifications-center-wrapper {
    color: $white !important;
    position: relative;
    z-index: 2;
    height: calc(100% - $header-height);

    .infinite-scroll-component__outerdiv {
        width: 100%;

        .notifications-center-container {
            padding-top: rem(8);
            overflow: auto;

            .chunck-label {
                font-weight: bold;
                font-size: rem(12);
                line-height: rem(14);
                letter-spacing: 1.2px;
                text-transform: uppercase;
                color: $white;
                padding: rem(13);
            }
        }
    }

    .swipe-to-delete {
        .js-delete {
            background: linear-gradient(92.62deg, #79162B 12.37%, #991F39 90.72%);
            margin: rem(10) rem(26);
            border-radius: rem(8);

            .delete-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-size: rem(18);
            }
        }

        .js-content .notification-body, .js-content .notification-body > div {
            position: static !important;
        }
    }

    .swipe-info {
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
        margin-top: rem(8);
        padding-bottom: rem(18);
        color: #AAAAAA;
    }

    .updates-empty {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .updates-empty-icon {
            width: rem(80);
            height: rem(80);
            border: 2px solid #ffffff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .bat-icon-bell {
                font-size: rem(30);
            }
        }

        .updates-empty-title {
            margin-top: rem(20);
            margin-bottom: rem(80);
            font-size: rem(26);
            font-weight: 800;
            line-height: rem(26);
            letter-spacing: -0.5px;
            text-align: center;
        }
    }
}