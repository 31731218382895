.blog-article-list {
  padding: 0 rem(27);
  list-style-type: none;
  .blog-article-container {
    display: flex;
    height: 90px;
    border-radius: rem(5);
    margin: rem(10) 0;
    background-color: #161616;

    .blog-article-image {
      width: 90px;
    }

    .blog-article-text-container {
      padding: rem(15) rem(20.5);
      .blog-article-date {
        font-weight: 700;
        font-size: rem(12);
        color: $primary-gray-light;
        margin-bottom: rem(10);
      }

      .blog-article-title {
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(18);
        color: $white;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
